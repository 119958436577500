import React from "react";
import i18n from "i18next";

const QuemFaz = () => (
  <div className="tcf-about__quemFaz">
    <h3 className="text-xl">{i18n.t("whoDoesIt")}</h3>
    <p className="text-gray">{i18n.t("whoDoesItText")}</p>
  </div>
);

export default QuemFaz;
