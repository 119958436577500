/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable prettier/prettier */
import { formatNumber, renderLegendNivo } from "helpers/utils";
import React from "react";
import i18n from "i18next";
import PropTypes from "prop-types";

function CustomLegend({ data, useIcone, directUse }) {
  const id = "lines" + Math.random().toString(16).slice(2)
  return (
    <>
      {

        directUse ? <>
          {data &&
            data.map((item, index) => (
              <>{renderLegendNivo(item.name, item.value, item.totalVal)}</>
            ))}

        </> : <div className="tcf-map-report-legality--legend">
          {data &&
            data.map((item, index) => (
              <div className="tcf-map-report-legality--legend-item" key={index}>

                {
                  useIcone && <>
                    {
                      item.isPattern ? (
                        <svg
                          width="12"
                          height="13"
                          viewBox="0 0 12 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            y="0.5"
                            width="12"
                            height="12"
                            fill={item.color}
                            fillOpacity="0.5"
                          />
                          <rect y="0.5" width="12" height="12" fill={`url(#${id})`} />
                          <defs>
                            <pattern
                              id={`${id}`}
                              width="18.384776310850235"
                              height="18.384776310850235"
                              patternUnits="userSpaceOnUse"
                            >
                              <rect
                                width="18.384776310850235"
                                height="18.384776310850235"
                                fill={item.background}
                                stroke={item.color}
                                strokeWidth="0"
                              />
                              <path
                                d="
                        M 0 -18.384776310850235 L 36.76955262170047 18.384776310850235
                        M -18.384776310850235 -18.384776310850235 L 18.384776310850235 18.384776310850235
                        M -18.384776310850235 0 L 18.384776310850235 36.76955262170047
                    "
                                strokeWidth="6"
                                stroke={item.color}
                                strokeLinecap="square"
                              />
                            </pattern>
                          </defs>
                        </svg>
                      ) : (
                        <svg
                          width="12"
                          height="13"
                          viewBox="0 0 12 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            y="0.5"
                            width="12"
                            height="12"
                            fill={item.color}
                          /* fill={
                        item.size && item.size === "P"
                          ? "#F3E6C9"
                          : item.size === "M"
                            ? "rgb(120, 199, 83)"
                            : "#AC6C29"
                      } */
                          />
                        </svg>
                      )
                    }
                  </>
                }


                <div className="tcf-map-report-legality--legend-item-info">
                  <span className="tcf-map-report-legality--legend-item-info-title">
                    {item.name ? item.name : item.size && item.size === "P"
                      ? `P - ${i18n.t("small")}`
                      : item.size === "M"
                        ? `M - ${i18n.t("medium")}`
                        : `G - ${i18n.t("large")}`
                    }
                  </span>
                  <span className="tcf-map-report-legality--legend-item-info-percent">
                    {formatNumber((item.value / item.totalVal) * 100, 2, ".", ",")}%
                    - {formatNumber(item.value, 0, ".", ",")} ha
                  </span>
                </div>
              </div>
            ))}
        </div>

      }
    </>

  );
}

CustomLegend.propTypes = {
  data: PropTypes.array,
  useIcone: PropTypes.bool,
};

CustomLegend.defaultProps = {
  data: [],
  useIcone: false,
};

export default CustomLegend;
