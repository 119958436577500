/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable prettier/prettier */
import React, { useRef } from "react";
import cx from 'classnames';

function Popover({ content, title, showModal, setShowModal, variation, size, style, className }) {
  const modalRef = useRef();
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const popOver = cx('tcf-map-popover', className, {
    'tcf-map-popover--sm': size === "sm",
    'tcf-map-popover--lg': size === "lg",
    'tcf-map-popover--primary': variation === "primary",
  });

  return (
    <>
      {showModal ? (
        <div
          onClick={closeModal}
          className={popOver}
          ref={modalRef}
          style={style}
        >
          <div className="tcf-map-popover__wrapper">
            {title && <h3 className="tcf-map-popover-header">{title}</h3>}
            <div className="tcf-map-popover-body">{content}</div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Popover;
