import React, { useEffect, useState, useRef } from "react";
import { getLanguage } from "service/axios.service";

import { Logo } from "components";

import Menu from "./Menu";

// import LogoPt from "assets/logo1_pt.png";
import LogoWhitePt from "assets/logos/logo-termometro-branco.png";
import LogoPt from "assets/logos/logo-termometro.png";
import LogoEn from "assets/logos/logo-thermometer.png";
import LogoWhiteEn from "assets/logos/logo-thermometer-white.png";
import MenuBars from "./MenuBars";
import Globe from "./Globe";

const Navbar = ({ toggle }) => {
  const navRef = useRef();
  const [sticky, setSticky] = useState(false);
  const logo =
    getLanguage() === "pt-BR"
      ? sticky
        ? LogoPt
        : LogoWhitePt
      : sticky
        ? LogoEn
        : LogoWhiteEn;

  /* Method that will fix header after a specific scrollable */
  const isSticky = () => {
    const scrollTop = window.scrollY;
    return scrollTop >= 250 ? setSticky(true) : setSticky(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  return (
    <nav className={`tcf-nav ${sticky ? "is-sticky" : ""}`} ref={navRef}>
      <Logo url={logo} />
      <div className="tcf-nav-action__mobile">
        <Globe onClick={toggle} />
        <MenuBars onClick={toggle} />
      </div>
      <Menu />
    </nav>
  );
};

export default Navbar;
