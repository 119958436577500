import styled from "styled-components";
import { color } from "styles";

const TooltipContainer = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
  color: ${color.white};
  font-size: 15px;

  &:after {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-top-color: rgba(0, 0, 0, 0.7);
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }

  code {
    color: ${color.skin};
  }
`;

const TooltipContent = styled.div``;

export { TooltipContainer, TooltipContent };
