import React, { useEffect, useRef, useState } from "react";
import i18n from "i18next";
import { useSelector } from "react-redux";
import { formatNumber } from "helpers/utils";

function Header() {
  const { name, type } = useSelector((state) => state.choice);
  const choice = useSelector((state) => state.choice);
  const { territorialCut } = choice || {};
  const { territory } = choice || {};
  const { cadastramentoInner } = useSelector((state) => state.cadastramento);
  const { legalidadeInner } = useSelector((state) => state.legalidade);

  const [sticky, setSticky] = useState(false);

  /* Method that will fix header after a specific scrollable */
  const isSticky = () => {
    const scrollTop = window.scrollY;
    return scrollTop >= 50 ? setSticky(true) : setSticky(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  return (
    <div className={`tcf-map-report--header ${sticky ? "is-sticky" : ""}`}>
      <div className="tcf-map-report--header--title">
        <h2>{i18n.t(name)}</h2>
        <ul className="tcf-map-report--header--subtitle-container">
          <li className="tcf-map-report--header--subtitle">
            <h4>{i18n.t(territorialCut.label)}</h4>
          </li>

          {territory && (
            <>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="8">
                  <path fill="none" stroke="#7f746c" d="M1 0l4 4-4 4" />
                </svg>
              </li>
              <li>
                <h4>{territory?.label}</h4>
              </li>
            </>
          )}
        </ul>
      </div>
      {/* <ReportSizeFilter/> */}
      <div className="tcf-map-report--body-territorialcut-header">
        <div className="tcf-map-report--body-territorialcut-header--info">
          <h3>{i18n.t("registeredArea")}</h3>
          <h2>
            {formatNumber(cadastramentoInner?.registered_area, 1, ".", ",")}{" "}
            <span>ha</span>
          </h2>
        </div>
        <div className="tcf-map-report--body-territorialcut-header--info">
          <h3>{i18n.t("nonRegisteredArea")}</h3>
          <h2>
            {formatNumber(cadastramentoInner?.not_registered, 1, ".", ",")}{" "}
            <span>ha</span>
          </h2>
        </div>
        {type === "traditional_territories" ? null : (
          <>
            <div className="tcf-map-report--body-territorialcut-header--info">
              <h3>{i18n.t("remainingRL")}</h3>
              <h2>
                {formatNumber(legalidadeInner?.remaining_rl_total, 1, ".", ",")}{" "}
                <span>ha</span>
              </h2>
            </div>
            <div className="tcf-map-report--body-territorialcut-header--info">
              <h3>{i18n.t("remainingAPP")}</h3>
              <h2>
                {formatNumber(
                  legalidadeInner?.remaining_app_total,
                  1,
                  ".",
                  ","
                )}{" "}
                <span>ha</span>
              </h2>
            </div>
            <div className="tcf-map-report--body-territorialcut-header--info">
              <h3>{i18n.t("passiveRL")}</h3>
              <h2>
                {formatNumber(legalidadeInner?.passive_rl_total, 1, ".", ",")}{" "}
                <span>ha</span>
              </h2>
            </div>
            <div className="tcf-map-report--body-territorialcut-header--info">
              <h3>{i18n.t("passiveAPP")}</h3>
              <h2>
                {formatNumber(legalidadeInner?.passive_app_total, 1, ".", ",")}{" "}
                <span>ha</span>
              </h2>
            </div>
            <div className="tcf-map-report--body-territorialcut-header--info">
              <h3>{i18n.t("surplus")}</h3>
              <h2>
                {formatNumber(legalidadeInner?.surplus_total, 1, ".", ",")}{" "}
                <span>ha</span>
              </h2>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Header;
