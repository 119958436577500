import React from "react";

import DropDownMenu from "./DropDownMenu";

const Dropdown = ({ isOpen, toggle }) => (
  <div
    /* onClick={toggle} */
    className={`tcf-dropdown ${isOpen ? "tcf-dropdown-open" : ""}`}
  >
    <div className="tcf-dropdown-close" onClick={toggle}>
      <div className="close-line" />
      <div className="close-line" />
    </div>
    <DropDownMenu />
  </div>
);

export default Dropdown;
