/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable prettier/prettier */
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ResponsiveBar } from "@nivo/bar";
import { useReportStore } from "service/report.service";
import { formatNumber, isEmptyObj, isObj } from "helpers/utils";
import CustomLegend from "components/CustomLegend";
import NivoCustomTooltip from "components/NivoCustomTooltip";

const Remanescente = () => {
  const choice = useSelector((state) => state.choice);
  const { type } = choice || {};
  const { legalidadeInner } = useSelector((state) => state.legalidade);
  const { sizeFilter } = useReportStore();
  const [dataAPP, setDataAPP] = useState([]);
  const [dataRL, setDataRL] = useState([]);

  const ConvertToNumber = (stringValue) => Number(stringValue);

  useEffect(() => {
    if (isObj(legalidadeInner) && !isEmptyObj(legalidadeInner)) {
      if (type === "rural_properties") {
        if (sizeFilter?.value === "All") {
          setDataRL([
            {
              size: "P",
              [i18n.t("remainingRL")]: ConvertToNumber(
                legalidadeInner.remaining_rl_small_properties
              ),
              value: ConvertToNumber(
                legalidadeInner.remaining_rl_small_properties
              ),
              totalVal: ConvertToNumber(legalidadeInner.remaining_rl_total),
              color: "rgb(128, 198, 77)",
              isPattern: false,
            },
            {
              size: "M",
              [i18n.t("remainingRL")]: ConvertToNumber(
                legalidadeInner.remaining_rl_average_properties
              ),
              value: ConvertToNumber(
                legalidadeInner.remaining_rl_average_properties
              ),
              totalVal: ConvertToNumber(legalidadeInner.remaining_rl_total),
              color: "rgb(128, 198, 77)",
              isPattern: false,
            },
            {
              size: "G",
              [i18n.t("remainingRL")]: ConvertToNumber(
                legalidadeInner.remaining_rl_large_properties
              ),
              value: ConvertToNumber(
                legalidadeInner.remaining_rl_large_properties
              ),
              totalVal: ConvertToNumber(legalidadeInner.remaining_rl_total),
              color: "rgb(128, 198, 77)",
              isPattern: false,
            },
          ]);
          setDataAPP([
            {
              size: "P",

              [i18n.t("remainingAPP")]: ConvertToNumber(
                legalidadeInner.remaining_app_small_properties
              ),
              value: ConvertToNumber(
                legalidadeInner.remaining_app_small_properties
              ),
              totalVal: ConvertToNumber(legalidadeInner.remaining_app_total),
              background: "rgba(120, 199, 83, 0.5)",
              color: "rgb(120, 199, 83)",
              isPattern: true,
            },
            {
              size: "M",
              [i18n.t("remainingAPP")]: ConvertToNumber(
                legalidadeInner.remaining_app_average_properties
              ),
              value: ConvertToNumber(
                legalidadeInner.remaining_app_average_properties
              ),
              totalVal: ConvertToNumber(legalidadeInner.remaining_app_total),
              background: "rgba(120, 199, 83, 0.5)",
              color: "rgb(120, 199, 83)",
              isPattern: true,
            },
            {
              size: "G",

              [i18n.t("remainingAPP")]: ConvertToNumber(
                legalidadeInner.remaining_app_large_properties
              ),
              value: ConvertToNumber(
                legalidadeInner.remaining_app_large_properties
              ),
              totalVal: ConvertToNumber(legalidadeInner.remaining_app_total),
              background: "rgba(120, 199, 83, 0.5)",
              color: "rgb(120, 199, 83)",
              isPattern: true,
            },
          ]);
        }
        if (sizeFilter?.value === "G") {
          setDataRL([
            {
              size: "G",
              [i18n.t("remainingRL")]: ConvertToNumber(
                legalidadeInner.remaining_rl_large_properties
              ),
              value: ConvertToNumber(
                legalidadeInner.remaining_rl_large_properties
              ),
              totalVal: ConvertToNumber(legalidadeInner.remaining_rl_total),
              color: "rgb(128, 198, 77)",
              isPattern: false,
            },
          ]);
          setDataAPP([
            {
              size: "G",
              [i18n.t("remainingAPP")]: ConvertToNumber(
                legalidadeInner.remaining_app_large_properties
              ),
              value: ConvertToNumber(
                legalidadeInner.remaining_app_large_properties
              ),
              totalVal: ConvertToNumber(legalidadeInner.remaining_app_total),
              background: "rgba(120, 199, 83, 0.5)",
              color: "rgb(120, 199, 83)",
              isPattern: true,
            },
          ]);
        }
        if (sizeFilter?.value === "M") {
          setDataRL([
            {
              size: "M",
              [i18n.t("remainingRL")]: ConvertToNumber(
                legalidadeInner.remaining_rl_average_properties
              ),
              value: ConvertToNumber(
                legalidadeInner.remaining_rl_average_properties
              ),
              totalVal: ConvertToNumber(legalidadeInner.remaining_rl_total),
              color: "rgb(128, 198, 77)",
              isPattern: false,
            },
          ]);
          setDataAPP([
            {
              size: "M",
              [i18n.t("remainingAPP")]: ConvertToNumber(
                legalidadeInner.remaining_app_average_properties
              ),
              value: ConvertToNumber(
                legalidadeInner.remaining_app_average_properties
              ),
              totalVal: ConvertToNumber(legalidadeInner.remaining_app_total),
              background: "rgba(120, 199, 83, 0.5)",
              color: "rgb(120, 199, 83)",
              isPattern: true,
            },
          ]);
        }
        if (sizeFilter?.value === "S") {
          setDataAPP([
            {
              size: "P",
              [i18n.t("remainingAPP")]: ConvertToNumber(
                legalidadeInner.remaining_app_small_properties
              ),
              value: ConvertToNumber(
                legalidadeInner.remaining_rl_average_properties
              ),
              totalVal: ConvertToNumber(legalidadeInner.remaining_rl_total),
              background: "rgba(120, 199, 83, 0.5)",
              color: "rgb(120, 199, 83)",
              isPattern: true,
            },
          ]);
          setDataRL([
            {
              size: "P",
              [i18n.t("remainingRL")]: ConvertToNumber(
                legalidadeInner.remaining_rl_small_properties
              ),
              value: ConvertToNumber(
                legalidadeInner.remaining_rl_average_properties
              ),
              totalVal: ConvertToNumber(legalidadeInner.remaining_rl_total),
              color: "rgb(128, 198, 77)",
              isPattern: false,
            },
          ]);
        }
      } else if (type === "settlements") {
        setDataAPP([
          {
            size: "",
            [i18n.t("remainingAPP")]: ConvertToNumber(
              legalidadeInner.remaining_app_total
            ),
            name: i18n.t("remainingAPP"),
            value: ConvertToNumber(legalidadeInner.remaining_app_total),
            totalVal: ConvertToNumber(legalidadeInner.remaining_app_total),
            background: "rgba(120, 199, 83, 0.5)",
            color: "rgb(120, 199, 83)",
            isPattern: true,
          },
        ]);
        setDataRL([
          {
            size: "",
            [i18n.t("remainingRL")]: ConvertToNumber(
              legalidadeInner.remaining_rl_total
            ),
            name: i18n.t("remainingRL"),
            value: ConvertToNumber(legalidadeInner.remaining_rl_total),
            totalVal: ConvertToNumber(legalidadeInner.remaining_rl_total),
            color: "rgb(128, 198, 77)",
            isPattern: false,
          },
        ]);
      } else {
        setDataAPP("");
        setDataRL("");
      }
    }
  }, [legalidadeInner, sizeFilter, type]);

  const conditionalArray = [[i18n.t("remainingAPP")]];
  const conditionalArray2 = [[i18n.t("remainingRL")]];
  /* const customColors = ["#F3E6C9", "#78C753", "#AC6C29"]; */
  const customColors = ["#80C64D"];

  return (
    <div className="tcf-map-report--body-territorialcut--ischildren">
      <div className="tcf-map-report--body-territorialcut--ischildren-item">
        <div className="tcf-map-report--body-territorialcut--ischildren-item--title">
          {i18n.t("remainingRL")}
        </div>
        <div className="tcf-map-report--body-territorialcut--ischildren-item--body">
          <ResponsiveBar
            data={dataRL}
            keys={conditionalArray2}
            indexBy="size"
            groupMode="grouped"
            margin={{ top: 10, right: 50, bottom: 30, left: 90 }}
            padding={0.6}
            innerPadding={8}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={customColors}
            /* colors={(bar) =>
              type === "settlements"
                ? "#78C753"
                : bar.index === 0
                  ? sizeFilter?.value === "G"
                    ? "#AC6C29"
                    : sizeFilter?.value === "M"
                      ? "#78C753"
                      : "#F3E6C9"
                  : bar.index === 1
                    ? "#78C753"
                    : "#AC6C29"
            } */

            borderColor={{
              from: "color",
              modifiers: [["darker", 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisLeft={{
              tickSize: 0,
              tickPadding: 6,
              tickRotation: 0,
              legend: i18n.t("areaHa"),
              legendPosition: "middle",
              legendOffset: -80,
              truncateTickAt: 0,
            }}
            tooltip={({ id, value }) => {
              const valueFormated = formatNumber(value, 0, ".", ",");
              return <NivoCustomTooltip id={id} value={valueFormated} />;
            }}
            enableLabel={false}
          />
        </div>
        <CustomLegend data={dataRL} useIcone={type === "settlements"} />
        {/* {type !== "settlements" && <CustomLegend data={dataRL} />} */}
      </div>
      <div className="tcf-map-report--body-territorialcut--ischildren-item">
        <div className="tcf-map-report--body-territorialcut--ischildren-item--title">
          {i18n.t("remainingAPP")}
        </div>
        <div className="tcf-map-report--body-territorialcut--ischildren-item--body">
          <ResponsiveBar
            data={dataAPP}
            keys={conditionalArray}
            indexBy="size"
            margin={{ top: 10, right: 50, bottom: 30, left: 90 }}
            padding={0.6}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={customColors}
            /* colors={(bar) =>
              type === "settlements"
                ? "#78C753"
                : bar.index === 0
                  ? sizeFilter?.value === "G"
                    ? "#AC6C29"
                    : sizeFilter?.value === "M"
                      ? "#78C753"
                      : "#F3E6C9"
                  : bar.index === 1
                    ? "#78C753"
                    : "#AC6C29"
            } */
            defs={[
              {
                id: "lines2",
                type: "patternLines",
                background: "rgba(120, 199, 83, 0.5)",
                color: "rgb(120, 199, 83)",
                rotation: 45,
                lineWidth: 6,
                spacing: 13,
              },
            ]}
            fill={[
              {
                match: {
                  id: [i18n.t("remainingAPP")],
                },
                id: "lines2",
              },
            ]}
            /* fill={[
              type === "settlements" && {
                match: {
                  id: [i18n.t("remainingAPP")],
                },
                id: "lines2",
              },
            ]} */
            axisTop={null}
            axisRight={null}
            axisLeft={{
              tickSize: 0,
              tickPadding: 6,
              tickRotation: 0,
              legend: i18n.t("areaHa"),
              legendPosition: "middle",
              legendOffset: -80,
              truncateTickAt: 0,
            }}
            tooltip={({ id, value }) => {
              const valueFormated = formatNumber(value, 0, ".", ",");
              return <NivoCustomTooltip id={id} value={valueFormated} />;
            }}
            enableLabel={false}
          />
        </div>

        {/* {type !== "settlements" && <CustomLegend data={dataAPP} />} */}
        <CustomLegend data={dataAPP} useIcone={type === "settlements"} />
      </div>
    </div>
  );
};

export default Remanescente;
