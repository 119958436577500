import React, { useContext } from "react";
import styled from "styled-components";
import { getLanguage } from "service/axios.service";
import { sharedValuesContext } from "service/context";
import { StyledLogo } from "./Logo.styles";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as statisticsActions from "store/actions/statisticsActions";
// import LogoPt from "assets/logo1_pt.png";
import LogoPt from "assets/logos/logo-termometro.png";
import LogoEn from "assets/logos/logo-thermometer.png";

const logo = getLanguage() === "pt-BR" ? LogoPt : LogoEn;

const Logo = () => {
  const { sidebarOpen, PADDING_SIDEBAR } = useContext(sharedValuesContext);
  const dispatch = useDispatch();

  return (
    <Link to="/" onClick={() => dispatch(statisticsActions.clearDownload())}>
      <img src={logo} className="tcf-logo" />
    </Link>
  );
};

export default Logo;
