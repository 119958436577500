import i18n from "i18next";
import React from "react";
import { zoomToExtentMap } from "../controlMap";
import ControlButton from "../Controls/ControlButton";

function ZoomToExtent() {
  return (
    <ControlButton
      title={i18n.t("baseMap")}
      type="baseMap"
      Action={zoomToExtentMap}
      className="tcf-map--control__btn--not--mobile"
    />
  );
}

export default ZoomToExtent;
