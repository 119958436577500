/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from "react";
import i18n from "i18next";
import Modal from "../Modal";
import { Markup } from "interweave";
import { useSelector } from "react-redux";
import {
  StyledPopUp,
  StyledTriangle,
  Container,
  SaibaMaisContainer,
  Wrap,
} from "./styled";
import { Close } from "lib/icons/svg";

function PopUp(props) {
  const { sobreInner } = useSelector((state) => state.sobre);
  const [isOpen, setIsOpen] = useState(false);

  const learnMore =
    sobreInner &&
    sobreInner.filter((item) => item.name_section === "LearnMore");

  return props.trigger ? (
    <>
      <Container left={props.left}>
        <StyledTriangle />
        <Wrap>
          <StyledPopUp>
            {props.children}
            <button
              className="close-btn"
              onClick={() => props.setTrigger(false)}
            >
              <img alt="fechar" src={Close} />
            </button>
          </StyledPopUp>
          <SaibaMaisContainer
            type="button"
            onClick={() => {
              setIsOpen(true);
            }}
            style={{ textDecoration: "underline", marginLeft: "auto" }}
          >
            {i18n.t("learnMore")}
          </SaibaMaisContainer>
        </Wrap>
      </Container>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} useBtnEnd={false}>
        <Markup
          content={learnMore.length !== 0 ? learnMore[0].text_topic : null}
        />
      </Modal>
    </>
  ) : (
    ""
  );
}

export default PopUp;
