import React from "react";

function Text({ id, children }) {
  return (
    <small className="tcf-form-text" id={id}>
      {children}
    </small>
  );
}

export default Text;
