import api from "service/axios.service";
import { typesEmbargoes } from "./types";

export const fetchEmbargoes = (choice) => async (dispatch) => {
  const { type, territorialCut, territory } = choice || {};
  const { value: valueCut } = territorialCut || {};
  const { label, value } = territory || {};

  try {
    dispatch({ type: typesEmbargoes.FETCH_EMBARGOES_REQUEST });
    const newValueCut = [
      "rural_properties",
      "traditional_territories",
    ].includes(valueCut)
      ? "municipality"
      : valueCut;

    const res = await api.post(`/v2/embargoTerritory/${newValueCut}/`, {
      codTerritory: valueCut === "biome" ? label : value,
    });

    const { data } = res || {};
    const [values] = data || [];
    dispatch({
      type: typesEmbargoes.FETCH_EMBARGOES_SUCCESS,
      payload: values || {},
    });
  } catch ({ response }) {
    const { message } = response.data;
    const errorMessage = message || "Erro desconhecido";

    dispatch({
      type: typesEmbargoes.FETCH_EMBARGOES_FAILURE,
      payload: errorMessage,
    });
  }
};
