import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
export default api;
export const getLanguage = () => {
  const lang = localStorage.getItem("@language");
  return lang || "pt-BR";
};
