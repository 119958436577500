import {
  ACOES_FAIL,
  ACOES_REQUEST,
  ACOES_SUCCESS,
} from "../constants/acoesConstants";

const acoesReducer = (state = {}, action) => {
  switch (action.type) {
    case ACOES_REQUEST:
      return { loading: true, acoesInner: [] };
    case ACOES_SUCCESS:
      return { loading: false, acoesInner: action.payload };
    case ACOES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export default acoesReducer;
