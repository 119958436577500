import styled from "styled-components";
import { alignment, direction } from "styles";

const Container = styled.div`
  width: 600px;
  height: 100%;
  padding-top: 40px;
  ${direction("column")};
  ${alignment};
`;
export { Container };
