export const pt = {
  translation: {
    // Global
    select_tenure_category: "Selecione o recorte fundiário",
    territory_category: "Selecione o recorte territorial",
    select_territory: "Selecione o território",
    select_state: "Selecione um estado",
    select: "Selecione ou digite ...",
    brazil: "Brasil",
    states: "Estados",
    state: "Estado",
    municipalities: "Municípios",
    municipality: "Município",
    nameWebSite: "Termômetro do Código Florestal",
    no_information: "Sem informação",
    error: "Ocorreu um erro",
    downApp: "BAIXE O APLICATIVO",
    art: "Arte:",
    report: "Relatório",
    map: "Mapa",
    settlements: "Assentamentos",
    rural_properties: "Imóveis Rurais",
    quilombos: "Quilombos",
    zee_status: "status do zee",
    biome: "Bioma",
    traditional_territories: "Territórios Tradicionais",
    msg_traditional_territories:
      "Não há dados oficiais sobre a demografia e a localização dos territórios dos 28 segmentos de Povos e Comunidades Tradicionais no Brasil. A exceção diz respeito a povos indígenas e quilombolas, pois quanto a esses sujeitos coletivos há mais informações disponíveis.",
    land_reform_settlements: "Assentamentos da reforma agrária",
    forestCodeText:
      "É a Lei que regulamenta o uso do solo, sobretudo em imóveis rurais no Brasil. Tem como objetivo garantir que, em propriedades privadas, parte da vegetação seja conservada, visando o equilíbrio ambiental para todos. Em maio de 2012, foi aprovada a Lei 12.651, mais nova versão do Código Florestal e que substituiu a de 1965.",
    forestCodeText_2:
      "Hoje, as principais obrigações previstas são: a manutenção de Áreas de Preservação Permanente (APP), da Reserva Legal (RL) e a inscrição no Cadastro Ambiental Rural (CAR). Para os imóveis que precisam se adaptar à nova Lei, foi criado o Programa de Regularização Ambiental (PRA), com uma série de benefícios para recuperar áreas desmatadas.",
    car_area_small_properties: "Área Cadastrada Imóvel pequeno",
    car_area_medium_properties: "Área Cadastrada Imóvel médio",
    car_area_large_properties: "Área Cadastrada Imóvel grande",

    // MENUS
    about: "Sobre",
    objective: "Objetivo",
    whoDoesIt: "Quem faz",
    articles: "Publicações",
    code: "Código",
    understand: "Entenda",
    glossary: "Glossário",
    contact: "Contato",
    platform: "Plataforma",
    access_platform: "Accessar plataforma",
    understandTheData: "Entenda os Dados",
    theObservatory: "O Observatório",
    dataMenu: "MENU DE DADOS",
    totalStateArea: "Área total do",
    exploreMap: "Explore o mapa",
    accessTheReport: "Acesse o relatório",
    downloadTheData: "Baixe os dados",
    learnMore: "Saiba mais",
    objectiveText:
      "Prover dados para que a sociedade acompanhe a implementação da Lei de Proteção da Vegetação Nativa do Brasil, também conhecida como “o novo Código Florestal”. Com informações confiáveis, visa-se tomada de decisões que consideram os direitos sociais no campo",
    whoDoesItText:
      "Iniciativa do Observatório do Código Florestal (OCF), desenvolvida pelo Instituto de Pesquisa Ambiental da Amazônia (IPAM) em colaboração com o Comitê Técnico e Científico de instituições-membros do OCF. São elas: Instituto Centro de Vida (ICV), Instituto de Manejo e Certificação Florestal e Agrícola (Imaflora), Instituto Socioambiental (ISA), Centro de Sensoriamento remoto da Universidade Federal de Minas Gerais (CSR/UFMG), Amigos da Terra – Amazônia Brasileira (AdT) e Instituto BVRio.",
    layersButton: "Todas as Camadas",
    filtersButton: "Todos os filtros",
    resetButton: "Limpar",

    // Parallax
    theThermometer: "O Termômetro",
    methodology: "Metodologia",
    legendParallax:
      "Essa iniciativa acompanha a implementação do Código Florestal (Lei nº 12.651, de 2012) nos estados e municípios, promovendo a transparência e o controle social por meio da geração de informações qualificadas.",
    accessPlatform: "ACESSAR PLATAFORMA",
    theForestCodeThermometer: "O Termômetro do Código Florestal",
    thermometerText:
      "Plataforma com dados e análises sobre a implementação do Código Florestal (Lei nº 12.651, de 2012) no Brasil, biomas, estados e municípios.",

    // Footer
    anInitiativeOf: "INICIATIVA",
    inColaborationWitch: "COLABORAÇÃO",
    developedBY: "DESENVOLVIMENTO",
    financedBY: "APOIO",

    // Mapa
    low: "Baixa",
    know: "Conheça",
    access: "Acessar",
    high: "Alta",
    legend: "Legenda",
    errorLoadLayer:
      "Erro ao carregar camada. Atualize a página e tente novamente!",
    baseMap: "Mapa base",
    layers: "Camadas",
    to: "a",
    attended: "Atendeu",
    criteria: "critérios",
    atc: "Atendeu todos os critérios",
    apc: "Atendeu parte dos critérios",
    nca: "Nenhum critério atendido",
    layerNotFound: "Camada não encontrada!",
    validatedStateZee: "ZEE do estado validado",
    validatedZones: "Zonas validadas",
    withoutZeeOrNotValidate: "Sem ZEE ou não validado",
    downloadSuccess: "Download realizado com successo.",
    downloadError: "Erro ao fazer download.",

    // Tools
    maps: "Mapas",
    forest_code: "Código florestal",
    tools: "Ferramentas",
    data: "Dados",
    statistics: "Estatísticas",
    reports: "Relatórios",
    mapsText:
      "Possibilita a visualização em um mapa personalizado por filtros, navegável em nível municipal, estadual, nacional e por biomas.",
    dataText:
      "Permite baixar os dados que compõem a plataforma em um arquivo .xls, para uma pesquisa mais completa, conforme a necessidade do usuário",
    reportText:
      "A partir do recorte fundiário e territorial selecionado, é possível gerar gráficos que simplificam a visualização e compreensão dos dados",
    understandTheDataText:
      "Os dados estão divididos por categorias fundiárias. Para cada recorte é possível visualizar o status da conformidade com a Lei, da regulamentação de instrumentos como o PRA e o ZEE, e do nível de transparência dos dados públicos.",
    settlementsText:
      "Terras destinadas à reforma agrária no Brasil, visando promover a justiça social e o desenvolvimento sustentável no meio rural.",
    traditionalTerritoriesText:
      "Espaços necessários à reprodução cultural, social e econômica dos povos e comunidades tradicionais, sejam eles utilizados de forma permanente ou temporária, observado, no que diz respeito aos povos indígenas e povos e comunidades tradicionais.",
    ruralPropertiesText:
      "Propriedades e posses rurais destinadas à atividades agropecuárias, seguindo as diretrizes do Código Florestal no que diz respeito à proteção da vegetação nativa e uso sustentável dos recursos naturais. De acordo com o número de módulos fiscais (MF), os imóveis ruais (IRU) são classificados em pequenos (IRU<4MF) , médios (4MF > IRU  ≤ 15 MF) e grandes (>15 MF).",
    contactText: "Envie suas dúvidas, pedidos de informações e sugestões.",

    // Relatório
    cccf: "Conformidade com o Código Florestal",
    registration: "Cadastramento",
    legality: "legalidade",
    forestBalance: "Balanço Florestal",
    overlays: "Sobreposições",
    apidl: "Ações para implementação da lei",
    aev: "Análise e Validação",
    transparency: "Transparência",
    sdzee: "Status do Zoneamento Ecológico-Econômico",
    // carVoids: "Vazios do CAR",
    nonRegisteredArea: "Área não cadastrada",
    registeredArea: "Área cadastrada",
    surplusRL: "Excedente RL",
    passiveTotal: "Passivo total",
    remainingTotal: "Remanescente total",
    passive: "Passivo",
    passiveTotalRL: "Passivo total RL",
    passiveTotalAPP: "Passivo total APP",
    passiveRL: "Passivo RL",
    passiveAPP: "Passivo APP",
    largeProperties: "Imóveis grandes",
    large: "Grande",
    medium: "Médio",
    small: "Pequeno",
    mediumProperties: "Imóveis médios",
    smallProperties: "Imóveis pequenos",
    remaining: "Remanescente",
    remainingRL: "Remanescente RL",
    remainingAPP: "Remanescente APP",
    surplus: "Excedente",
    totalSurplusRL: "Total Excedente RL",
    areaHa: "Área (ha)",
    embargoes: "Embargos",
    suppression: "Autorização de supressão (ASV)",

    ASTUC: "Assentamento x UC",
    ASTTI: "Assentamento x TI",
    ASTQUI: "Assentamento x quilombola",
    ASTAST: "Assentamento x assentamento",
    ASTFPND: "Assentamento x florestas públicas",
    ASTIRU: "Assentamento x imóveis rurais",

    IRUUC: "Imóveis rurais x UC",
    IRUTI: "Imóveis rurais x TI",
    IRUQUI: "Imóveis rurais x quilombola",
    IRUAST: "Imóveis rurais x assentamento",
    IRUFPND: "Imóveis rurais x florestas públicas",

    PCTsUC: "Territórios tradicionais x UC",
    PCTsTI: "Territórios tradicionais x TI",
    PCTsQUI: "Territórios tradicionais x quilombola",
    PCTsAST: "Territórios tradicionais x assentamento",
    PCTsFPND: "Territórios tradicionais x florestas públicas",

    nCarAnalyzed: "Nº de CAR analisados",
    nCarValidated: "Nº de CAR validados",
    nCarSigned: "Nº de TC firmados",
    transparencyActive: "Transparência ativa",
    transparencyPassive: "Transparência passiva",

    // Envio de mensagem

    successSendMessage: "Mensagem enviada com sucesso.",
    errorSendMessage: "Desculpe, não foi possível enviar a mensagem!",
    yourName: "Seu nome",
    yourMail: "Seu email",
    yourMessage: "Sua mensagem",
    send: "Enviar",
    wait: "Aguarde...",
    mailValid: "Insira um endereço de email válido",

    // Quem somos
    WhoProducesTheThermometer: "Quem faz o termômetro",
    WhoProducesTheThermometerText:
      "Desde 2018, o Observatório do Código Florestal (OCF), rede de organizações da sociedade civil que monitora os avanços da Lei Florestal, tem desenvolvido uma metodologia para a análise e interpretação do Cadastro Ambiental Rural (CAR) e para avaliar a implementação do Código Florestal. Para isso, foi formado um Comitê Técnico e Científico, com a participação de especialistas de instituições membro do OCF, entre elas o Instituto Centro de Vida (ICV), Instituto de Manejo e Certificação Florestal e Agrícola (Imaflora), Instituto de Pesquisa Ambiental da Amazônia (IPAM), Instituto Socioambiental (ISA), Centro de Sensoriamento remoto da Universidade Federal de Minas Gerais (CSR/UFMG), Amigos da Terra – Amazônia Brasileira e Instituto BVRio. Com discussões periódicas, o Comitê está sempre atualizando e aprimorando a metodologia utilizada nesta plataforma, além de trabalhar em novos dados, conforme os avanços na implementação da Lei.",
    unknownError: "Erro desconhecido. Tente novamente.",
    cookieConsent:
      "Usamos cookies para garantir uma melhor experiência de navegação, bem como cookies de rastreamento para entender como você interage com o nosso site.",
    acceptTerm: "Aceitar termo",
    reject: "Recusar",
  },
};

export default pt;
