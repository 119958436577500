import React from "react";
import { withTranslation } from "react-i18next";

const Hero = ({ size, children }) => (
  <section className={`tcf-hero__section ${size}`}>
    <div className="tcf-hero__container">
      <div className="tcf-hero__content">{children}</div>
    </div>
  </section>
);

export default withTranslation()(Hero);
