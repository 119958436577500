import i18n from "i18next";
import api, { getLanguage } from "service/axios.service";
import { statistics } from "./types";

export const download = () => async (dispatch) => {
  dispatch({ type: statistics.DOWNLOAD_REQUEST });
  api
    .post(
      `/v1/statistics/download/${getLanguage()}`,
      {},
      {
        responseType: "blob",
      }
    )
    .then((res) => {
      const fileURL = window.URL.createObjectURL(new Blob([res.data]));
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", "statisticis-termometro.xlsx");
      document.body.appendChild(fileLink);
      fileLink.click();

      dispatch({
        type: statistics.DOWNLOAD_SUCCESS,
        payload: i18n.t("downloadSuccess"),
      });
    })
    .catch((error) => {
      const { response } = error || {};
      const { statusText } = response || {};

      const msg = `${i18n.t("downloadError")} ${statusText}`;

      dispatch({
        type: statistics.DOWNLOAD_FAIL,
        payload: msg,
      });
    });
};

export const clearDownload = () => (dispatch) => {
  dispatch({ type: statistics.DOWNLOAD_CLEAR });
};
