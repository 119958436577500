import api from "service/axios.service";
import { linksApp } from "./types";
import i18n from "i18next";

export const linksAppAction = () => async (dispatch) => {
  dispatch({ type: linksApp.LINKS_APP_REQUEST });
  try {
    const res = await api.get("/v1/link-app");
    const { data } = res || {};
    dispatch({
      type: linksApp.LINKS_APP_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: linksApp.LINKS_APP_FAIL,
      payload: { message: i18n.t("errorSendMessage"), type: "error" },
    });
  }
};
