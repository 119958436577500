import React, { useState, useEffect } from "react";
import TitleSubTitle from "./TitleSubTitle";
import SobreTCF from "./SobreTCF";

const SobreOTermometro = () => (
  <div className="tcf-about__container" id="about">
    <div className="tcf-container">
      <div className="tcf-row">
        <div className="tcf-col-12">
          <TitleSubTitle />
          <SobreTCF />
        </div>
      </div>
    </div>
  </div>
);

export default SobreOTermometro;
