/* eslint-disable prettier/prettier */
import React from "react";
import Button from "components/Button/index";
import close from "assets/close.svg";

import * as S from "./styled";

// Passe dentro para a modal o isOpen e a funcção que altera o estado de isOpen
// generalFunction, é uma função que você quer que execute assim que pressionado o button, se quiser não quiser nenhum ação, não passe nada pois dessa forma a modal só fechará
// No generalFunction, no minimo passe a função de fechar a modal, está dessa forma pq as vezes podemos esperar uma requisição, e é por isso que não passo direto o btn de fechar a modal
// Passe o texto do btn em textBtn
// Caso nao queira usar o btn deste comp, só passar useBtnEnd como false e passar seu proprio btn como child, como no exepmlo abaixo.
// Para passar o conteúdo da modal passe dentro do componente, ex: <Modal/> EXEMPLO </Modal>

export default function Modal({
  isOpen = false,
  setIsOpen = () => {},
  isClose = () => {},
  btnClose = true,
  geralFunction = () => {},
  textBtn = "Entendi",
  styleContent = {},
  useBtnEnd = true,
  ...props
}) {
  return isOpen ? (
    <S.containerTransparendModal>
      <S.containerModal style={styleContent}>
        {btnClose ? (
          <S.closeModal
            type="button"
            onClick={() => {
              setIsOpen(false);
              isClose();
            }}
          >
            <img src={close} alt="botão de fechar" style={{ width: "10px" }} />
          </S.closeModal>
        ) : null}

        <S.content>
          {props.children || (
            <p className="notes">Não esqueça de passar o texto ;)</p>
          )}
        </S.content>

        {useBtnEnd ? (
          <Button
            text={textBtn}
            onClick={() => {
              geralFunction();
              setIsOpen(false);
            }}
          />
        ) : null}
      </S.containerModal>
    </S.containerTransparendModal>
  ) : null;
}
