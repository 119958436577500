export const deviceSizes = {
  mobile: 480,
  tablet: 1024,
  laptop: 1440,
};

export const device = {
  mobile: `${deviceSizes.mobile}px`,
  tablet: `${deviceSizes.tablet}px`,
  laptop: `${deviceSizes.laptop}px`,
};
