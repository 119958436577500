import { toast, Zoom } from "react-toastify";

export const showToast = (msg, params = {}) => {
  params.position = params.position || "top-center";
  params.autoClose = params.autoClose || 3000;
  params.hideProgressBar = params.hideProgressBar || false;
  params.closeOnClick = params.closeOnClick || true;
  params.pauseOnHover = params.pauseOnHover || true;
  params.type = params.type || "info";
  params.transition = Zoom;

  toast(msg || "Toast, sua mensagem!", params);
};
