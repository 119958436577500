import { useEffect } from "react";
import { useSelector } from "react-redux";
import { showToast } from "helpers/Toast";

const Message = () => {
  const { result } = useSelector((state) => state.sendMail);

  useEffect(() => {
    const { message, type } = result || {};
    if (message)
      showToast(message, {
        type: type,
        autoClose: 3000,
        position: "bottom-center",
      });
  }, [result]);

  return null;
};

export default Message;
