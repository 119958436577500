import {
  ANALISE_FAIL,
  ANALISE_REQUEST,
  ANALISE_SUCCESS,
} from "../constants/analiseEValidacaoConstants";

const analiseReducer = (state = {}, action) => {
  switch (action.type) {
    case ANALISE_REQUEST:
      return { loading: true, analiseInner: {} };
    case ANALISE_SUCCESS:
      return { loading: false, analiseInner: action.payload };
    case ANALISE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export default analiseReducer;
