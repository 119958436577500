import React from "react";
import ControlButton from "../ControlButton";
import { zoomInOutMap } from "../../controlMap";

function ControlInOut() {
  return (
    <div className="tcf-map--control__btn--group tcf-map--control__btn--not--mobile">
      <ControlButton
        title="Mais zoom"
        Action={() => zoomInOutMap("In")}
        type="In"
        directAction
      />
      <ControlButton
        title="Menos zoom"
        Action={() => zoomInOutMap("Out")}
        type="Out"
        directAction
      />
    </div>
  );
}

export default ControlInOut;
