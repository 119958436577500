import ReactGA from "react-ga4";

export const initGA = (id) => {
  ReactGA.initialize(id, {
    debug: false,
    gaOptions: {
      cookieFlags: "max-age=7200;secure;samesite=none",
      // 'max-age=2600000;domain=example.com;path=/;secure;samesite=none;SameSite=None;'
    },
  });
  ReactGA.ga("set", "forceSSL", true);
  ReactGA.send({ hitType: "pageview", page: "/plataforma" });
};
