import { methodology } from "../actions/types";

const initialState = {
  loadingMethodology: false,
  methodologyInner: [],
  errorReadMethodology: "",
};

const methodologyReducer = (state = initialState, action) => {
  switch (action.type) {
    case methodology.METHODOLOGY_REQUEST:
      return { ...state, loadingMethodology: true, methodologyInner: [] };
    case methodology.METHODOLOGY_COMMIT:
      return {
        ...state,
        loadingMethodology: false,
        methodologyInner: action.payload,
      };
    case methodology.METHODOLOGY_ROLLBACK:
      return {
        ...state,
        loadingMethodology: false,
        errorReadMethodology: action.payload,
      };
    default:
      return state;
  }
};

export default methodologyReducer;
