const { useState, createContext, useContext, useMemo } = require("react");

const ReportStore = createContext();

function ReportStoreProvider({ children }) {
  const [showReport, setShowReport] = useState(false);
  const [sizeFilter, setsizeFilter] = useState({
    value: "All",
    label: "Todos os tamanhos",
  });

  const handleShowReport = () => {
    setShowReport(!showReport);
  };

  const handlesizeFilter = (size) => {
    setsizeFilter(size);
  };

  const storeValue = useMemo(
    () => ({
      showReport,
      setShowReport,
      sizeFilter,
      setsizeFilter,
      handleShowReport,
      handlesizeFilter,
    }),
    [showReport, setShowReport, sizeFilter, setsizeFilter]
  );
  return (
    <ReportStore.Provider value={storeValue}>{children}</ReportStore.Provider>
  );
}

const useReportStore = () => useContext(ReportStore);

export { useReportStore, ReportStoreProvider };
