const legendBoxesReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_LEGEND_BOX":
      const findHere = state.legendBoxesInner.filter(
        (item) => item.id === action.payload
      )[0];
      const newObj = { ...findHere, active: !findHere.active };
      const filterHere = state.legendBoxesInner.filter(
        (item) => item.id !== action.payload
      );
      const newState = [...filterHere, newObj];
      return { legendBoxesInner: newState };
    default:
      return state;
  }
};

export default legendBoxesReducer;
