import i18n from "i18next";
import api from "service/axios.service";
import { settlementsBrasil } from "./types";

export const getSettlements = async () => async (dispatch) => {
  dispatch({ type: settlementsBrasil.SETTLEMENTS_REQUEST });
  try {
    const res = await api.get(`/v2/settlements`);

    const { data } = res || {};

    dispatch({
      type: settlementsBrasil.SETTLEMENTS_REQUEST_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    const { response } = error || {};
    const { data } = response || {};
    const { message } = data || {};

    const msg = message || i18n.t("unknownError");

    dispatch({
      type: settlementsBrasil.SETTLEMENTS_REQUEST_FAIL,
      payload: msg,
    });
  }
};
