import api from "service/axios.service";
import {
  MUNICIPALITIES_REQUEST,
  MUNICIPALITIES_FAIL,
  MUNICIPALITIES_SUCCESS,
} from "../constants/municipalitiesConstants";
import { sortArrayOfObject } from "helpers/utils";
import { selectUfGeocode } from "helpers/ufs";

export const listMunicipalities = async () => async (dispatch, getState) => {
  const { municipalitiesInner } = getState().municipalities;

  dispatch({ type: MUNICIPALITIES_REQUEST });
  try {
    if (municipalitiesInner && municipalitiesInner.length > 0) {
      dispatch({
        type: MUNICIPALITIES_SUCCESS,
        payload: municipalitiesInner,
      });
    } else {
      const result = await api.get("/v1/regions");

      const list = result.data.flatMap((s) =>
        s.states.flatMap((m) =>
          m.municipalities.flatMap((item) => {
            const uf = selectUfGeocode.filter(
              (state) => state.value === m.id
            )[0]?.label;
            return {
              geocode_uf: m.id,
              uf: uf,
              name: `${item.name} (${uf})`,
              id: item.id,
              type: "municipality",
            };
          })
        )
      );

      list.sort(sortArrayOfObject("name"));

      dispatch({
        type: MUNICIPALITIES_SUCCESS,
        payload: list,
      });

      return list;
    }
  } catch (error) {
    dispatch({
      type: MUNICIPALITIES_FAIL,
      payload: error.response?.data.message
        ? error.message.data.message
        : error.message,
    });
  }
};
