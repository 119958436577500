import styled from "styled-components";
import { color as systemColor, direction, alignment } from "styles";

const Wrapper = styled.div`
  ${direction};
  ${alignment({ cross: "flex-start" })};
  padding-top: 32px;
`;

const Title = styled.h2`
  color: #083739;
  text-align: center;
  font-size: 1.07rem;
  font-family: "Poppins", "Source Serif Pro", serif;
  margin-bottom: 25px;
  margin-top: 25px;
  font-weight: 600;
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  background: ${systemColor.white};
`;

const Box = styled.div`
  ${direction};
  ${alignment({ main: "center", cross: "flex-start" })};
`;

const Legenda = styled.div`
  margin-right: 50px;
  height: 100%;
  ${direction("column")};
  ${alignment({ main: "flex-start" })};
`;

const Item = styled.div`
  ${direction};
  ${alignment({ main: "flex-start" })};
  width: 100%;
  font-size: 15px;
`;

const Marker = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  background: ${({ color }) => color && color};
`;

export { Wrapper, Container, Box, Legenda, Item, Marker, Title };
