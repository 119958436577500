import {
  LEGALIDADE_FAIL,
  LEGALIDADE_REQUEST,
  LEGALIDADE_SUCCESS,
} from "../constants/legalidadeConstants";

const legalidadeReducer = (state = {}, action) => {
  switch (action.type) {
    case LEGALIDADE_REQUEST:
      return { loading: true, legalidadeInner: {} };
    case LEGALIDADE_SUCCESS:
      return { loading: false, legalidadeInner: action.payload };
    case LEGALIDADE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export default legalidadeReducer;
