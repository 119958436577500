import React from "react";
import { Link } from "react-router-dom";

function Article({ link, imagem, title }) {
  return (
    <div key={title} role="button" className="tcf-article">
      <Link className="tcf-article__thumbnail__link" to={link} target="_blank">
        <div className="tcf-article__thumbnail">
          <img src={imagem} alt={title} />
        </div>
      </Link>

      <div className="tcf-article__text">
        <h3 className="tcf-article__title">
          <Link to={link} target="_blank">
            {title}
          </Link>
        </h3>
      </div>
    </div>
  );
}

export default Article;
