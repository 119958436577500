import React from "react";
import i18n from "i18next";
import Image from "../../../assets/brazil.png";
import Button from "components/Button";
import { Link } from "react-router-dom";

const EntendaOsDados = () => (
  <div className="tcf-understanding-data" id="understand">
    <div className="tcf-container">
      <div className="tcf-row">
        <div className="tcf-col-12 tcf-understanding-data__wrapper">
          <div className="tcf-understanding-data--title">
            <h2>{i18n.t("understandTheData")}</h2>
            <p>{i18n.t("understandTheDataText")}</p>
          </div>

          <div className="tcf-understanding-data__land-tenure">
            <div>
              <img src={Image} alt="brazil" />
            </div>
            <div className="tcf-understanding-data__land-tenure-info">
              <div className="tcf-understanding-data__land-tenure-info--contaner">
                <h4>{i18n.t("settlements")}</h4>
                <p>{i18n.t("settlementsText")}</p>
              </div>
              <div className="tcf-understanding-data__land-tenure-info--contaner">
                <h4>{i18n.t("rural_properties")}</h4>
                <p>{i18n.t("ruralPropertiesText")}</p>
              </div>
              <div className="tcf-understanding-data__land-tenure-info--contaner">
                <h4>{i18n.t("traditional_territories")}</h4>
                <p>{i18n.t("traditionalTerritoriesText")}</p>
              </div>
              <div>
                <Link to="/metodologia">
                  <Button variant="outline-primary" size="md">
                    {i18n.t("understandTheData")}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default EntendaOsDados;
