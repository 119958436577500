import React from "react";
import {
  Header,
  Landingpage,
  ConsentCookie,
  Footer,
  Hero,
  Button,
  ButtonGroup,
  Blog,
} from "components";
import CodigoFlorestal from "components/Landingpage/SobreOTermometro/CodigoFlorestal";
import i18n from "i18next";
import { Link } from "react-router-dom";

const { SobreOTermometro, EntendaOsDados, Ferramentas } = Landingpage;
function Landing() {
  const scrollTo = (target) => {
    const top =
      document.getElementById(target).getBoundingClientRect().top +
      (window.scrollY || window.pageYOffset) -
      100;
    window.scrollTo({
      top, // Offset of -100 pixels
      /* top: target - 100,  */ // Offset of -100 pixels
      behavior: "smooth",
    });
  };
  return (
    <div className="tcf-landing-page" id="home">
      <Header />
      <Hero size="md">
        <h1>{i18n.t("theThermometer")}</h1>
        <p>{i18n.t("legendParallax")}</p>
        <ButtonGroup center>
          <Button
            onClick={() => scrollTo("about")}
            variant="link-dark"
            size="md"
          >
            {i18n.t("know")}
          </Button>
          <Link to="/plataforma">
            <Button variant="primary" size="md">
              {i18n.t("access_platform")}
            </Button>
          </Link>
        </ButtonGroup>
      </Hero>
      <SobreOTermometro />
      <Blog />
      <CodigoFlorestal />
      <Ferramentas />
      <EntendaOsDados />
      <Footer />
      <ConsentCookie />
    </div>
  );
}

export default Landing;
