export const color = {
  // Project
  primary: "#073739",
  dark: "#073234",
  secondary: "#1D6D73",
  gray: "#9BADAE",
  grayDark: "#757575",
  grayLight: "#D7DEDE",
  tertiary: "#F7F7F7",
  bg: "#F2F2F0",
  red: "#BF5A1D",
  orange: "#CF8C21",
  orange1: "#EA6212",
  orange2: "#F17112",
  orange3: "#FF9E09",

  yellow: "#D7BC2B",
  yellow1: "#FFB809",
  yellow2: "#FFD600",
  yellow3: "#d7bc2b",

  green: "#739437",
  green1: "#B5BC3C",
  green2: "#7DA13C",
  green3: "#083739",
  green4: "#d8de6f",

  salmon: "#EED4C5",
  skin: "#F3E1C6",

  blue1: "#1C6E74",
  blue2: "#A4C0C3",

  gray1: "#404040",
  gray2: "#939393",
  gray3: "#B1B1B1",

  // Escala de cores
  scaleGreen: "#7DA13C",
  scaleGreen2: "#B5BC3C",
  scaleYellow: "#FFD600",
  scaleOrange: "#FF9E09",
  scaleRed: "#EA6212",
  scaleGray: "#939393",

  iconOrange: "#FFB809",

  // B&W
  white: "#ffffff",
  black: "#000000",
  black2: "#444F63",

  font: "#ffffffad",
  title: "#648432",
  blackTransparent: "#00000008",
};

// CORES USADAS NA VERSÂO 3.0 (2023)
/* export const color = {
  orange1: "#AC6C29",

  yellow1: "#F7B718",
  yellow2: "#BAB696",
  yellow3: "#F3E6C9",

  green1: "#78C753",
  green2: "#034638",

  pink1: "F8D7DD",

  blue1: " #AFA4CE",

  gray1: "#7F746C",
  gray2: "#AFA7A1",
  gray3: "#DEDBD8",
  gray3: "#F6F5F4",

  // B&W
  white: "#FFFFFF",
  black: "#2D2926",
}; */
