import i18n from "i18next";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeMessage, sendMail } from "store/actions/sendMailActions";

import Message from "./message";
import { Button, Form } from "components";
const Contato = forwardRef((_, ref) => {
  const dispatch = useDispatch();
  const { loading, result } = useSelector((state) => state.sendMail);

  const { type } = result || { type: "" };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [validEmail, setValidMail] = useState(false);

  const send = (e) => {
    e.preventDefault();

    const payload = {
      name,
      email,
      message,
    };
    dispatch(sendMail(payload));
  };

  useEffect(() => {
    const usuario = (email && email.substring(0, email.indexOf("@"))) || "";
    const dominio =
      (email && email.substring(email.indexOf("@") + 1, email.length)) || "";

    if (
      usuario.length >= 1 &&
      dominio.length >= 3 &&
      usuario.search("@") === -1 &&
      dominio.search("@") === -1 &&
      usuario.search(" ") === -1 &&
      dominio.search(" ") === -1 &&
      dominio.search(".") !== -1 &&
      dominio.indexOf(".") >= 1 &&
      dominio.lastIndexOf(".") < dominio.length - 1
    ) {
      setValidMail(true);
    } else {
      setValidMail(false);
    }
  }, [email]);

  useEffect(() => {
    if (type && !loading) {
      setName("");
      setEmail("");
      setMessage("");
      dispatch(closeMessage());
    }
  }, [type]);

  const nameButton = loading ? (
    <>{i18n.t("wait").toUpperCase()}</>
  ) : (
    <>{i18n.t("send").toUpperCase()}</>
  );

  return (
    <div className="tcf-contact" ref={ref} id="contact">
      <div className="tcf-container">
        <h2 className="tcf-subtitle">{i18n.t("contact")}</h2>
        <p className="tcf-paragraph">{i18n.t("contactText")}</p>
        <div className="tcf-row tcf-form-container">
          <form className="tcf-col tcf-form--contact" onSubmit={send}>
            <Form.FormGroup>
              <Form.Label for="name">{i18n.t("yourName")}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                id="name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
                valid={name?.length > 0}
              />
              {/* <FormFeedback valid={name?.length > 0} /> */}
            </Form.FormGroup>
            <Form.FormGroup>
              <Form.Label for="email">{i18n.t("yourMail")}</Form.Label>
              <Form.Control
                type="email"
                name="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
                valid={validEmail && email?.length > 0}
                invalid={!validEmail && email?.length > 0}
              />
            </Form.FormGroup>
            <Form.FormGroup>
              <Form.Label for="message">{i18n.t("yourMessage")}</Form.Label>
              <Form.Textarea
                type="textarea"
                name="message"
                id="message"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                required
                valid={message?.length > 0}
              />
            </Form.FormGroup>

            {/* <FormGroup>
              <Label for="email">{i18n.t("yourMail")}</Label>
              <Input
                name="name"
                id="name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
                valid={name?.length > 0}
              />
              <FormFeedback valid={email?.length > 0} />
            </FormGroup> */}
            {/*  <FormGroup>
              <Label for="email">{i18n.t("yourMail")}</Label>
              <Input
                type="email"
                name="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
                valid={validEmail && email?.length > 0}
                invalid={!validEmail && email?.length > 0}
              />
              <FormFeedback valid={email?.length > 0} />
            </FormGroup> */}

            {/*  <FormGroup>
              <Label for="exampleText">{i18n.t("yourMessage")}</Label>
              <Input
                type="textarea"
                name="text"
                id="exampleText"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                required
                valid={message?.length > 0}
              />
              <FormFeedback valid={message?.length > 0} />
            </FormGroup> */}
            <Button variant="primary" size="md" style={{ width: "100%" }}>
              {nameButton}
            </Button>
          </form>
          <Message />
        </div>
      </div>
    </div>
  );
});

export default Contato;
