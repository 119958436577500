import React from "react";
import { useSelector } from "react-redux";
import { useReportStore } from "service/report.service";
import RecorteFundiario from "./RecorteFundiario";
import RecorteTerritorial from "./RecorteTerritorial";
import Territorio from "./Territorio";
import MapLayer from "../MapLayers";
import ResetFilter from "../ResetFilter";

function SelectorTerritory() {
  const choice = useSelector((state) => state.choice);
  const { type, territorialCut } = choice || {};
  const { value } = territorialCut || {};
  const { showReport } = useReportStore();

  return (
    <div className="tcf-sidebar__territory--selector">
      {!showReport ? (
        type && <MapLayer />
      ) : (
        <div className="tcf-sidebar__territory--selector-reports">
          <div className="tcf-sidebar__territory--selector--reset-filter__title">
            <svg
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 0.699951H1L6.6 7.32195V11.9L9.4 13.3V7.32195L15 0.699951Z"
                stroke="#78C753"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h2>Filtros</h2>
          </div>
          <ResetFilter isReport />
          <RecorteFundiario isReport />
          <div className="tcf-territorial-cutout--select">
            {type && <RecorteTerritorial />}
            {value && value !== "brazil" && <Territorio />}
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectorTerritory;
