/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";

function MenuBars({ onClick }) {
  return (
    <div onClick={() => onClick()} className="tcf-hamburger-menu">
      <span className="line line1" />
      <span className="line line2" />
      <span className="line line3" />
      <span className="line line3" />
    </div>
  );
}

export default MenuBars;
