import React from "react";
import { useSelector } from "react-redux";
import Loading from "components/Loading";

function LoadingLayer() {
  const { loadingLayer } = useSelector((state) => state.map);

  return loadingLayer && <Loading type="loading" />;
}

export default LoadingLayer;
