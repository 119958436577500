import {
  SOBRE_FAIL,
  SOBRE_REQUEST,
  SOBRE_SUCCESS,
} from "../constants/sobreConstants";

const statesReducer = (state = { sobreInner: [] }, action) => {
  switch (action.type) {
    case SOBRE_REQUEST:
      return { loading: true, sobreInner: [] };
    case SOBRE_SUCCESS:
      return { loading: false, sobreInner: action.payload };
    case SOBRE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export default statesReducer;
