import { typesSuppression } from "../actions/types";

const initialStateSuppression = {
  loadingPostSuppression: false,
  postSuppressionMessage: null,
  suppressions: [],
};

const suppressionReducer = (state = initialStateSuppression, action) => {
  switch (action.type) {
    case typesSuppression.FETCH_SUPPRESSION_REQUEST:
      return {
        ...state,
        loadingPostSuppression: true,
        postSuppressionMessage: null,
      };

    case typesSuppression.FETCH_SUPPRESSION_SUCCESS: {
      return {
        ...state,
        loadingPostSuppression: false,
        suppressions: action.payload,
      };
    }

    case typesSuppression.FETCH_SUPPRESSION_FAILURE:
      return {
        ...state,
        loadingPostSuppression: false,
        postSuppressionMessage: action.payload,
      };

    default:
      return state;
  }
};

export default suppressionReducer;
