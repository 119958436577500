import { ZEE_FAIL, ZEE_REQUEST, ZEE_SUCCESS } from "../constants/zeeConstants";

const zeeReducer = (state = {}, action) => {
  switch (action.type) {
    case ZEE_REQUEST:
      return { loading: true, zeeInner: [] };
    case ZEE_SUCCESS:
      return { loading: false, zeeInner: action.payload };
    case ZEE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export default zeeReducer;
