import React from "react";
import { useReportStore } from "service/report.service";
import { Form } from "components";
import { useSelector } from "react-redux";

function ReportSizeFilter() {
  const { type } = useSelector((state) => state.choice);

  const sizes = [
    { value: "All", label: "Todos os tamanhos" },
    { value: "G", label: "Grande" },
    { value: "M", label: "Médio" },
    { value: "S", label: "Pequeno" },
  ];

  const { sizeFilter, handlesizeFilter } = useReportStore();
  const placeholder = "Selecione...";

  return (
    type === "rural_properties" && (
      <div className="tcf-map-report--header--filter">
        <Form.Select
          placeholder={placeholder}
          onChange={handlesizeFilter}
          options={sizes}
          defaultValue={sizeFilter}
        />
      </div>
    )
  );
}

export default ReportSizeFilter;
