import React from "react";
import CountUp from "react-countup";

const AnimationCount = ({ value, color = "#ccc" }) => (
  <CountUp
    end={value}
    duration={3}
    separator="." // Define um separador para os milhares
    decimals={0} // Especifica a quantidade de casas decimais
    decimal=","
    // prefix="R$ " // Adiciona um prefixo ao valor
    suffix="%" // Adiciona um sufixo ao valor
    style={{ fontSize: "44px", color: color, fontWeight: "bold" }}
  />
);

export default AnimationCount;
