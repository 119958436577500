import { statistics } from "../actions/types";

const initialState = {
  loading: false,
  message: "",
  type: null,
};

const statisticsReducer = (state = initialState, action) => {
  switch (action.type) {
    // DOWNLOAD
    case statistics.DOWNLOAD_REQUEST:
      return { ...state, loading: true, message: "", type: null };
    case statistics.DOWNLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
        type: "success",
      };
    case statistics.DOWNLOAD_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
        type: "error",
      };
    case statistics.DOWNLOAD_CLEAR:
      return { ...state, message: "", type: "" };
    default:
      return state;
  }
};

export default statisticsReducer;
