import React from "react";
import Logo from "../Logo";
import Actions from "../Actions";

function SidebarHeader() {
  return (
    <div className="tcf-sidebar__header">
      <Logo />
      <Actions />
    </div>
  );
}

export default SidebarHeader;
