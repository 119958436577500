import React from "react";
import { useReportStore } from "service/report.service";
import Header from "./Header";
import Body from "./Body";

function ReportBar() {
  const { showReport } = useReportStore();
  return (
    <div
      className="tcf-map-report"
      style={{
        display: showReport ? "flex" : "none",
      }}
    >
      <Header />
      <Body />
    </div>
  );
}

export default ReportBar;
