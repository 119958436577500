import React, { createContext, useState } from "react";

export const sharedValuesContext = createContext({});

const OPEN_SIDEBAR = 370;
const CLOSE_SIDEBAR = 70;
const PADDING_SIDEBAR = 8;

export function SharedValuesProvider({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  return (
    <sharedValuesContext.Provider
      value={{
        sidebarOpen,
        setSidebarOpen,
        OPEN_SIDEBAR,
        CLOSE_SIDEBAR,
        PADDING_SIDEBAR,
      }}
    >
      {children}
    </sharedValuesContext.Provider>
  );
}
