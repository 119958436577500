import React from "react";
import styled from "styled-components";
import { color } from "styles";

const Area = ({ children, margin, ...props }) => (
  <StyledArea {...{ margin, ...props }}>{children}</StyledArea>
);

const StyledArea = styled.div`
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: ${({ margin }) => `${margin}px`};
  padding-top: 20px;
`;

export default Area;
