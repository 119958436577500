import React from "react";
import { Title } from "components";
import i18n from "i18next";

const TitleSubTitle = () => (
  <div className="tcf-about__title--subtitle">
    <Title>{i18n.t("theForestCodeThermometer")}</Title>
    <p>{i18n.t("thermometerText")}</p>
  </div>
);

export default TitleSubTitle;
