import api, { getLanguage } from "service/axios.service";
import {
  GLOSSARIO_FAIL,
  GLOSSARIO_REQUEST,
  GLOSSARIO_SUCCESS,
} from "../constants/glossarioConstants";

export const glossarioActions = () => async (dispatch) => {
  dispatch({ type: GLOSSARIO_REQUEST });
  try {
    const result = await api.get(`/v1/glossary/${getLanguage()}`);
    const list = result.data[0].glossary;

    dispatch({
      type: GLOSSARIO_SUCCESS,
      payload: list,
    });
  } catch (error) {
    dispatch({
      type: GLOSSARIO_FAIL,
      payload: error.response?.data.message
        ? error.message.data.message
        : error.message,
    });
  }
};
