import {
  CADASTRAMENTO_REQUEST,
  CADASTRAMENTO_SUCCESS,
  CADASTRAMENTO_FAIL,
} from "../constants/cadastramentoConstants";

const cadastramentoReducer = (state = {}, action) => {
  switch (action.type) {
    case CADASTRAMENTO_REQUEST:
      return { loading: true, cadastramentoInner: {} };
    case CADASTRAMENTO_SUCCESS:
      return { loading: false, cadastramentoInner: action.payload };
    case CADASTRAMENTO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export default cadastramentoReducer;
