import React, { useEffect } from "react";
import * as statisticsActions from "store/actions/statisticsActions";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "helpers/Toast";
import { Button } from "components";

const StatisticsButton = () => {
  const dispatch = useDispatch();
  const { loading, message, type } = useSelector((state) => state.statistics);

  useEffect(() => {
    if (type) {
      showToast(message, { type: type });
    }
  }, [message, type]);

  const onDownload = () => {
    dispatch(statisticsActions.download());
  };

  return (
    <Button
      loading={loading}
      multipleState
      variant="outline-primary"
      size="md"
      onClick={onDownload}
      style={{
        margin: `0px 0 0 10px`,
      }}
    >
      <span className="spinner tcf-d-inline-block">
        <span className="bounce1" />
        <span className="bounce2" />
        <span className="bounce3" />
      </span>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.5 10V12.6667C14.5 13.0203 14.3595 13.3594 14.1095 13.6095C13.8594 13.8595 13.5203 14 13.1667 14H3.83333C3.47971 14 3.14057 13.8595 2.89052 13.6095C2.64048 13.3594 2.5 13.0203 2.5 12.6667V10"
          stroke="#78C753"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.1665 6.66669L8.49984 10L11.8332 6.66669"
          stroke="#78C753"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 10V2"
          stroke="#78C753"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Button>
  );
};

export default StatisticsButton;
