import i18n from "i18next";
import api from "service/axios.service";
import { regionInfo } from "./types";

export const getRegionInfo = (geocode) => async (dispatch, getState) => {
  const choice = getState().choice;
  const { territorialCut } = choice || {};
  const { value: typeTerritory } = territorialCut || {};

  const route =
    typeTerritory === "biome"
      ? `/v1/biome/info/${geocode}`
      : `/v1/region/info/${geocode}`; // Brasil, estados e municípios

  dispatch({ type: regionInfo.REGION_INFO_READ_REQUEST });
  try {
    const res = await api.get(route);

    const { data } = res || {};
    //const { area } = data || {};

    dispatch({
      type: regionInfo.REGION_INFO_READ_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    const { response } = error || {};
    const { data } = response || {};
    const { message } = data || {};

    const msg = message || i18n.t("unknownError");

    dispatch({
      type: regionInfo.REGION_INFO_READ_FAIL,
      payload: msg,
    });
  }
};
