import { typesEmbargoes } from "../actions/types";

const initialStateEmbargoes = {
  loadingPostEmbargoes: false,
  postEmbargoesMessage: null,
  embargoes: [],
};

const embargoesReducer = (state = initialStateEmbargoes, action) => {
  switch (action.type) {
    case typesEmbargoes.FETCH_EMBARGOES_REQUEST:
      return {
        ...state,
        loadingPostEmbargoes: true,
        postEmbargoesMessage: null,
      };

    case typesEmbargoes.FETCH_EMBARGOES_SUCCESS: {
      return {
        ...state,
        loadingPostEmbargoes: false,
        embargoes: action.payload,
      };
    }

    case typesEmbargoes.FETCH_EMBARGOES_FAILURE:
      return {
        ...state,
        loadingPostEmbargoes: false,
        postEmbargoesMessage: action.payload,
      };

    default:
      return state;
  }
};

export default embargoesReducer;
