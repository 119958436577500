import { biomesBrasil } from "../actions/types";

const initialState = {
  loading: false,
  biomesBrasil: [],
  error: false,
};

const biomesBrasilReducer = (state = initialState, action) => {
  switch (action.type) {
    // Filtro de quilombos
    case biomesBrasil.BIOMES_BRASIL_REQUEST:
      return { ...state, loading: true, error: false };
    case biomesBrasil.BIOMES_BRASIL_REQUEST_SUCCESS:
      return {
        loading: false,
        biomesBrasil: action.payload,
        error: false,
      };
    case biomesBrasil.BIOMES_BRASIL_REQUEST_FAIL:
      return {
        loading: false,
        biomesBrasil: action.payload,
        error: true,
      };
    default:
      return state;
  }
};

export default biomesBrasilReducer;
