import Label from "./Label";
import Control from "./Control";
import Textarea from "./Textarea";
import Select from "./Select";
import Range from "./InputRange";
import Check from "./Check";
import Text from "./Text";
import FormGroup from "./FormGroup";

const Form = {
  Label,
  Text,
  Control,
  Textarea,
  Range,
  Check,
  Select,
  FormGroup,
};

export default Form;
