import styled from "styled-components";

const StyledLogo = styled.img`
  height: 36px;
  width: 132px;
  cursor: pointer;

  ${({ sidebarOpen }) => !sidebarOpen && `transform: rotate(-90deg)`};
  transform-origin: bottom;

  position: absolute;
  ${({ sidebarOpen, PADDING_SIDEBAR }) =>
    sidebarOpen
      ? `top: 11px; left: ${PADDING_SIDEBAR}px`
      : `bottom: 98px; left: -13px;`};
`;

export { StyledLogo };
