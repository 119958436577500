import React, { useCallback, useRef, useState } from "react";

function Controle({
  /* style, */
  onChange,
  /* type = "text", */
  /* placeholder = "", */
  disabled = false,
  required = false,
  /* value = "", */
  className = "",
  /* name = "",
  id = "", */
  ...rest
}) {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const handlerInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handlerInputBlur = useCallback(() => {
    setIsFocused(false);
    /* setIsFiels(!!inputRef.current?.value); */
  }, []);
  return (
    <input
      onFocus={handlerInputFocus}
      onBlur={handlerInputBlur}
      ref={inputRef}
      className={`tcf-form-control${className ? ` ${className}` : ""}`}
      /* value={value} */
      disabled={disabled}
      /* required={required} */
      onChange={onChange}
      {...rest}
    />
  );
}

export default Controle;
