import React, { useRef } from "react";
import Logo from "./Logo";
import Actions from "./Actions";
import SelectorTerritory from "./Selector";
import SidebarHeader from "./Header";

function Sidebar() {
  const sideRef = useRef();

  return (
    <nav className="tcf-sidebar" ref={sideRef}>
      <SidebarHeader />
      <SelectorTerritory />
    </nav>
  );
}

export default Sidebar;
