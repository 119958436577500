import { layer } from "../actions/types";

const initialState = {
  loading: false,
  data: [],
  errorRead: "",
  loadingStatesGeojeon: false,
  statesGeojson: [],
  errorReadStatesGeojson: "",
};

const layerReducer = (state = initialState, action) => {
  switch (action.type) {
    // LAYERS_READ
    case layer.LAYERS_READ_REQUEST:
      return { ...state, loading: true };
    case layer.LAYERS_READ_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case layer.LAYERS_READ_FAIL:
      return { ...state, loading: false, errorRead: action.payload };

    // STATES_GEOJSON_READ
    case layer.STATES_GEOJSON_READ_REQUEST:
      return { ...state, loadingStatesGeojeon: true };
    case layer.STATES_GEOJSON_READ_SUCCESS:
      return {
        ...state,
        loadingStatesGeojeon: false,
        statesGeojson: action.payload,
      };
    case layer.STATES_GEOJSON_READ_FAIL:
      return {
        ...state,
        loadingStatesGeojeon: false,
        errorReadStatesGeojson: action.payload,
      };

    case layer.LAYERS_REDRAW_LIST_MENU:
      const { indexGroup, item, listAll } = action.payload;
      const { layer_link, checked } = item || {};
      const { name_mapfile } = layer_link || {};

      listAll.map((l) => {
        if (l.layer_link.is_type_radio) {
          l.checked =
            l.layer_link.name_mapfile === name_mapfile ? checked : false;
        }
      });

      state.data.layers_groups[indexGroup].layers_names = listAll;

      return { ...state, loading: false, data: state.data };

    default:
      return state;
  }
};

export default layerReducer;
