const { useState, createContext, useContext, useMemo } = require("react");

const LayersStore = createContext();

function LayersStoreProvider({ children }) {
  const [groups, setGroups] = useState([]);

  const getLayersGroups = (groups) => {
    setGroups(groups);
  };

  const storeValue = useMemo(
    () => ({
      groups,
      setGroups,
      getLayersGroups,
    }),
    [groups, setGroups]
  );
  return (
    <LayersStore.Provider value={storeValue}>{children}</LayersStore.Provider>
  );
}

const useLayersStore = () => useContext(LayersStore);

export { useLayersStore, LayersStoreProvider };
