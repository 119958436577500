import React from "react";
import ForestCodeImg_2 from "../../../assets/forestCode_2.png";
import ForestCodeImg from "../../../assets/forestCode.png";
import i18n from "i18next";
import { SubTitle } from "components";

const CodigoFlorestal = () => (
  <div className="tcf-forest-code" id="code">
    <div className="tcf-container">
      <div className="tcf-forest-code__box__container">
        <div className="tcf-forest-code__box">
          <div>
            <SubTitle>{i18n.t("forest_code")}</SubTitle>
            <p>{i18n.t("forestCodeText")}</p>
            <p>{i18n.t("forestCodeText_2")}</p>
          </div>
          <img src={ForestCodeImg} alt="fc" />
        </div>
        <div className="tcf-forest-code__box">
          <img src={ForestCodeImg_2} alt="6" />
        </div>
      </div>
    </div>
  </div>
);

export default CodigoFlorestal;
