import React from "react";

function ReportFeedback() {
  return (
    <div className="tcf-report-feedback">
      No data available for the bar chart.
    </div>
  );
}

export default ReportFeedback;
