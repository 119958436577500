import React from "react";
import PropTypes from "prop-types";

function NivoCustomTooltip({ id, value, unit }) {
  const valueAndUnit = unit === "%" ? `${value}${unit}` : `${value} ${unit}`;
  return (
    <div className="tcf-custom-bar-tooltip">
      <strong>{id}: </strong>
      {valueAndUnit}
    </div>
  );
}

export default NivoCustomTooltip;

NivoCustomTooltip.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unit: PropTypes.string,
};

NivoCustomTooltip.defaultProps = {
  id: "N/A",
  value: 0,
  unit: "ha",
};
