import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { acoesData } from "store/actions/acoesActions";
import { direction } from "styles";
import Panorama from "./Panorama";

const Acoes = () => {
  const dispatch = useDispatch();
  const { territory } = useSelector((state) => state.choice);
  const { acoesInner: data } = useSelector((state) => state.acoes);

  useEffect(() => {
    dispatch(acoesData());
  }, [dispatch]);

  // filtra os dados para o estado selecionado
  const dataFiltered = data && data.find((f) => f.geocode === territory?.value);

  // pega os dados de implementação da lei
  const { law_implementation } = dataFiltered || {};

  return (
    <Container>
      {law_implementation?.length > 0 &&
        law_implementation.map((item, idx) => (
          <Panorama
            key={idx}
            title={item.type}
            data={data} // dados de todos os estados para o gráfico
            lawImplementation={item} // implementação da lei do estado selecionado
            geocode={territory?.value}
          />
        ))}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 400px;
  ${direction("column")};
`;

export default Acoes;
