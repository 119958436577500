import { filterUf } from "../actions/types";

const initialState = {
  geocode_uf: "",
};

const filterUfReducer = (state = initialState, action) => {
  const geocode_uf = action?.result || {};

  switch (action.type) {
    case filterUf.FILTER_UF:
      return { ...state, geocode_uf };
    default:
      return state;
  }
};

export default filterUfReducer;
