import api, { getLanguage } from "service/axios.service";
import {
  ACOES_FAIL,
  ACOES_REQUEST,
  ACOES_SUCCESS,
} from "../constants/acoesConstants";

export const acoesData = (id) => async (dispatch) => {
  dispatch({ type: ACOES_REQUEST });
  try {
    const res = await api.post(`/v1/implementationLaw/${getLanguage()}`, {
      geocodes: id ? [id] : [],
    });

    const { data } = res || {};
    const [{ law_implementation }] = data || [{}];

    dispatch({
      type: ACOES_SUCCESS,
      payload: id && data && law_implementation ? law_implementation : data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: ACOES_FAIL,
      payload: error.response?.data.message
        ? error.message.data.message
        : error.message,
    });
  }
};
