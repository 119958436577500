import React, { useState } from "react";
import styled from "styled-components";
import { direction, alignment, color } from "styles";
import { Markup } from "interweave";
import { FaInfoCircle } from "react-icons/fa";

const Item = ({ item }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledItem onClick={() => setOpen(!open)}>
        <p>{item.item}</p>
        <FaInfoCircle />
      </StyledItem>
      {open && (
        <Desc>
          <Markup content={item.text_item} />
        </Desc>
      )}
    </>
  );
};

/* const P = styled.p`
  font-size: 26px;
`; */

const Desc = styled.div`
  padding: 5px 32px;
  p {
    color: ${color.green3};

    a:link {
      text-decoration: revert;
      color: ${color.green3};
      font-weight: bold;
    }

    a:visited {
      text-decoration: revert;
      color: ${color.green3};
      font-weight: bold;
    }
  }
`;

const StyledItem = styled.div`
  height: 46px;
  ${direction};
  ${alignment({ main: "space-between" })};
  padding: 0 32px;
  &:hover {
    background: ${color.yellow1};
    cursor: pointer;
  }
  p {
    color: ${color.green3};
    font-weight: bold;
  }
`;

export default Item;
