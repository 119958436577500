import i18n from "i18next";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { showToast } from "helpers/Toast";

/*
 * se ocorrer algum erro ao ativar uma camada
 * mostra a mensagem
 */

function Error() {
  const { loadingLayerError } = useSelector((state) => state.map);

  useEffect(() => {
    if (loadingLayerError)
      showToast(i18n.t("errorLoadLayer"), { type: "error", autoClose: 8000 });
  }, [loadingLayerError]);

  return null;
}

export default Error;
