import React from "react";
import CookieConsent from "react-cookie-consent";
import i18n from "i18next";

function ConsentCookie() {
  return (
    <CookieConsent
      location="bottom"
      buttonText={i18n.t("acceptTerm")}
      declineButtonText={i18n.t("reject")}
      cookieName="cookieConsent"
      style={{
        background: "#fff",
        color: "#7F746C",
        boxShadow: `0px 0px 8px 0px rgba(0, 0, 0, 0.10)`,
      }}
      buttonStyle={{
        background: "#78C753",
        color: "#fff",
        fontSize: "1rem",
        borderRadius: 0,
      }}
      expires={150}
    >
      {i18n.t("cookieConsent")}
      {/* <span style={{ fontSize: "10px" }}> Politica de cookies</span> */}
    </CookieConsent>
  );
}
export default ConsentCookie;
