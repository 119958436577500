/* eslint-disable prettier/prettier */
import React from "react";

function Loading({ style, size, type }) {
	return (
		<>
			{type === "loading" && (
				<div
					className="loading"
					style={{ ...style, width: `${size}`, height: `${size}` }}
				/>
			)}
			{type === "loading-simple" && (
				<div
					className="loading-simple"
					style={{ ...style, width: `${size}`, height: `${size}` }}
				/>
			)}
			{type === "circle-progress" && (
				<span
					className="tcf-loading"
					style={{ ...style, width: `${size}`, height: `${size}` }}
				>
					<svg className="tcf-loading-svg" viewBox="22 22 44 44">
						<circle
							className="tcf-loading-circle"
							cx="44"
							cy="44"
							r="20.2"
							fill="none"
							strokeWidth="3.6"
						/>
					</svg>
				</span>
			)}
			{type === "simple-circle-progress" && (
				<span
					className="tcf-loading-simple"
					style={{ ...style, width: `${size}`, height: `${size}` }}
				>
					<svg className="tcf-loading-svg" viewBox="22 22 44 44">
						<circle
							className="tcf-loading-circle"
							cx="44"
							cy="44"
							r="20.2"
							fill="none"
							strokeWidth="3.6"
						/>
					</svg>
				</span>
			)}
			{type === "linear-progress" && (
				<span
					className="tcf-multi-linear-progress-loading"
					style={{ ...style, width: `${size}`, height: `${size}` }}
				>
					<span className="tcf-multi-linear-progress-bar" />
				</span>
			)}
		</>
	);
}

export default Loading;
