import {
  GLOSSARIO_FAIL,
  GLOSSARIO_REQUEST,
  GLOSSARIO_SUCCESS,
} from "../constants/glossarioConstants";

const glossarioReducer = (state = { glossarioInner: [] }, action) => {
  switch (action.type) {
    case GLOSSARIO_REQUEST:
      return { loading: true, glossarioInner: [] };
    case GLOSSARIO_SUCCESS:
      return { loading: false, glossarioInner: action.payload };
    case GLOSSARIO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export default glossarioReducer;
