/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prettier/prettier */
import React, { useState } from "react";

function Accordion({
  size = "sm",
  title,
  content,
  indice = true,
  indiceContent = "",
  space,
  isLayer,
  className,
  style,
  active = false,
  onClick = () => { }
}) {
  const [isActive, setIsActive] = useState(active);
  const handlerClick = () => {
    setIsActive(!isActive);
    onClick();
  }
  return (
    <div
      style={style}
      className={`tcf-accordion${className ? ` ${className}` : ""} ${size === "md" ? "tcf-accordion--medium" : "tcf-accordion--small"
        }`}
    >
      <div
        className={`tcf-accordion-header${space ? " tcf-accordion-header--space" : ""
          }`}
        onClick={
          handlerClick
        }

      >
        {space ? (
          <>
            <span
              className={`tcf-accordion-header-text ${isActive ? "accordion-expanded" : ""
                }`}
            >
              {isLayer ? (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 1L1 4.5L8 8L15 4.5L8 1Z"
                    stroke="#7F746C"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1 11.5L8 15L15 11.5"
                    stroke="#7F746C"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1 8L8 11.5L15 8"
                    stroke="#7F746C"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                ""
              )}

              {title}
            </span>
            <div className="tcf-accordion-expand-icon">
              {indice ? (
                <svg
                  className={`tcf-accordion-expand-svg-arrow ${isActive
                    ? "tcf-accordion-expand-svg-arrow--up"
                    : "tcf-accordion-expand-svg-arrow--down"
                    }`}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M4 6L8 10L12 6"
                    stroke="#7F746C"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <span
                  className={`tcf-accordion-indice ${isActive ? "tcf-accordion-indice--active" : ""
                    }`}
                >
                  {indiceContent}
                </span>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="tcf-accordion-expand-icon">
              {/* <i
            className={`tcf-accordion-expand-icon-arrow ${isActive ? "up" : "down"
              }`}
          ></i> */}
              {indice ? (
                <svg
                  className={`tcf-accordion-expand-svg-arrow ${isActive
                    ? "tcf-accordion-expand-svg-arrow--up"
                    : "tcf-accordion-expand-svg-arrow--down"
                    }`}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M4 6L8 10L12 6"
                    stroke="#7F746C"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <span
                  className={`tcf-accordion-indice ${isActive ? "tcf-accordion-indice--active" : ""
                    }`}
                >
                  {indiceContent}
                </span>
              )}
            </div>
            <span
              className={`tcf-accordion-header-text ${isActive ? "accordion-expanded" : ""
                }`}
            >
              {title}
            </span>
          </>
        )}
      </div>
      {isActive && (
        <div
          className={`tcf-accordion-content ${space ? "tcf-accordion-content--space" : ""
            }`}
        >
          {content}
        </div>
      )}
    </div>
  );
}

export default Accordion;
