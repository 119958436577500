import { map } from "../actions/types";

const initialState = {
  loadingLayer: false,
  loadingLayerError: false,
  activatedLayers: [],
};

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    // LOAD_START
    case map.LOAD_LAYER_START:
      return { ...state, loadingLayer: true, loadingLayerError: false };

    // LOAD_END
    case map.LOAD_LAYER_END:
      return { ...state, loadingLayer: false };

    // LOAD_ERROR;
    case map.LOAD_LAYER_ERROR:
      return { ...state, loadingLayer: false, loadingLayerError: true };

    // SET_ACTIVATED_LAYERS
    case map.SET_ACTIVATED_LAYERS:
      return { ...state, activatedLayers: action.payload };

    default:
      return state;
  }
};

export default mapReducer;
