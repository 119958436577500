/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cadastramentoData } from "store/actions/cadastramentoActions";
import i18n from "i18next";
import { ResponsiveBar } from "@nivo/bar";
import {
  ConvertToNumber,
  formatNumber,
  isEmptyObj,
  isObj,
  renderLegendNivo,
} from "helpers/utils";
import { useReportStore } from "service/report.service";

function Cadastramento() {
  const dispatch = useDispatch();
  const choice = useSelector((state) => state.choice);
  const { cadastramentoInner } = useSelector((state) => state.cadastramento);
  const [data, setData] = useState([]);
  const [dataLengend, setDataLegend] = useState([]);
  const customColors = ["#A66C29", "#F0B70E"];
  const { type } = choice || {};
  const { sizeFilter } = useReportStore();

  useEffect(() => {
    dispatch(cadastramentoData(choice));
  }, [dispatch, choice]);

  useEffect(() => {
    if (isObj(cadastramentoInner) && !isEmptyObj(cadastramentoInner)) {
      /* if (type === "settlements") {
        setData([
          {
            size: "",
            [i18n.t("registeredArea")]: Number(
              cadastramentoInner.registered_area
            ),

            [i18n.t("nonRegisteredArea")]: Number(
              cadastramentoInner.not_registered
            ),
          },
        ]);
      }
      if (type === "rural_properties") {
        if (sizeFilter?.value === "All") {
          setData([
            {
              size: "P",
              [i18n.t("registeredArea")]: Number(
                cadastramentoInner.car_area_small_properties
              ),
            },
            {
              size: "M",
              [i18n.t("registeredArea")]: Number(
                cadastramentoInner.car_area_medium_properties
              ),
            },
            {
              size: "G",
              [i18n.t("registeredArea")]: Number(
                cadastramentoInner.car_area_large_properties
              ),
            },
            {
              size: "",

              [i18n.t("nonRegisteredArea")]: Number(
                cadastramentoInner.not_registered
              ),
            },
          ]);
        }
        if (sizeFilter?.value === "G") {
          setData([
            {
              size: "G",
              [i18n.t("registeredArea")]: Number(
                cadastramentoInner.car_area_large_properties
              ),
            },
          ]);
        }
        if (sizeFilter?.value === "M") {
          setData([
            {
              size: "M",
              [i18n.t("registeredArea")]: Number(
                cadastramentoInner.car_area_medium_properties
              ),
            },
          ]);
        }
        if (sizeFilter?.value === "S") {
          setData([
            {
              size: "P",
              [i18n.t("registeredArea")]: Number(
                cadastramentoInner.car_area_small_properties
              ),
            },
          ]);
        }
      }
      if (type === "traditional_territories") {
        setData([
          {
            size: "P",
            [i18n.t("registeredArea")]: Number(
              cadastramentoInner.registered_area
            ),

            [i18n.t("nonRegisteredArea")]: Number(
              cadastramentoInner.not_registered
            ),
          },
        ]);
      } */

      /* setData([
        {
          name: i18n.t("registeredArea"), // "Area cadastrada",
          y: Number(cadastramentoInner.registered_area),
        },
        {
          name: i18n.t("nonRegisteredArea"), // "Vazios do CAR",
          y: Number(cadastramentoInner.empty_of_car),
        },
      ]); */
      setData([
        {
          [i18n.t("nonRegisteredArea")]: Number(
            cadastramentoInner.not_registered
          ),
          size: "",
          [i18n.t("registeredArea")]: Number(
            cadastramentoInner.registered_area
          ),
        },
      ]);
      setDataLegend([
        {
          name: i18n.t("registeredArea"),
          value: ConvertToNumber(cadastramentoInner.registered_area),
          totalVal: ConvertToNumber(cadastramentoInner.registration_area),
          color: "#F0B70E",
          isPattern: false,
        },
        {
          name: i18n.t("nonRegisteredArea"),
          value: ConvertToNumber(cadastramentoInner.not_registered),
          totalVal: ConvertToNumber(cadastramentoInner.registration_area),
          color: "#A66C29",
          isPattern: false,
        },
      ]);
    }
  }, [cadastramentoInner /* , sizeFilter, type */]);

  const conditionalArray = [
    [i18n.t("nonRegisteredArea")],
    [i18n.t("registeredArea")],
  ];

  // Define custom legend data with labels based on unique keys
  /*  const customLegends = dataLengend.map((key) => ({
    id: key.name,
    label: (
      <>
        <tspan
          style={{ color: "#7F746C", fontSize: "12px", marginBottom: "4px" }}
        >
          {key.name}
          <rect
            x="0"
            y="0"
            fill="#F0B70E"
            opacity="1"
            strokeWidth="0"
            stroke="transparent"
            width="20"
            height="20"
            style={{ pointerEvents: "none" }}
          />
        </tspan>
        <tspan x={25} dy={18} style={{ color: "#AFA7A1", fontSize: "12px" }}>
          {formatNumber((key.value / key.totalVal) * 100, 2, ".", ",")}% -{" "}
          {formatNumber(key.value, 0, ".", ",")} ha
        </tspan>
      </>
    ), // Use the key itself as the legend label
    color: key.color, // You can define custom colors here
  })); */
  const customLegends = dataLengend.map((d) =>
    renderLegendNivo({
      type: d.name,
      value: d.value,
      total: d.totalVal,
      color: d.color,
    })
  );
  /*  const conditionalArray =
    type === "rural_properties"
      ? [[i18n.t("registeredArea")]]
      : [[i18n.t("registeredArea")], [i18n.t("nonRegisteredArea")]]; */

  return (
    <div className="tcf-map-report--body-territorialcut-item--body--bar">
      {data && (
        <ResponsiveBar
          data={data}
          keys={conditionalArray}
          indexBy="size"
          margin={{ top: 0, right: 240, bottom: 45, left: 120 }}
          padding={0.6}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          valueFormat=">-,~"
          colors={customColors}
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={
            type === "rural_properties"
              ? {
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "",
                  legendPosition: "middle",
                  legendOffset: 32,
                  truncateTickAt: 0,
                }
              : false
          }
          axisLeft={{
            tickSize: 0,
            tickPadding: 15,
            tickRotation: 0,
            legend: i18n.t("areaHa"),
            legendPosition: "middle",
            legendOffset: -100,
            truncateTickAt: 0,
          }}
          enableLabel={false}
          legends={[
            {
              dataFrom: "keys",
              anchor: "right",
              direction: "column",
              justify: false,
              translateX: 140,
              translateY: 0,
              itemsSpacing: 20,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
              data: customLegends,
            },
          ]}
          /*  tooltip={({ indexValue, value }) =>
          `${indexValue}: ${formatNumber(value, 1, ".", ",")}`
        } */
          tooltip={({ id, value }) => (
            <div className="tcf-custom-bar-tooltip">
              <strong>{id} : </strong>
              {formatNumber(value, 0, ".", ",")} ha
            </div>
          )}
          role="application"
          ariaLabel="Nivo bar chart demo"
          barAriaLabel={(e) =>
            e.id + ": " + e.formattedValue + " in country: " + e.indexValue
          }
        />
      )}
    </div>
  );
}

export default Cadastramento;
