import React from "react";
import i18n from "i18next";

const Objetivo = () => (
  <div className="tcf-about__objetivo">
    <p className="text-xl">{i18n.t("objective")}</p>
    <p className="text-gray">{i18n.t("objectiveText")}</p>
  </div>
);

export default Objetivo;
