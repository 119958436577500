import React from "react";
import Image from "assets/termometro_3.png";
import Objetivo from "./Objetivo";
import QuemFaz from "./QuemFaz";

const SobreTCF = () => (
  <div className="tcf-about__description">
    <Objetivo />
    <div className="tcf-about__description--imagem">
      <img src={Image} alt="codigoFlorestal" />
    </div>
    <QuemFaz />
  </div>
);

export default SobreTCF;
