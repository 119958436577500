import React from "react";
import i18n from "i18next";
import { Header, Footer, Hero, MethodologyComponents } from "components";

const { UnderstandDatas, Glossary } = MethodologyComponents;

function Methodology() {
  return (
    <div className="" id="methodology">
      <Header />
      <Hero size="sm">
        <h1>{i18n.t("methodology")}</h1>
      </Hero>
      <UnderstandDatas />
      <Glossary />
      <Footer />
    </div>
  );
}

export default Methodology;
