export const layer = {
  LAYERS_READ_REQUEST: "@layer/LAYERS_READ_REQUEST",
  LAYERS_READ_SUCCESS: "@layer/LAYERS_READ_SUCCESS",
  LAYERS_READ_FAIL: "@layer/LAYERS_READ_FAIL",

  STATES_GEOJSON_READ_REQUEST: "@layer/STATES_GEOJSON_READ_REQUEST",
  STATES_GEOJSON_READ_SUCCESS: "@layer/STATES_GEOJSON_READ_SUCCESS",
  STATES_GEOJSON_READ_FAIL: "@layer/STATES_GEOJSON_READ_FAIL",

  LAYERS_REDRAW_LIST_MENU: "@layer/LAYERS_REDRAW_LIST_MENU",
};

export const map = {
  SET_ACTIVATED_LAYERS: "@map/SET_ACTIVATED_LAYERS",

  LOAD_LAYER_START: "@map/LOAD_LAYER_START",
  LOAD_LAYER_END: "@map/LOAD_LAYER_END",
  LOAD_LAYER_ERROR: "@map/LOAD_LAYER_ERROR",
};

export const sendMailUser = {
  SEND_MAIL_FAILURE: "@sendMail/SEND_MAIL_FAILURE",
  SEND_MAIL_REQUEST: "@sendMail/SEND_MAIL_REQUEST",
  SEND_MAIL_SUCCESS: "@sendMail/SEND_MAIL_SUCCESS",
  SEND_MAIL_ERROR: "@sendMail/SEND_MAIL_ERROR",
  CLOSE_MESSAGE: "@sendMail/CLOSE_MESSAGE",
};

export const regionInfo = {
  REGION_INFO_READ_REQUEST: "@layer/REGION_INFO_READ_REQUEST",
  REGION_INFO_READ_SUCCESS: "@layer/REGION_INFO_READ_SUCCESS",
  REGION_INFO_READ_FAIL: "@layer/REGION_INFO_READ_FAIL",
};

export const linksApp = {
  LINKS_APP_REQUEST: "@linksApp/LINKS_APP_REQUEST",
  LINKS_APP_SUCCESS: "@linksApp/LINKS_APP_SUCCESS",
  LINKS_APP_FAIL: "@linksApp/LINKS_APP_FAIL",
};

export const methodology = {
  METHODOLOGY_REQUEST: "@methodology/METHODOLOGY_REQUEST",
  METHODOLOGY_COMMIT: "@methodology/METHODOLOGY_COMMIT",
  METHODOLOGY_ROLLBACK: "@methodology/METHODOLOGY_ROLLBACK",
};

export const statistics = {
  DOWNLOAD_REQUEST: "@statistics/DOWNLOAD_REQUEST",
  DOWNLOAD_SUCCESS: "@statistics/DOWNLOAD_SUCCESS",
  DOWNLOAD_FAIL: "@statistics/DOWNLOAD_FAIL",
  DOWNLOAD_CLEAR: "@statistics/DOWNLOAD_CLEAR",
};

export const barOpenOrClose = {
  OPEN_OR_CLOSE: "@barOpenOrClose/OPEN_OR_CLOSE",
};

export const filterUf = {
  FILTER_UF: "@filterUf/FILTER_UF",
};

export const quilombosBrasil = {
  QUILOMBOS_BRASIL_REQUEST: "@quilombosBrasil/QUILOMBOS_BRASIL_REQUEST",
  QUILOMBOS_BRASIL_SUCCESS: "@quilombosBrasil/QUILOMBOS_BRASIL_SUCCESS",
  QUILOMBOS_BRASIL_FAIL: "@quilombosBrasil/QUILOMBOS_BRASIL_FAIL",
};

export const biomesBrasil = {
  BIOMES_BRASIL_REQUEST: "@biomesBrasil/BIOMES_BRASIL_REQUEST",
  BIOMES_BRASIL_REQUEST_SUCCESS: "@biomesBrasil/BIOMES_BRASIL_REQUEST_SUCCESS",
  BIOMES_BRASIL_REQUEST_FAIL: "@biomesBrasil/BIOMES_BRASIL_REQUEST_FAIL",
};

export const settlementsBrasil = {
  SETTLEMENTS_REQUEST: "@settlements/SETTLEMENTS_REQUEST",
  SETTLEMENTS_REQUEST_SUCCESS: "@settlements/SETTLEMENTS_REQUEST_SUCCESS",
  SETTLEMENTS_REQUEST_FAIL: "@settlements/SETTLEMENTS_REQUEST_FAIL",
};

export const typesEmbargoes = {
  FETCH_EMBARGOES_REQUEST: "FETCH_EMBARGOES_REQUEST",
  FETCH_EMBARGOES_SUCCESS: "FETCH_EMBARGOES_SUCCESS",
  FETCH_EMBARGOES_FAILURE: "FETCH_EMBARGOES_FAILURE",
};

export const typesSuppression = {
  FETCH_SUPPRESSION_REQUEST: "FETCH_SUPPRESSION_REQUEST",
  FETCH_SUPPRESSION_SUCCESS: "FETCH_SUPPRESSION_SUCCESS",
  FETCH_SUPPRESSION_FAILURE: "FETCH_SUPPRESSION_FAILURE",
};
