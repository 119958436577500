/* eslint-disable prettier/prettier */
import React from "react";

function Check({ id, checked, onChange, disabled = false, label, type }) {
  return (
    <div
      className={`tcf-form-check ${
        type === "switch" ? "tcf-form-switch" : "tcf-form-radio"
      }`}
    >
      {type === "radio" ? (
        <input
          id={id}
          type="radio"
          className="tcf-form-check-input"
          checked={checked}
          disabled={disabled}
          onChange={(e) => onChange(e)}
        />
      ) : (
        <input
          id={id}
          type="checkbox"
          className="tcf-form-check-input"
          checked={checked}
          disabled={disabled}
          onChange={(e) => onChange(e.target.checked)}
        />
      )}

      {/* <span className="switch" /> */}
      {label && (
        <label htmlFor={id} className="form-check-label">
          {label}
          <div />
        </label>
      )}
    </div>
  );
}

export default Check;
