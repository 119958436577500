import React, { useEffect } from "react";
import i18n from "i18next";
import { useSelector, useDispatch } from "react-redux";
import { zoomToExtentMap } from "../../../Map/controlMap";
import setChoice from "store/actions/choiceAction";
import * as regionActions from "store/actions/regionActions";
import { Form } from "components";

const RecorteTerritorial = () => {
  const dispatch = useDispatch();
  const choice = useSelector((state) => state.choice);
  const { type, territorialCut } = choice || {};

  const unitsTerritory = {
    settlements: i18n.t("settlements"),
    rural_properties: i18n.t("municipalities"),
    traditional_territories: i18n.t("municipalities"),
  };

  const menuList = [
    { label: i18n.t("brazil"), value: "brazil" },
    { label: i18n.t("biome"), value: "biome" },
    { label: i18n.t("states"), value: "state" },
    { label: unitsTerritory[type], value: type },
  ];

  // reseta para o Brasil, quando muda o recorte fundiário e o recorte territorial é
  // assentamento, imovéis rurais ou territórios tradicionais
  useEffect(() => {
    const { territorialCut } = choice || {};
    const { value } = territorialCut || {};

    const tcs = ["settlements", "rural_properties", "traditional_territories"];

    if (tcs.includes(value)) {
      handleChange(menuList[0]);
    }
  }, [type]);

  // quando seleciona um recorte territorial
  const handleChange = (opt) => {
    const { value } = opt || {};
    // se Brasil, retorna o zoom do mapa para nível inicial
    // e pega as infos referente ao Brasil
    if (value === "brazil") {
      zoomToExtentMap();
      dispatch(regionActions.getRegionInfo(1));
    }

    const newChoice = {
      ...choice,
      territorialCut: opt,
      territory: "",
    };
    dispatch(setChoice(newChoice));
  };

  return (
    <div className="tcf-map-recorte-territorial-side">
      <h2 className="tcf-sidebar__territory-selector-title">
        {i18n.t("territory_category")}:
      </h2>
      <Form.Select
        placeholder={i18n.t("select")}
        onChange={handleChange}
        options={menuList}
        defaultValue={territorialCut}
      />
    </div>
  );
};

export default RecorteTerritorial;
