/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable camelcase */
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { showToast } from "helpers/Toast";
import * as mapActions from "store/actions/mapActions";
import * as layerActions from "store/actions/layerActions";
import { color as fontColor } from "styles";
import { Form } from "components";

function Switch({ indexGroup, item, listAll }) {
  // reducer
  const dispatch = useDispatch();

  const { layer_link } = item || {};

  item.checked =
    item.checked === undefined ? layer_link.activated : item.checked || false;

  const handleClick = (checked) => {
    if (!layer_link.link && checked && layer_link.is_wms) {
      showToast(i18n.t("layerNotFound"), { type: "warning" });
    } else {
      dispatch(mapActions.toggleLayer({ checked, item, listAll }));

      item.checked = checked;

      // atualiza a lista do menu por causa das camadas
      // tipo radio, ou seja, ao ativar uma, desativa a outra
      dispatch(layerActions.redrawListMenu(indexGroup, item, listAll));
    }
  };

  return (
    <Form.Check
      type="switch"
      id={item.id}
      name={item.id}
      checked={item.checked}
      onChange={handleClick}
    />
  );
}

Switch.defaultProps = {
  color: fontColor.secondary,
  item: {},
};

Switch.propTypes = {
  color: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
};

export default Switch;
