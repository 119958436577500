import React from "react";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import Logo4 from "assets/Logo4.png";
import LogoIcv from "assets/logo_icv1.png";
import LogoImaflora from "assets/logo_imaflora1.png";
import LogoIpam from "assets/logo_ipam1.png";
// import LogoLagesa from "assets/logo_lagesa.jpeg";
import Logo1 from "assets/logo_observatorio1.png";
import LogoSocioambiental from "assets/logo_socioambiental.png";
import LogoBvRio from "assets/logo_bvRio.png";
import LogoAmigosTerra from "assets/logo_amigosTerra1.jpg";
import LogoCSR from "assets/logo_csr.png";

const list = [
  {
    title: i18n.t("anInitiativeOf"),
    partners: [
      {
        name: "Logo observatorio",
        image: Logo1,
      },
    ],
  },
  {
    title: i18n.t("inColaborationWitch"),
    partners: [
      {
        name: "Logo Ipam",
        image: LogoIpam,
      },
      {
        name: "Logo CSR",
        image: LogoCSR,
      },
      {
        name: "Logo Icv",
        image: LogoIcv,
      },
      {
        name: "Logo Imaflora",
        image: LogoImaflora,
      },
      {
        name: "Logo Socioambiental",
        image: LogoSocioambiental,
      },
      {
        name: "Logo BvRio",
        image: LogoBvRio,
      },
      {
        name: "Logo AmigosTerra",
        image: LogoAmigosTerra,
      },
    ],
  },
  {
    title: i18n.t("developedBY"),
    partners: [
      {
        name: "Logo Ipam",
        image: LogoIpam,
      },
    ],
  },
  {
    title: i18n.t("financedBY"),
    partners: [
      {
        name: "Logo Norad",
        image: Logo4,
      },
    ],
  },
];

const BarraFinal = () => (
  <div className="tcf-partners tcf-container">
    <div className="tcf-row">
      {list.map((item, idx) => (
        <div className="tcf-col tcf-partners-box" key={idx}>
          <h4 className="tcf-partners-box-title">{item.title}</h4>
          <div className="tcf-partners__lists">
            {item.partners.map((partner, index) => (
              <div className="tcf-partners__list" key={index}>
                <img
                  src={partner.image}
                  alt={partner.name}
                  className="tcf-partners__list-thumbnail"
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default withTranslation()(BarraFinal);
