import {
  TRANSPARENCIA_REQUEST,
  TRANSPARENCIA_SUCCESS,
  TRANSPARENCIA_FAIL,
} from "../constants/transparenciaConstants";

const analiseReducer = (state = {}, action) => {
  switch (action.type) {
    case TRANSPARENCIA_REQUEST:
      return { loading: true, transparenciaInner: {} };
    case TRANSPARENCIA_SUCCESS:
      return { loading: false, transparenciaInner: action.payload };
    case TRANSPARENCIA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export default analiseReducer;
