import i18n from "i18next";
import api, { getLanguage } from "service/axios.service";
import * as mapActions from "./mapActions";
import { layer } from "./types";

// retorna os layers que vão no menu do mapa
export const read = () => async (dispatch) => {
  dispatch({ type: layer.LAYERS_READ_REQUEST });
  try {
    const res = await api.get(`/v1/layers/${getLanguage()}`);
    const { data } = res || {};
    dispatch({
      type: layer.LAYERS_READ_SUCCESS,
      payload: data,
    });

    dispatch(mapActions.renderLayersActivaded());
    return data;
  } catch (error) {
    const { response } = error || {};
    const { data } = response || {};
    const { message } = data || {};

    const msg = message || i18n.t("unknownError");

    dispatch({
      type: layer.LAYERS_READ_FAIL,
      payload: msg,
    });
  }
};

// retorna o geojson dos estados
export const readStatesGeojson = () => async (dispatch) => {
  dispatch({ type: layer.STATES_GEOJSON_READ_REQUEST });
  try {
    const res = await api.get("/v1/states/geojson");
    const { data } = res || {};
    dispatch({
      type: layer.STATES_GEOJSON_READ_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    const { response } = error || {};
    const { data } = response || {};
    const { message } = data || {};

    const msg = message || i18n.t("unknownError");

    dispatch({
      type: layer.STATES_GEOJSON_READ_FAIL,
      payload: msg,
    });
  }
};

export const redrawListMenu = (indexGroup, item, listAll) => (dispatch) => {
  dispatch({
    type: layer.LAYERS_REDRAW_LIST_MENU,
    payload: { indexGroup, item, listAll },
  });
};
