import * as React from "react";
import Modal from "components/Modal";
import i18n from "i18next";
import { useSelector } from "react-redux";
import { HTMLRenderer, Button } from "components";

export default function LearnMore() {
  const [open, setOpen] = React.useState(false);
  const handleShowReport = () => setOpen(true);
  const { sobreInner } = useSelector((state) => state.sobre);
  const learnMore = sobreInner.filter(
    (item) => item.name_section === "LearnMore"
  );

  return (
    <div>
      <Button
        href="#"
        onClick={handleShowReport}
        variant="link-light"
        size="sm"
      >
        {i18n.t("learnMore")}
      </Button>
      <br />
      <br />
      <Modal isOpen={open} setIsOpen={setOpen} useBtnEnd={false}>
        <HTMLRenderer
          content={learnMore.length !== 0 ? learnMore[0].text_topic : null}
        />
      </Modal>
    </div>
  );
}
