import api from "service/axios.service";
import {
  STATES_FAIL,
  STATES_REQUEST,
  STATES_SUCCESS,
} from "../constants/statesConstants";
import { sortArrayOfObject } from "helpers/utils";
import { selectUfGeocode } from "helpers/ufs";

export const listStates = async () => async (dispatch, getState) => {
  const { statesInner } = getState().states;

  dispatch({ type: STATES_REQUEST });
  try {
    if (statesInner && statesInner.length > 0) {
      dispatch({
        type: STATES_SUCCESS,
        payload: statesInner,
      });
    } else {
      const result = await api.get("/v1/regions");
      const states = await result.data.flatMap((item) => item.states);
      const list = await states.flatMap((item) => ({
        name: item.name,
        id: item.id,
        uf: selectUfGeocode.find((state) => state.value === item.id)?.label,
        type: "state",
      }));

      list.sort(sortArrayOfObject("name"));

      dispatch({
        type: STATES_SUCCESS,
        payload: list,
      });

      return list;
    }
  } catch (error) {
    dispatch({
      type: STATES_FAIL,
      payload: error.response?.data.message
        ? error.message.data.message
        : error.message,
    });
  }
};
