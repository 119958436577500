/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
// import Info from "components/Info";
import ReportSizeFilter from "components/ReportSizeFilter";

const TeritorialCut = ({ item }) => {
  const { title, component } = item;
  return (
    <>
      {item && (
        <div
          className={`tcf-map-report--body-territorialcut-item${
            item.col === "full"
              ? " tcf-map-report--body-territorialcut-item--third"
              : item.col === "fullplus"
                ? " tcf-map-report--body-territorialcut-item--third--plus"
                : ""
          }`}
        >
          <div className="tcf-map-report--body-territorialcut-item--title">
            {title}
            {/* {item && <Info item={item} />}{" "} */}

            {item.hasFilter && <ReportSizeFilter />}
          </div>

          <div className="tcf-map-report--body-territorialcut-item--body">
            {component}
          </div>
        </div>
      )}
    </>
  );
};

export default TeritorialCut;
