import React, { useState } from "react";
import styled from "styled-components";
import { direction, alignment, color } from "styles";
import ImageIcon from "assets/nextIcon.svg";

import DropdownAbout from "./DropdownAbout";
import i18n from "i18next";

import ModalLearnMore from "./ModalLearnMore";

const Dropdown = ({ territory, topic }) => {
  const [open, setOpen] = useState(false);

  return (
    <Wrapper>
      <Menu onClick={() => setOpen(!open)}>
        <Image src={ImageIcon} {...{ open }} />
        <h3>{territory?.caption}</h3>
      </Menu>
      {open && (
        <>
          {territory.value === "traditional_territories" ? (
            <Container>
              <p>{i18n.t("msg_traditional_territories")}</p>
              <ModalLearnMore />
              fsagdhbfasdjhkfjDAHJ
            </Container>
          ) : null}
          <BodyContainer>
            {topic.map(
              (item, idx) =>
                territory.value === item.cod_territory && (
                  <DropdownAbout {...{ item }} key={idx} />
                )
            )}
          </BodyContainer>
        </>
      )}
    </Wrapper>
  );
};

const Image = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 20px;
  ${({ open }) => open && `transform: rotate(90deg)`};
`;
const BodyContainer = styled.div`
  width: 100%;
`;

const Container = styled.div`
  ${direction("column")};
  ${alignment({ main: "flex-start", cross: "flex-start" })};
  width: 100%;

  p {
    color: #083739;
    font-size: 1rem;
    text-align: justify;
    font-family: "Poppins", "Source Serif Pro", sans-serif;
    margin-bottom: 15px;
    padding: 10px 0px 0px 30px;

    a:link {
      text-decoration: revert;
      color: ${color.green3};
      font-weight: bold;
    }

    a:visited {
      text-decoration: revert;
      color: ${color.green3};
      font-weight: bold;
    }
  }

  ul {
    list-style-type: inherit;
    padding: 0px 0px 0px 82px;
    margin-bottom: 0;
  }

  li {
    color: ${color.green3};
    font-size: 1rem;
    text-align: justify;
    font-family: "Poppins", "Source Serif Pro", sans-serif;

    a:link {
      text-decoration: revert;
      color: ${color.green3};
      font-weight: bold;
    }

    a:visited {
      text-decoration: revert;
      color: ${color.green3};
      font-weight: bold;
    }
  }

  ol {
    list-style-type: decimal;
    padding: 0px 0px 0px 72px;
  }
`;

const Wrapper = styled.div`
  margin-bottom: 10px;
`;

const Menu = styled.div`
  width: 100%;
  height: 40px;
  cursor: pointer;
  ${direction};
  ${alignment({ main: "flex-start" })};
  padding-left: 21px;
  &:hover {
    background: ${color.yellow1};
    cursor: pointer;
  }
  h3 {
    color: ${color.green3};
    font-family: "Poppins", "Source Serif Pro", serif;
    font-size: 1.2rem;
  }
`;

/* const Body = styled.div`
  width: 100%;
  background: ${color.white};
`;

const SaibaMaisContainer = styled.button`
  background: transparent;
  color: #1c6e74;
  padding: 4px 10px;
  // margin-right: 32px;
  border-radius: 5px;
  font-weight: 400;
  display: flex;
  align-items: flex-end;
`; */

export default Dropdown;
