import React from "react";
import BarraFinal from "./Partners";
import Contato from "./Contato";

function Footer() {
  return (
    <>
      <Contato />
      <BarraFinal />
    </>
  );
}

export default Footer;
