import styled from "styled-components";
import { direction, alignment, color } from "styles";

const HeaderBox = styled.div`
  width: 100%;
  height: 100%;
  ${direction};
  ${alignment({ main: "space-between" })};
`;

const Body = styled.div`
  margin-top: 21px;
  font-size: 15px;
`;

const Title = styled.p`
  margin-left: 21px;
  font-size: 16px;
`;

const Header = styled.div`
  height: 60px;
  width: 100%;
  border-bottom: 1px solid ${color.gray};
  cursor: pointer;
  ${direction};
  ${alignment({ main: "flex-start" })};
`;

const P = styled.p`
  font-size: 26px;
`;
export { HeaderBox, Header, Body, Title, P };
