/* eslint-disable prettier/prettier */
import { Button } from "components";
import i18n from "i18next";
import React from "react";
import * as mapActions from "store/actions/mapActions";
import { useDispatch, useSelector } from "react-redux";
import setChoice from "store/actions/choiceAction";

const menuList = [
  { name: i18n.t("settlements"), type: "settlements" },
  { name: i18n.t("rural_properties"), type: "rural_properties" },
  { name: i18n.t("traditional_territories"), type: "traditional_territories" },
];

function ResetFilter({ isReport = false }) {
  const layers = useSelector((state) => state.layers);
  const { layers_groups } = layers.data || {};
  const dispatch = useDispatch();
  const choice = useSelector((state) => state.choice);

  const resetHandler = () => {
    dispatch(mapActions.renderLayersActivaded());
  };
  const controlLayer = (checked, item) => {
    if (checked || item.visible)
      dispatch(
        mapActions.toggleLayer({ checked, item, listAll: layers_groups })
      );
  };
  const resetReportBarFilter = () => {
    if (layers_groups) {
      const value = "settlements";

      // pega apenas as camadas de apoio
      const supportLayers = layers_groups?.find((lg) => !lg.main_menu);

      // assentamentos
      const ass = supportLayers.layers_names.find(
        (sl) =>
          sl.layer_link.name_mapfile ===
          "mapbiomas-alertas:dashboard_settlements-static-layer"
      );

      // municípios
      const mun = supportLayers.layers_names.find(
        (sl) => sl.layer_link.name_mapfile === "municipios"
      );

      // biomas
      const biomes = supportLayers.layers_names.find(
        (sl) =>
          sl.layer_link.name_mapfile ===
          "mapbiomas-alertas:dashboard_biomes-static-layer"
      );

      controlLayer(false, ass);
      controlLayer(false, mun);
      controlLayer(false, biomes);

      // se recorte fundiário for assentamento e recorte territorial assentamento
      // ativa a camada assentamento
      if (value === "settlements") {
        controlLayer(true, ass);
      }

      // se recorte fundiário for imóveis rurais e recorte territorial imóveis rurais
      // ou recorte fundiário for territórios tradicionais e recorte territorial territórios tradicionais
      // ativa a camada municípios
      if (value === "rural_properties" || value === "traditional_territories") {
        controlLayer(true, mun);
      }

      // se recorte territorial for bioma, ativa a camada
      if (value === "biome") {
        controlLayer(true, biomes);
      }
      const newChoice = {
        ...choice,
        territory: null,
        territorialCut: {
          label: i18n.t("brazil").toUpperCase(),
          value: "brazil",
        },
        active: 0,
        ...menuList[0],
      };
      dispatch(setChoice(newChoice));
    }
  };

  return (
    <div className="tcf-sidebar__territory--selector--reset-filter">
      <h2 className="tcf-sidebar__territory-selector-title">
        {isReport ? i18n.t("filtersButton") : i18n.t("layersButton")}
      </h2>
      <Button
        size="sm"
        variant="outline-primary"
        onClick={isReport ? resetReportBarFilter : resetHandler}
      >
        {i18n.t("resetButton")}
      </Button>
    </div>
  );
}

export default ResetFilter;
