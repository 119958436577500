import React from "react";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Languages from "../Languages";
import "../Languages/i18n";
import { Button } from "components";

const items = [
  { anchor: true, name: i18n.t("about"), id: "about", key: "about" },
  { anchor: true, name: i18n.t("articles"), id: "articles", key: "articles" },
  { anchor: true, name: i18n.t("code"), id: "ncode", key: "code" },
  { anchor: true, name: i18n.t("tools"), id: "tools", key: "tools" },
  {
    anchor: true,
    name: i18n.t("understand"),
    id: "understand",
    key: "understand",
  },
  {
    anchor: false,
    name: i18n.t("methodology"),
    id: "methodology",
    key: "methodology",
    href: "/metodologia",
  },
  { anchor: true, name: i18n.t("contact"), id: "contact", key: "contact" },
];

const scrollTo = (event, target, anchor) => {
  // event.preventDefault();
  if (anchor) {
    const top =
      document.getElementById(target).getBoundingClientRect().top +
      (window.scrollY || window.pageYOffset) -
      100;
    window.scrollTo({
      top: top, // Offset of -100 pixels
      /* top: target - 100,  */ // Offset of -100 pixels
      behavior: "smooth",
    });
  }

  // return false;
};

function DropDownMenu() {
  return (
    <ul className="tcf-drop-items">
      <li className="tcf-nav-lang">
        <Languages
          vertical={false}
          styleContainer={{ position: "initial", top: 0, right: 0.5 }}
        />
      </li>
      <li className="tcf-nav-item">
        <Link to="/plataforma">
          <Button variant="outline-primary" size="md">
            {i18n.t("platform")}
          </Button>
        </Link>
      </li>
      {items.map((item, idx) => (
        <li className="tcf-nav-item" key={idx}>
          <Button
            href={item.anchor ? "/#scrool" : item.href}
            variant="link-dark"
            size="md"
            key={idx}
            onClick={(event) => scrollTo(event, item.id, item.anchor)}
          >
            {item.name}
          </Button>
        </li>
      ))}
    </ul>
  );
}

export default withTranslation()(DropDownMenu);
