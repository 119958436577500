import React, { useState } from "react";
import Popover from "components/Popover";
import { useMapStore } from "service/map.context";
import ControlButton from "../ControlButton";
import { zoomInOutMap } from "../../controlMap";

function ControlInLegendIndicator() {
  const { openLegend, setOpenLegend } = useMapStore();
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal((prev) => !prev);
  };
  return (
    <div className="tcf-map--control__btn--group">
      <ControlButton
        title="123"
        active={showModal}
        Action={openModal}
        type="Indicator"
        directAction
      />
      <Popover
        showModal={showModal}
        setShowModal={setShowModal}
        variation="primary"
        content={
          <>
            <span>Dica:</span> Visualize legendas ou indicadores
          </>
        }
      />
      <ControlButton
        title="Legenda"
        Action={() => setOpenLegend(!openLegend)}
        type="Legend"
        active={openLegend}
        directAction
      />
    </div>
  );
}

export default ControlInLegendIndicator;
