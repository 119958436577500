import React, { useRef, useState, useEffect } from "react";

function InputRange({ min, max, step, value, onChange }) {
  const [sliderValue, setSliderValue] = useState(value);
  const container = useRef();

  const handleChange = (e) => {
    const newValue = parseFloat(e.target.value);
    setSliderValue(newValue);
    onChange(newValue);
  };
  useEffect(() => {
    if (container && container.current) {
      const sliderEl = container.current;

      sliderEl.addEventListener("input", (event) => {
        const tempSliderValue = event.target.value;
        const progress = (tempSliderValue / max) * max;
        sliderEl.style.background = `linear-gradient(to right, #78c753 ${progress}%, #dedbd8 ${progress}%)`;
      });
    }
  }, [container]);

  return (
    <input
      type="range"
      className="tcf-form-range"
      ref={container}
      min={min}
      max={max}
      step={step}
      value={sliderValue}
      onChange={handleChange}
    />
  );
}

export default InputRange;
