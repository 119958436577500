import React from "react";
import StatisticsButton from "../StatisticsButton";
import ReportButton from "../ReportButton";

function Actions() {
  return (
    <div className="tcf-sidebar__header--actions">
      <ReportButton />
      <StatisticsButton />
    </div>
  );
}

export default Actions;
