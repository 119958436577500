import React from "react";

const Totem = ({ children, src, title, bgColor }) => (
  <div className="tcf-box-tools">
    <div className="tcf-box-tools__title">
      <img src={src} alt="image_ferramentas" />
      <h3>{title}</h3>
    </div>
    <div className="tcf-box-tools__description">
      <p>{children}</p>
    </div>
  </div>
);

/* const Title = styled.h4``;

const Desc = styled.div`
  width: 50%;
  height: 100%;

  p {
    color: ${color.green3};
    font-size: 1.2rem;
    text-align: justify;
    margin-bottom: 10px;
    font-family: "Poppins", "Source Serif Pro", sans-serif;
  }

  @media (min-width: 720px) and (max-width: 1000px) {
    p {
      font-size: 18px;
    }
  }
  @media (max-width: 720px) {
    p {
      font-size: 14px;
    }
  }
`;

const Box = styled.div`
  width: 120px;
  height: 120px;
  background: ${color.green};
  ${direction("column")};
  ${alignment};
  margin-right: 32px;

  img {
    height: 80px;
    width: 80px;
  }
`; */

export default Totem;
