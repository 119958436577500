import { quilombosBrasil } from "../actions/types";

const initialState = {
  loading: false,
  quilombos: [],
  type: null,
};

const quilombosBrasilReducer = (state = initialState, action) => {
  switch (action.type) {
    // Filtro de quilombos
    case quilombosBrasil.QUILOMBOS_BRASIL_REQUEST:
      return { ...state, loading: true, message: "", type: null };
    case quilombosBrasil.QUILOMBOS_BRASIL_SUCCESS:
      return {
        ...state,
        loading: false,
        quilombos: action.payload,
        type: "success",
      };
    case quilombosBrasil.QUILOMBOS_BRASIL_FAIL:
      return {
        ...state,
        loading: false,
        quilombos: action.payload,
        type: "error",
      };
    default:
      return state;
  }
};

export default quilombosBrasilReducer;
