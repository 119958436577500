import i18n from "i18next";
import { Fill, Stroke, Style } from "ol/style";

/*
 * AÇẼOS PARA IMPLEMENTAÇÃO DA LEI
 * - CAPACIDADE INSTITUCIONAL
 * - AÇÕES PARA INSCRIÇÃO
 * - ANÁLISE DO CAR
 * - IMPLEMENTAÇÃO DO PRA
 */
export const actionsImplementationLawStyle = (feature) => {
  const styles = {
    attend: {
      style: new Style({
        fill: new Fill({
          color: "#7DA13C",
        }),
        stroke: new Stroke({
          color: "#6b6868",
          width: 1,
        }),
      }),
      label: i18n.t("atc").toUpperCase(), // Atende todos os critérios
    },
    "attend-partial": {
      style: new Style({
        fill: new Fill({
          color: "#FFD600",
        }),
        stroke: new Stroke({
          color: "#6b6868",
          width: 1,
        }),
      }),
      label: i18n.t("apc").toUpperCase(), // Atende parte dos critérios
    },
    "does-not-attend": {
      style: new Style({
        fill: new Fill({
          color: "#EA6212",
        }),
        stroke: new Stroke({
          color: "#6b6868",
          width: 1,
        }),
      }),
      label: i18n.t("nca").toUpperCase(), // Nenhum critério atendido
    },
  };

  if (!feature) return styles;

  if (feature.get("criterias") === "attend") {
    return styles.attend.style;
  }
  if (feature.get("criterias") === "attend-partial") {
    return styles["attend-partial"].style;
  }
  return styles["does-not-attend"].style;
};

/*
 * ANÁLISE E VALIDAÇÃO
 * - NÚMERO DE CAR ANALISADO
 * - NÚMERO DE CAR VALIDADOS
 * - NÚMERO DE TERMO DE COMPROMISSO FIRMADO
 */
export const analyzeAndValidationStyle = (feature) => {
  const styles = {
    ">50000": {
      style: new Style({
        fill: new Fill({
          color: "#7DA13C",
        }),
        stroke: new Stroke({
          color: "#cccccc",
          width: 1,
        }),
      }),
      label: "> 50.000 CAR",
    },
    ">10000": {
      style: new Style({
        fill: new Fill({
          color: "#B5BC3C",
        }),
        stroke: new Stroke({
          color: "#cccccc",
          width: 1,
        }),
      }),
      label: "> 10.000 CAR",
    },
    ">1000": {
      style: new Style({
        fill: new Fill({
          color: "#FFD600",
        }),
        stroke: new Stroke({
          color: "#cccccc",
          width: 1,
        }),
      }),
      label: "> 1.000 CAR",
    },
    ">0": {
      style: new Style({
        fill: new Fill({
          color: "#FF9E09",
        }),
        stroke: new Stroke({
          color: "#cccccc",
          width: 1,
        }),
      }),
      label: "> 0 CAR",
    },
    "=0": {
      style: new Style({
        fill: new Fill({
          color: "#EA6212",
        }),
        stroke: new Stroke({
          color: "#cccccc",
          width: 1,
        }),
      }),
      label: "0 CAR",
    },
    default: {
      style: new Style({
        fill: new Fill({
          color: "#939393",
        }),
        stroke: new Stroke({
          color: "#cccccc",
          width: 1,
        }),
      }),
      label: i18n.t("no_information").toUpperCase(),
    },
  };

  if (!feature) return styles;

  if (feature.get("analyze") > 50000) {
    return styles[">50000"].style;
  }
  if (feature.get("analyze") > 10000) {
    return styles[">10000"].style;
  }
  if (feature.get("analyze") > 1000) {
    return styles[">1000"].style;
  }
  if (feature.get("analyze") > 0) {
    return styles[">0"].style;
  }
  if (feature.get("analyze") === 0) {
    return styles["=0"].style;
  }
  return styles.default.style;
};

/*
 * TRANSPARÊNCIA
 * - ATIVA
 * - PASSIVA
 */
export const transparencyStyle = (feature) => {
  const styles = {
    "0a25": {
      style: new Style({
        fill: new Fill({
          color: "#EA6212",
        }),
        stroke: new Stroke({
          color: "#cccccc",
          width: 1,
        }),
      }),
      label: `0 ${i18n.t("to")} 25`,
    },
    "25a50": {
      style: new Style({
        fill: new Fill({
          color: "#FFD600",
        }),
        stroke: new Stroke({
          color: "#cccccc",
          width: 1,
        }),
      }),
      label: `25 ${i18n.t("to")} 50`,
    },
    "50a75": {
      style: new Style({
        fill: new Fill({
          color: "#B5BC3C",
        }),
        stroke: new Stroke({
          color: "#cccccc",
          width: 1,
        }),
      }),
      label: `50 ${i18n.t("to")} 75`,
    },
    "75a100": {
      style: new Style({
        fill: new Fill({
          color: "#7DA13C",
        }),
        stroke: new Stroke({
          color: "#cccccc",
          width: 1,
        }),
      }),
      label: `75 ${i18n.t("to")} 100`,
    },
    default: {
      style: new Style({
        fill: new Fill({
          color: "#939393",
        }),
        stroke: new Stroke({
          color: "#cccccc",
          width: 1,
        }),
      }),
      label: i18n.t("no_information").toUpperCase(),
    },
  };

  if (!feature) return styles;

  if (feature.get("transparency") > 75) {
    return styles["75a100"].style;
  }
  if (feature.get("transparency") > 50) {
    return styles["50a75"].style;
  }
  if (feature.get("transparency") > 25) {
    return styles["25a50"].style;
  }
  if (feature.get("transparency") >= 0) {
    return styles["0a25"].style;
  }
  return styles.default.style;
};

/*
 * ZEE
 */
export const zeeStyle = (feature) => {
  const styles = {
    1: {
      style: new Style({
        fill: new Fill({
          color: "#7DA13C",
        }),
        stroke: new Stroke({
          color: "#cccccc",
          width: 1,
        }),
      }),
      label: i18n.t("validatedStateZee").toUpperCase(), // ZEE DO ESTADO VALIDADO,
    },
    2: {
      style: new Style({
        fill: new Fill({
          color: "#B5BC3C",
        }),
        stroke: new Stroke({
          color: "#cccccc",
          width: 1,
        }),
      }),
      label: i18n.t("validatedZones").toUpperCase(), // ZONAS VALIDADAS,
    },
    3: {
      style: new Style({
        fill: new Fill({
          color: "#EA6212",
        }),
        stroke: new Stroke({
          color: "#cccccc",
          width: 1,
        }),
      }),
      label: i18n.t("withoutZeeOrNotValidate").toUpperCase(), // SEM ZEE OU NÃO VALIDADO,
    },
    default: {
      style: new Style({
        fill: new Fill({
          color: "#939393",
        }),
        stroke: new Stroke({
          color: "#cccccc",
          width: 1,
        }),
      }),
      label: i18n.t("no_information").toUpperCase(),
    },
  };

  if (!feature) return styles;

  if (feature.get("zee") === 1 || feature.get("zee") === 5) {
    return styles["1"].style;
  }
  if (feature.get("zee") === 2 || feature.get("zee") === 6) {
    return styles["2"].style;
  }
  if (feature.get("zee") === 3 || feature.get("zee") === 7) {
    return styles["3"].style;
  }
  return styles.default.style;
};
