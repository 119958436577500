import api from "service/axios.service";
import { typesSuppression } from "./types";

export const fetchSuppression = (choice) => async (dispatch) => {
  const { territorialCut, territory } = choice || {};
  const { value: valueCut } = territorialCut || {};
  const { label, value } = territory || {};
  try {
    dispatch({ type: typesSuppression.FETCH_SUPPRESSION_REQUEST });

    const newValueCut = [
      "rural_properties",
      "traditional_territories",
    ].includes(valueCut)
      ? "municipality"
      : valueCut;

    const res = await api.post(`/v2/supressaoTerritory/${newValueCut}/`, {
      codTerritory: valueCut === "biome" ? label : value,
    });

    const { data } = res || {};
    const [values] = data || [];

    dispatch({
      type: typesSuppression.FETCH_SUPPRESSION_SUCCESS,
      payload: values || {},
    });
  } catch ({ response }) {
    const { message } = response.data;
    const errorMessage = message || "Erro desconhecido";

    dispatch({
      type: typesSuppression.FETCH_SUPPRESSION_FAILURE,
      payload: errorMessage,
    });
  }
};
