import { settlementsBrasil } from "../actions/types";

const initialState = {
  loading: false,
  settlements: [],
  error: false,
};

const settlementsReducer = (state = initialState, action) => {
  switch (action.type) {
    // Filtro de assentamentos
    case settlementsBrasil.SETTLEMENTS_REQUEST:
      return { ...state, loading: true, error: false };
    case settlementsBrasil.SETTLEMENTS_REQUEST_SUCCESS:
      return {
        loading: false,
        settlements: action.payload,
        error: false,
      };
    case settlementsBrasil.SETTLEMENTS_REQUEST_FAIL:
      return {
        loading: false,
        settlements: action.payload,
        error: true,
      };
    default:
      return state;
  }
};

export default settlementsReducer;
