import i18n from "i18next";
import api, { getLanguage } from "service/axios.service";
import { methodology } from "./types";

export const methodologyActions = () => async (dispatch) => {
  dispatch({ type: methodology.METHODOLOGY_REQUEST });
  try {
    const res = await api.get(`/v1/methodology/${getLanguage()}`);
    const { data } = res || {};
    dispatch({
      type: methodology.METHODOLOGY_COMMIT,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: methodology.METHODOLOGY_ROLLBACK,
      payload: { message: i18n.t("errorSendMessage"), type: "error" },
    });
  }
};
