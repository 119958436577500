import {
  STATES_FAIL,
  STATES_REQUEST,
  STATES_SUCCESS,
} from "../constants/statesConstants";

const statesReducer = (state = { statesInner: [] }, action) => {
  switch (action.type) {
    case STATES_REQUEST:
      return { loading: true, statesInner: [] };
    case STATES_SUCCESS:
      return { loading: false, statesInner: action.payload };
    case STATES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export default statesReducer;
