/* import Observatorio from "./Observatorio"; */
import SobreOTermometro from "./SobreOTermometro";
import EntendaOsDados from "./EntendaOsDados/EntendaOsDados";
import Ferramentas from "./Ferramentas";

const Landingpage = {
  /* Observatorio, */
  SobreOTermometro,
  EntendaOsDados,
  Ferramentas,
};

export default Landingpage;
