import api, { getLanguage } from "service/axios.service";
import { ZEE_FAIL, ZEE_REQUEST, ZEE_SUCCESS } from "../constants/zeeConstants";

export const zeeData = () => async (dispatch, getState) => {
  const { zeeInner } = getState().zee;

  if (zeeInner) {
    return false;
  }

  dispatch({ type: ZEE_REQUEST });
  try {
    const { data } = await api.get(`/v1/zee/${getLanguage()}`);
    const { zee } = data || {};
    const [arrayZee] = zee || [];
    const { zee_regulation } = arrayZee || {};
    const zeeInner = zee_regulation || [];

    dispatch({
      type: ZEE_SUCCESS,
      payload: zeeInner,
    });
    return zeeInner;
  } catch (error) {
    dispatch({
      type: ZEE_FAIL,
      payload: error.response?.data.message
        ? error.message.data.message
        : error.message,
    });
  }
};
