import React, { useState } from "react";
import { Form, Button, Blog, Accordion } from "components";

function TesteEstilo() {
  // const [selectedOption, setSelectedOption] = useState(null);
  const min = 0;
  const max = 100;
  const step = 1;
  const [sliderValue, setSliderValue] = useState(0);
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
    { value: "nini", label: "nini" },
    { value: "Milk", label: "Milk" },
    { value: "Mocka", label: "Mocka" },
    { value: "Coca", label: "Coca" },
    { value: "Chack", label: "Chack" },
  ];
  const accordionData = [
    {
      title: "Section 1",
      content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
      laborum cupiditate possimus labore, hic temporibus velit dicta earum
      suscipit commodi eum enim atque at? Et perspiciatis dolore iure
      voluptatem.`,
    },
    {
      title: "Section 2",
      content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
      reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
      quaerat iure quos dolorum accusantium ducimus in illum vero commodi
      pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
      quidem maiores doloremque est numquam praesentium eos voluptatem amet!
      Repudiandae, mollitia id reprehenderit a ab odit!`,
    },
    {
      title: "Section 3",
      content: `Sapiente expedita hic obcaecati, laboriosam similique omnis architecto ducimus magnam accusantium corrupti
      quam sint dolore pariatur perspiciatis, necessitatibus rem vel dignissimos
      dolor ut sequi minus iste? Quas?`,
    },
  ];
  const [newsletter, setNewsletter] = useState(false);
  const [isToggled, setIsToggled] = useState(false);

  const onNewsletterChange = (checked) => {
    setNewsletter(checked);
  };
  const [selectedOption, setSelectedOption] = useState();

  const [selectedOptions, setSelectedOptions] = useState([]);
  const placeholder = "Selecione...";

  const handleSelectChange = (newSelectedOptions) => {
    setSelectedOption(newSelectedOptions);
  };

  const handleSliderChange = (newValue) => {
    setSliderValue(newValue);
  };

  const handleSwitch = (checked) => {
    setIsToggled(checked);
  };

  return (
    <div style={{ background: "#F6F5F4", padding: `20px 0` }}>
      <div className="container-lg">
        <div className="row">
          <div className="col-12 ml-1">
            <h2>ButtonPrimary</h2>
            <p>
              Botão de destaque, pode ter largura adaptada ao componente de
              destino, não pode ser utilizado mais de uma vez na mesma seção ou
              página. Pode receber IndicatorCircle(Dark)
            </p>
            <Button type="button" variant="primary" size="md">
              ButtonText
            </Button>{" "}
            <Button type="button" variant="primary" size="sm">
              ButtonText
            </Button>
          </div>
          <div className="col-12">
            <h2>ButtonPrimary</h2>
            <p>
              Botão de destaque, pode ter largura adaptada ao componente de
              destino, não pode ser utilizado mais de uma vez na mesma seção ou
              página. Pode receber IndicatorCircle(Dark)
            </p>
            <div className="row">
              <div className="col-6">
                <Button type="button" variant="icon" size="md">
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5 10V12.6667C14.5 13.0203 14.3595 13.3594 14.1095 13.6095C13.8594 13.8595 13.5203 14 13.1667 14H3.83333C3.47971 14 3.14057 13.8595 2.89052 13.6095C2.64048 13.3594 2.5 13.0203 2.5 12.6667V10"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.1665 6.66669L8.49984 10L11.8332 6.66669"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.5 10V2"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
              </div>
              <div className="col-6">
                <Button variant="icon" size="sm">
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.5 10V12.6667C14.5 13.0203 14.3595 13.3594 14.1095 13.6095C13.8594 13.8595 13.5203 14 13.1667 14H3.83333C3.47971 14 3.14057 13.8595 2.89052 13.6095C2.64048 13.3594 2.5 13.0203 2.5 12.6667V10"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.1665 6.66669L8.49984 10L11.8332 6.66669"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.5 10V2"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          </div>
          <div className="col-12">
            <h2>ButtonOutline</h2>
            <p>
              Botão secundário, pode ter largura adaptada ao componente de
              destino, pode ser utilizado mais de uma vez na mesma seção ou
              página. Pode receber IndicatorCircle(Light)
            </p>
            <Button variant="outline-primary" size="md">
              vvgg
            </Button>
            <Button variant="outline-primary" size="sm">
              vvgg
            </Button>{" "}
          </div>
          <div className="col-12">
            <h2>ButtonLink</h2>

            <Button variant="link-light" size="md">
              ButtonLink
            </Button>
          </div>
          <div className="col-12">
            <h2>InputText</h2>
            <p>Pode ter largura adaptada ao componente de destino.</p>

            <div>
              <Form.Control
                type="text"
                className="tcf-input"
                placeholder="placeholder"
              />
              <Form.Control
                type="text"
                className="tcf-input"
                value="hgsdhgsdhg"
                disabled
                placeholder="placeholder"
              />
            </div>
            <div>
              <Form.Label htmlFor="inputPassword5">Password</Form.Label>
              <Form.Control
                type="password"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
              />
              <Form.Text id="passwordHelpBlock" muted>
                Your password must be 8-20 characters long, contain letters and
                numbers, and must not contain spaces, special characters, or
                emoji.
              </Form.Text>
            </div>
          </div>
          <div className="col-12">
            <h2>InputSelect</h2>
            <p>Pode ter largura adaptada ao componente de destino.</p>

            <Form.Select
              placeholder={placeholder}
              onChange={handleSelectChange}
              options={options}
              defaultValue={selectedOption}
            />
            <Form.Select
              placeholder={placeholder}
              onChange={handleSelectChange}
              options={options}
              defaultValue={selectedOption}
              disabled
            />
          </div>
          <div className="col-12">
            <h2>InputTextarea</h2>
            <p>Pode ter largura adaptada ao componente de destino.</p>
            <div>
              <Form.Textarea
                className="tcf-form-control"
                name=""
                id=""
                placeholder="placeholder"
              />
              <Form.Textarea
                disabled
                className="tcf-form-control"
                defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
                name=""
                id=""
                placeholder="placeholder"
              />
            </div>
          </div>
          <div className="col-12">
            <h2>InputSlider</h2>
            <p>InputSlider</p>
            <div>
              <Form.Range
                min={min}
                max={max}
                step={step}
                value={sliderValue}
                onChange={handleSliderChange}
              />
              <div className="slider-value">{sliderValue}</div>
            </div>
          </div>
          <div className="col-12">
            <h2>InputSwitch</h2>
            <p>InputSwitch</p>
            <div>
              <Form.Check
                type="switch"
                checked={isToggled}
                onChange={handleSwitch}
              />
            </div>
          </div>
          <div className="col-12">
            <h2>Accordion</h2>
            <p>Accordion</p>
            <div>
              {accordionData.map(({ title, content }) => (
                <Accordion title={title} content={content} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Blog />
    </div>
  );
}

export default TesteEstilo;
