export const selectUfGeocode = [
  { value: 12, label: "AC" },
  { value: 27, label: "AL" },
  { value: 13, label: "AM" },
  { value: 16, label: "AP" },
  { value: 29, label: "BA" },
  { value: 23, label: "CE" },
  { value: 53, label: "DF" },
  { value: 32, label: "ES" },
  { value: 52, label: "GO" },
  { value: 21, label: "MA" },
  { value: 31, label: "MG" },
  { value: 50, label: "MS" },
  { value: 51, label: "MT" },
  { value: 15, label: "PA" },
  { value: 25, label: "PB" },
  { value: 26, label: "PE" },
  { value: 22, label: "PI" },
  { value: 41, label: "PR" },
  { value: 33, label: "RJ" },
  { value: 24, label: "RN" },
  { value: 11, label: "RO" },
  { value: 14, label: "RR" },
  { value: 43, label: "RS" },
  { value: 42, label: "SC" },
  { value: 28, label: "SE" },
  { value: 35, label: "SP" },
  { value: 17, label: "TO" },
];
