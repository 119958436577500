/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable import/extensions */
import React, { useState } from "react";
import HighchartsReact from "highcharts-react-official";
import i18n from "i18next";
import Highcharts from "highcharts/highstock";
import variablePie from "highcharts/modules/variable-pie.js";
import PropTypes from "prop-types";
import { color } from "styles";
import { ResponsiveBar } from "@nivo/bar";

variablePie(Highcharts);

function Donut({ data, colors }) {
  const options = {
    chart: {
      type: "column",
      height: "300px",
      backgroundColor: color.white,
    },
    colors,
    title: { text: null },
    xAxis: {
      categories: ["Arsenal", "Chelsea", "Liverpool", "Manchester United"],
    },
    yAxis: {
      min: 0,
      title: {
        text: "Count trophies",
      },
      stackLabels: {
        enabled: true,
      },
    },
    credits: { enabled: false },
    tooltip: {
      style: {
        color: "#505050",
        fontSize: "13px",
      },
      formatter() {
        return `${this.point.name}
          :
          ${Highcharts.numberFormat(Math.abs(this.y), 0, ",", ".")}
          ha
          (${Highcharts.numberFormat(this.percentage, 1)}%)`;
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
        },
      },
      series: {
        states: {
          hover: {
            enabled: true,
            halo: { size: 0 },
          },
        },
        dataLabels: { enabled: false },
      },
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        minPointSize: 10,
        innerSize: "70%",
        size: 200,
        zMin: 0,
        data,
      },
    ],
  };
  /*  const options = {
    chart: {
      type: "column",
      height: "300px",
      backgroundColor: color.white,
    },
    colors,
    title: { text: null },
    credits: { enabled: false },
    tooltip: {
      style: {
        color: "#505050",
        fontSize: "13px",
      },
      formatter: function () {
        return `${this.point.name}
          :
          ${Highcharts.numberFormat(Math.abs(this.y), 0, ",", ".")}
          ha
          (${Highcharts.numberFormat(this.percentage, 1)}%)`;
      },
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: true,
            halo: { size: 0 },
          },
        },
        dataLabels: { enabled: false },
      },
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        minPointSize: 10,
        innerSize: "70%",
        size: 200,
        zMin: 0,
        data: data,
      },
    ],
  }; */
  const customColors = ["#AC6C29", "#78C753"];
  return (
    <>
      <ResponsiveBar
        data={data}
        keys={[[i18n.t("registeredArea")], [i18n.t("nonRegisteredArea")]]}
        indexBy="size"
        margin={{ top: 50, right: 120, bottom: 50, left: 60 }}
        padding={0.8}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={customColors}
        /* defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "#38bcb2",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "#eed312",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]} */
        /* fill={[
          {
            match: {
              id: "fries",
            },
            id: "dots",
          },
          {
            match: {
              id: "sandwich",
            },
            id: "lines",
          },
        ]} */
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          legendOffset: 32,
          truncateTickAt: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: -30,
          tickRotation: 0,
          legend: i18n.t("areaHa"),
          legendPosition: "middle",
          legendOffset: -40,
          truncateTickAt: 0,
        }}
        enableLabel={false}
        /* labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }} */
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        /* ariaLabel="Nivo bar chart demo"
        barAriaLabel={(e) =>
          `${e.id}: ${e.formattedValue} in country: ${e.indexValue}`
        } */
      />
      {/* <HighchartsReact highcharts={Highcharts} options={options} />; */}
    </>
  );
}

Donut.defaultProps = {
  colors: ["#2ec277", "#6d5494", "tomato", "#2db799", "#0077b4"],
};

Donut.propTypes = {
  data: PropTypes.array.isRequired,
  colors: PropTypes.array.isRequired,
};

export default Donut;
