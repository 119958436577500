import i18n from "i18next";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { zeeData } from "store/actions/zeeActions";
import { color } from "styles";
import { HTMLRenderer } from "components";

const StatusZEE = () => {
  const dispatch = useDispatch();
  const { territory } = useSelector((state) => state.choice);
  const { zeeInner } = useSelector((state) => state.zee);

  const zeeState =
    zeeInner && zeeInner.find((item) => item.geocode === territory?.value);

  const { situation_id } = zeeState || "";
  const renderFillColor = () => {
    let colorTitle = "#424242";
    if (situation_id === 1) {
      colorTitle = color.green;
    } else if (situation_id === 2) {
      colorTitle = color.orange3;
    } else if (situation_id === 3) {
      colorTitle = color.orange1;
    } else if (situation_id === 4) {
      colorTitle = color.gray2;
    }
    return colorTitle;
  };

  useEffect(() => {
    dispatch(zeeData());
  }, [dispatch]);

  return territory?.value ? (
    <ZEEBox
      title={zeeState?.decree}
      desc={zeeState?.text_description}
      colorTitle={renderFillColor()}
    />
  ) : (
    <div style={{ width: "100%" }}>{i18n.t("select_state")}</div>
  );
};

const ZEEBox = ({ title, desc, colorTitle }) => (
  <div>
    <h4 style={{ color: colorTitle }}>{title}</h4>
    <HTMLRenderer content={desc} />
  </div>
);

export default StatusZEE;
