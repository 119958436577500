import { Vector } from "ol/layer";
import Overlay from "ol/Overlay";
import React, { useEffect, useState, useRef } from "react";
import { formatNumber } from "helpers/utils";
import { useMapStore } from "service/map.context";
import { TooltipContainer, TooltipContent } from "./Tooltip.style";

function Tooltip() {
  const { map } = useMapStore();

  const [content, setContent] = useState([]);
  const container = useRef();

  useEffect(() => {
    // cria tooltip
    const tooltip = new Overlay({
      element: container.current,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });
    map?.addOverlay(tooltip);

    // ao passar o mouse no mapa
    map?.on("pointermove", (evt) => {
      if (evt.dragging) return;

      tooltip.setPosition(null);

      const { pixel, coordinate } = evt;
      const feat = map?.forEachFeatureAtPixel(pixel, (feature) => feature);
      const layer = map?.forEachLayerAtPixel(pixel, (layer) => layer);

      if (feat && layer && layer instanceof Vector) {
        const label = layer ? layer.get("label") : "";

        const unity = feat.get("unity") || "";
        const name = feat.get("name");
        const key = feat.get("key"); // key do value
        const val = feat.get(key);
        let value = !Number.isNaN(val) ? formatNumber(val, 0, ".", ",") : val;

        // se for uma feature de "Ações para implementação da lei"
        if (key === "criterias") {
          const styles = layer.getStyle()();
          value = styles[val].label;
        }

        setContent(`<p>${name}</p>${label}<code>: ${value}${unity}</code>`);
        tooltip.setPosition(coordinate);
      }
    });
  }, [map]);

  return (
    <TooltipContainer ref={container} id="tooltip" className="ol-tooltip">
      <TooltipContent
        id="tooltip-content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </TooltipContainer>
  );
}

export default Tooltip;
