import {
  SOBREPOSICOES_REQUEST,
  SOBREPOSICOES_SUCCESS,
  SOBREPOSICOES_FAIL,
} from "../constants/sobreposicoesConstants";

const legalidadeReducer = (state = {}, action) => {
  switch (action.type) {
    case SOBREPOSICOES_REQUEST:
      return { loading: true, sobreposicoesInner: {} };
    case SOBREPOSICOES_SUCCESS:
      return { loading: false, sobreposicoesInner: action.payload };
    case SOBREPOSICOES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export default legalidadeReducer;
