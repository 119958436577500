import * as ol from "ol";
import "ol/ol.css";
import i18n from "i18next";

import { transform } from "ol/proj";
import React, { useEffect, useRef } from "react";
import { useMapStore } from "service/map.context";
import { useReportStore } from "service/report.service";
import Error from "./Error";
import Legend from "./Legend";
import LoadingLayer from "./LoadingLayer";
import Tooltip from "./Tooltip";
import { mapCenter, mapZoom } from "./controlMap";
import Controls from "./Controls";
import Sidebar from "../Sidebar";
import ReportBar from "../ReportBar";
// import { useLayersStore } from "service/layers.service";

// const menuList = [
//   { name: i18n.t("settlements"), type: "settlements" },
//   { name: i18n.t("rural_properties"), type: "rural_properties" },
//   { name: i18n.t("traditional_territories"), type: "traditional_territories" },
// ];

function Map() {
  const mapRef = useRef();
  const { map, setMap } = useMapStore();
  const { showReport } = useReportStore();
  // const { getLayersGroups, setGroups, groups } = useLayersStore();

  useEffect(() => {
    const options = {
      view: new ol.View({
        center: transform(mapCenter, "EPSG:4326", "EPSG:3857"),
        zoom: mapZoom,
        maxZoom: 19,
      }),
      target: null,
      layers: [],
      controls: [],
      overlays: [],
    };

    const tcfMap = new ol.Map(options);
    tcfMap.setTarget(mapRef.current);
    document.getElementById("tcf-map").data = tcfMap;
    setMap(tcfMap);

    return () => tcfMap.setTarget(undefined);
  }, []);

  // zoom change handler
  useEffect(() => {
    if (!map) return;
    map.getView().setZoom(mapZoom);
  }, [mapZoom]);

  return (
    <div ref={mapRef} id="tcf-map" className="ol-map">
      <div
        className={
          showReport
            ? "tcf-map--controles tcf-map--controles--bg"
            : "tcf-map--controles"
        }
      >
        <Sidebar />
        {showReport && <ReportBar />}
        {!showReport && <Legend />}
        <Controls />
      </div>
      <Tooltip />
      <LoadingLayer />
      <Error />
    </div>
  );
}

export default Map;
