import i18n from "i18next";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import setChoice from "store/actions/choiceAction";
import * as mapActions from "store/actions/mapActions";
import PopUp from "components/Popup/PopUp";
import { Button, ButtonGroup } from "components";
import { useLayersStore } from "service/layers.service";

const menuList = [
  { name: i18n.t("settlements"), type: "settlements" },
  { name: i18n.t("rural_properties"), type: "rural_properties" },
  { name: i18n.t("traditional_territories"), type: "traditional_territories" },
];

const getObjectByName = (array, targetName) =>
  array.find((obj) => obj.name === targetName);

function RecorteFundiario({ group, isReport = false, list }) {
  const choice = useSelector((state) => state.choice);
  const { active } = choice || {};
  const { getLayersGroups, setGroups, groups } = useLayersStore();
  const layers = useSelector((state) => state.layers);
  const { layers_groups } = layers.data || {};

  const dispatch = useDispatch();
  const [popUpPosition, setPopUpPosition] = useState(1);
  const [popUp, setPopUp] = useState(false);

  const controlLayer = (checked, item) => {
    if (checked || item.visible)
      dispatch(
        mapActions.toggleLayer({ checked, item, listAll: layers_groups })
      );
  };

  // para controlar quais camada ativa quando seleciona um recorte territorial
  useEffect(() => {
    if (layers_groups) {
      const groups = layers_groups.map((group) => {
        const { name } = group; // Declare 'name' with 'const'
        const choice = {
          ...menuList[0],
          active: 0,
        };
        const currentActive = false;

        return {
          name,
          currentActive,
          choice,
        };
      });

      getLayersGroups(groups);
    }
  }, []); // Include dependencies in the dependency array

  useEffect(() => {
    if (layers_groups) {
      const { territorialCut } = choice || {};
      const { value } = territorialCut || {};

      // pega apenas as camadas de apoio
      const supportLayers = layers_groups?.find((lg) => !lg.main_menu);

      // assentamentos
      const ass = supportLayers.layers_names.find(
        (sl) =>
          sl.layer_link.name_mapfile ===
          "mapbiomas-alertas:dashboard_settlements-static-layer"
      );

      // municípios
      const mun = supportLayers.layers_names.find(
        (sl) => sl.layer_link.name_mapfile === "municipios"
      );

      // biomas
      const biomes = supportLayers.layers_names.find(
        (sl) =>
          sl.layer_link.name_mapfile ===
          "mapbiomas-alertas:dashboard_biomes-static-layer"
      );

      controlLayer(false, ass);
      controlLayer(false, mun);
      controlLayer(false, biomes);

      // se recorte fundiário for assentamento e recorte territorial assentamento
      // ativa a camada assentamento
      if (value === "settlements") {
        controlLayer(true, ass);
      }

      // se recorte fundiário for imóveis rurais e recorte territorial imóveis rurais
      // ou recorte fundiário for territórios tradicionais e recorte territorial territórios tradicionais
      // ativa a camada municípios
      if (value === "rural_properties" || value === "traditional_territories") {
        controlLayer(true, mun);
      }

      // se recorte territorial for bioma, ativa a camada
      if (value === "biome") {
        controlLayer(true, biomes);
      }
    }
  }, [choice]);

  useEffect(() => {
    const newChoice = {
      ...choice,
      territorialCut: {
        label: i18n.t("brazil"),
        value: "brazil",
      },
      territory: null,
    };
    dispatch(setChoice(newChoice));
  }, [choice.type]);

  const handleClick = (item, idx) => {
    const { name, type } = item || {};
    if (type === "traditional_territories") setPopUpPosition(idx + 1);

    const newChoice = { ...choice, active: idx, name, type };
    dispatch(setChoice(newChoice));
  };
  const handleSetGroups = (item, idx, group) => {
    const { name, type } = item || {};
    if (type === "traditional_territories") setPopUpPosition(idx + 1);

    const newsGroups = groups.map((obj) => {
      if (obj.name === group.name) {
        // If the object's name matches the target name, update the data
        const choice = {
          name,
          type,
          active: idx,
        };
        return { ...obj, choice };
      }
      // Otherwise, return the original object unchanged
      return obj;
    });
    setGroups(newsGroups);
  };

  const returnLengthGroupLayers = (listName) => {
    const filterItems = list.filter(
      (l) =>
        (l.layer_link.land_cutout === listName && l.checked) ||
        (l.layer_link.land_cutout === null && l.checked)
    );
    return filterItems?.length;
  };
  return (
    <div className="tcf-land-cut--select">
      <h2 className="tcf-sidebar__territory-selector-title">
        {i18n.t("select_tenure_category")}:
      </h2>

      {isReport ? (
        <ButtonGroup>
          {menuList.map((item, idx) => (
            <Button
              key={idx}
              onClick={() => {
                handleClick(item, idx);
                /*  if (idx === 2) setPopUp(true);
                if (idx !== 2) setPopUp(false); */
              }}
              variant={idx === active ? "primary" : "outline-primary"}
              size="sm"
            >
              {item.name}
            </Button>
          ))}
        </ButtonGroup>
      ) : (
        <ButtonGroup>
          {menuList.map((item, idx) => (
            <Button
              key={idx}
              onClick={() => {
                handleSetGroups(item, idx, group);
                /* if (idx === 2) setPopUp(true);
                if (idx !== 2) setPopUp(false); */
              }}
              variant={
                getObjectByName(groups, group?.name) &&
                getObjectByName(groups, group?.name)?.name === group?.name &&
                idx === getObjectByName(groups, group?.name).choice.active
                  ? "primary"
                  : "outline-primary"
              }
              size="sm"
            >
              {item.name}
              {getObjectByName(groups, group?.name) &&
              getObjectByName(groups, group?.name)?.name === group?.name &&
              returnLengthGroupLayers(item.type) > 0 ? (
                <span className="tcf-land-cut--select-active-indicator">
                  {returnLengthGroupLayers(item.type)}
                </span>
              ) : null}
            </Button>
          ))}
        </ButtonGroup>
      )}

      <PopUp
        trigger={popUp}
        setTrigger={setPopUp}
        left={popUpPosition * 117.85}
      >
        {i18n.t("msg_traditional_territories")}
      </PopUp>
    </div>
  );
}

export default RecorteFundiario;
