/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18next";
import { ResponsiveBar } from "@nivo/bar";
import {
  formatNumber,
  isEmptyObj,
  isObj,
  sumValueArrayObject,
  renderLegendNivo,
} from "helpers/utils";
import { fetchEmbargoes } from "store/actions/embargoesAtions";
import ReportFeedback from "components/ReportFeedback";
import NivoCustomTooltip from "components/NivoCustomTooltip";

function Embargos() {
  const dispatch = useDispatch();
  const choice = useSelector((state) => state.choice);
  const { embargoes } = useSelector((state) => state.embargo);
  const [data, setData] = useState([]);
  const customColors = ["#F1E6C9"];

  useEffect(() => {
    dispatch(fetchEmbargoes(choice));
  }, [dispatch, choice]);

  useEffect(() => {
    if (isObj(embargoes) && !isEmptyObj(embargoes)) {
      const {
        overlap_ar_EMBARGO_IRU,
        overlap_ar_EMBARGO_AST,
        overlap_ar_EMBARGO_PCT,
        overlap_ar_EMBARGO_IRU_AST,
        overlap_ar_EMBARGO_IRU_PCT,
        overlap_ar_EMBARGO_Outros,
      } = embargoes;

      setData([
        {
          type: "IRU",
          value: Number(overlap_ar_EMBARGO_IRU),
        },
        {
          type: "AST",
          value: Number(overlap_ar_EMBARGO_AST),
        },
        {
          type: "PCT",
          value: Number(overlap_ar_EMBARGO_PCT),
        },
        {
          type: "IRU x AST",
          value: Number(overlap_ar_EMBARGO_IRU_AST),
        },
        {
          type: "IRU x PCT",
          value: Number(overlap_ar_EMBARGO_IRU_PCT),
        },
        {
          type: "Outros",
          value: Number(overlap_ar_EMBARGO_Outros),
        },
      ]);
    }
  }, [embargoes]);

  // soma os valores
  const totalData = sumValueArrayObject(data, "value");

  return (
    <div className="tcf-map-report--body-territorialcut--ischildren-item">
      <div className="tcf-map-report--body-territorialcut--ischildren-item--title">
        {i18n.t("embargoes")}
      </div>
      <div className="tcf-map-report--body-territorialcut--ischildren-item--body">
        {data ? (
          <ResponsiveBar
            data={data}
            keys={["value"]}
            indexBy="type"
            margin={{ top: 10, right: 50, bottom: 150, left: 90 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            valueFormat=">-,~"
            colors={customColors}
            borderColor={{
              from: "color",
              modifiers: [["darker", 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "",
              legendPosition: "middle",
              legendOffset: 32,
              truncateTickAt: 0,
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 6,
              tickRotation: 0,
              legend: i18n.t("areaHa"),
              legendPosition: "middle",
              legendOffset: -80,
              truncateTickAt: 0,
            }}
            tooltip={({ indexValue, value }) => {
              const valueFormated = formatNumber(value, 0, ".", ",");
              return (
                <NivoCustomTooltip id={indexValue} value={valueFormated} />
              );
            }}
            enableLabel={false}
            legends={[
              {
                dataFrom: "indexes",
                anchor: "bottom-left",
                direction: "column",
                justify: false,
                translateX: -90,
                translateY: 170,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 0,
                data: data.map((d) =>
                  renderLegendNivo({
                    type: d.type,
                    value: d.value,
                    total: totalData,
                  })
                ),
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        ) : (
          <ReportFeedback />
        )}
      </div>
    </div>
  );
}

export default Embargos;
