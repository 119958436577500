import i18n from "i18next";
import api, { getLanguage } from "service/axios.service";
import {
  SOBRE_FAIL,
  SOBRE_REQUEST,
  SOBRE_SUCCESS,
} from "../constants/sobreConstants";

export const sobreActions = () => async (dispatch) => {
  dispatch({ type: SOBRE_REQUEST });
  try {
    const result = await api.get(`/v1/about/${getLanguage()}`);
    const list = result.data[0].about_topic;

    dispatch({
      type: SOBRE_SUCCESS,
      payload: list,
    });
  } catch (error) {
    const { response } = error || {};
    const { data } = response || {};
    const { message } = data || {};

    const msg = message || i18n.t("unknownError");

    dispatch({
      type: SOBRE_FAIL,
      payload: msg,
    });
  }
};
