import React from "react";
import { Link } from "react-router-dom";

function Logo({ url }) {
  const scrollTo = (target) => {
    const top =
      document.getElementById(target).getBoundingClientRect().top +
      (window.scrollY || window.pageYOffset) -
      100;
    window.scrollTo({
      top, // Offset of -100 pixels
      /* top: target - 100,  */ // Offset of -100 pixels
      behavior: "smooth",
    });
  };
  return (
    <Link to="/" onClick={() => scrollTo("home")}>
      <img src={url} className="tcf-logo" />
    </Link>
  );
}

export default Logo;
