import i18n from "i18next";
import { Vector } from "ol/layer";
import React from "react";
import { useSelector } from "react-redux";

import { useMapStore } from "service/map.context";

const maxHeightBodyLegend = window.innerHeight - 120;

const LegendVector = ({ layer }) => {
  const styles = layer.getStyle()();
  return Object.keys(styles).map((key) => {
    const fill = styles[key].style.getFill();
    return (
      <div className="tcf-map-legend--vector-item" key={key}>
        <span
          className="tcf-map-legend--vector-symbol"
          style={{
            backgroundColor: fill.getColor(),
          }}
        />
        <span className="tcf-map-legend--vector-name">{styles[key].label}</span>
      </div>
    );
  });
};

const getLegend = (layer) => {
  const label = layer.get("label");
  // VECTOR
  if (layer instanceof Vector) {
    return (
      <div className="tcf-map-legend--label">
        <div className="tcf-map-legend-label--title">{label}</div>
        <LegendVector layer={layer} />
      </div>
    );
  }
  // para legenda dos heatmaps
  const low = i18n.t("low");
  const high = i18n.t("high");

  const source = layer.getSource();

  // WMS
  const url = `${source.getLegendUrl()}&SLD_VERSION=1.1.0&name=${label}&low=${low}&high=${high}`;
  return (
    <div className="tcf-map-legend-label">
      <div className="tcf-map-legend-label--title">{label}</div>
      <div className="tcf-map-legend-label--imgs">
        <img src={url} alt={`${i18n.t("legend")} ${label}`} />
      </div>
    </div>
  );
};

function Legend() {
  const { name, type } = useSelector((state) => state.choice);
  const { activatedLayers } = useSelector((state) => state.map);
  const { openLegend, setOpenLegend } = useMapStore();
  return (
    <div
      className="tcf-map-legend"
      style={{
        display: !!activatedLayers.length && openLegend ? "block" : "none",
      }}
    >
      <div className="tcf-map-legend--wrapper">
        {/* <div className="tcf-map-legend--title">
          {i18n.t("legend")}

          <span onClick={() => setOpenLegend(!openLegend)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
            >
              <path
                d="M13 1.75L1 13.75"
                stroke="#AFA7A1"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 1.25L13 13.25"
                stroke="#AFA7A1"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
 */}
        <div className="tcf-map-legend--body">
          {activatedLayers && activatedLayers.length > 0 ? (
            activatedLayers.map((layer, i) => (
              <div key={`layer-${i}`}>{getLegend(layer)}</div>
            ))
          ) : (
            <div style={{ fontSize: 14 }}>Nenhuma camada ativada</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Legend;
