import styled from "styled-components";
import { color } from "styles";

const StyledPopUp = styled.div`
  background-color: ${color.yellow1};
  z-index: 1;
  display: flex;
  padding: 10px;
  border-radius: 10px;

  button {
    background-color: ${color.yellow1};
    align-self: flex-start;
  }

  img {
    height: 15px;
    background-color: ${color.yellow1};
    padding: 0;
  }

  /*  @media (min-width: 480px) and (max-width: 1024px) {
    transform: translateX(220%);
    height: 50vh;
  } ; */
`;

const StyledTriangle = styled.div`
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-right: 25px solid ${color.yellow1};
  z-index: 1;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 10;
  left: ${({ left }) => left && `${left}px`};
  max-width: 400px;
  transform: translateY(-35%);
`;

const SaibaMais = styled.div`
  display: flex;
  button {
    background-color: ${color.yellow1};
  }
  a {
    background-color: ${color.yellow1};
    padding: 10px;
    border-radius: 10px;
  }
`;

const Wrap = styled.div`
  display: block;
  background-color: ${color.yellow1};
  border-radius: 10px;
`;

const SaibaMaisContainer = styled.button`
  background: #ffb809;
  color: #1c6e74;
  padding: 4px 10px;
  margin-right: 32px;
  border-radius: 5px;
  font-weight: 400;
`;
export {
  StyledPopUp,
  StyledTriangle,
  Container,
  SaibaMais,
  Wrap,
  SaibaMaisContainer,
};
