import { barOpenOrClose } from "../actions/types";

const initialState = {
  open: false,
};

const barWithDataReducer = (state = initialState, action) => {
  const { open } = action?.result || {};

  switch (action.type) {
    case barOpenOrClose.OPEN_OR_CLOSE:
      return { ...state, open };
    default:
      return state;
  }
};

export default barWithDataReducer;
