import { transform } from "ol/proj";

export const mapCenter = [-60.3319245, -15.2533513];
export const mapZoom = 4.6;

// olmap
export const olMap = () =>
  document.getElementById("tcf-map")
    ? document.getElementById("tcf-map").data
    : null;

// zoom para um layer específico
export const zoomToExtentLayer = (extent, padding = [60, 60, 60, 60]) => {
  const olmap = olMap();
  if (extent && olmap) {
    olmap.getView().fit(extent, { duration: 2000, padding });
  }
};

// zoom para posição inicial do mapa
export const zoomToExtentMap = () => {
  const olmap = olMap();
  if (olmap)
    olmap.getView().animate({
      center: transform(mapCenter, "EPSG:4326", "EPSG:3857"),
      zoom: mapZoom,
      duration: 1500,
    });
};

// zoom para controlar mapa
export const zoomInOutMap = (type) => {
  const olmap = olMap();
  if (olmap) {
    if (type === "In") {
      olmap.getView().animate({
        zoom: olmap.getView().getZoom() + 1,
        duration: 250,
      });
    } else {
      olmap.getView().animate({
        zoom: olmap.getView().getZoom() - 1,
        duration: 250,
      });
    }
  }
};
