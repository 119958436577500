// import { useState } from 'react'
import React from "react";
import styled from "styled-components";
import { alignment, color, direction } from "styles";

const App = ({ active }) => {
  const chooseIcon = (active) => {
    switch (active) {
      case true:
        return <Icon type="check" px={12} color="green" />;
      case false:
        return <Icon type="close" px={12} color="red" />;
      default:
        return "";
    }
  };

  return (
    // <CheckBox onClick={() => setActive((active + 1) % 3)}>
    <CheckBox>{chooseIcon(active)}</CheckBox>
  );
};

const icons = {
  close:
    "M22 3.76101L20.239 2L12 10.2075L3.76101 2L2 3.76101L10.2075 12L2 20.239L3.76101 22L12 13.7925L20.239 22L22 20.239L13.7925 12L22 3.76101Z",
  check:
    "M7.32468 21.8571L0 14.5325L2.18182 12.3506L7.32468 17.4935L21.8182 3L24 5.18182L7.32468 21.8571Z",
};

const Icon = ({ type, px, color }) => (
  <Svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    px={px}
  >
    <path fillRule="evenodd" clipRule="evenodd" d={icons[type]} fill={color} />
  </Svg>
);

const Svg = styled.svg`
  width: ${({ px }) => px && `${px}px`};
  height: ${({ px }) => px && `${px}px`};
  ${({ pointer }) => pointer && "cursor: pointer"};
  ${({ absolute }) => absolute && "position: absolute;"};
  ${({ top }) => top && `top: ${top}px`};
  ${({ right }) => right && `right: ${right}px`};
`;

const CheckBox = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 15px;
  border: 1px solid ${color.gray};
  cursor: pointer;
  ${direction};
  ${alignment};
`;

export default App;
