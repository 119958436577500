import { css } from "styled-components";

const direction = (x = "row") =>
  `
  display: flex;
  flex-direction: ${x};
`;

const alignment = ({ main = "center", cross = "center" } = {}) =>
  `
  justify-content: ${main};
  align-items: ${cross};
`;

const transition = ({ seconds = 0.3 } = {}) =>
  `transition: width ${seconds}s ease-in-out;`;

const background = ({ img = "", color = "rgba(0,0,0,0)" } = {}) =>
  `
  background: linear-gradient(${color}, ${color}), url(${img}) center/ cover fixed no-repeat;
`;

const cleanButton = css`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

export { direction, alignment, transition, cleanButton, background };
