import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ResponsiveBar } from "@nivo/bar";
import { useReportStore } from "service/report.service";
import { formatNumber, isEmptyObj, isObj } from "helpers/utils";
import CustomLegend from "components/CustomLegend";
import ReportFeedback from "components/ReportFeedback";
import NivoCustomTooltip from "components/NivoCustomTooltip";

const Excedente = () => {
  const choice = useSelector((state) => state.choice);
  const { type } = choice || {};
  const { legalidadeInner } = useSelector((state) => state.legalidade);
  const { sizeFilter } = useReportStore();

  const [dataRL, setDataRL] = useState([]);

  const ConvertToNumber = (stringValue) => Number(stringValue);

  useEffect(() => {
    if (isObj(legalidadeInner) && !isEmptyObj(legalidadeInner)) {
      if (type === "rural_properties") {
        if (sizeFilter?.value === "All") {
          setDataRL([
            {
              size: "P",
              [i18n.t("surplusRL")]: ConvertToNumber(
                legalidadeInner.surplus_rl_small_properties
              ),
              value: ConvertToNumber(
                legalidadeInner.surplus_rl_small_properties
              ),
              totalVal: ConvertToNumber(legalidadeInner.surplus_total),
              color: "#6D945C",
              isPattern: false,
            },
            {
              size: "M",
              [i18n.t("surplusRL")]: ConvertToNumber(
                legalidadeInner.surplus_rl_average_properties
              ),
              value: ConvertToNumber(
                legalidadeInner.surplus_rl_average_properties
              ),
              totalVal: ConvertToNumber(legalidadeInner.surplus_total),
              color: "#6D945C",
              isPattern: false,
            },
            {
              size: "G",
              [i18n.t("surplusRL")]: ConvertToNumber(
                legalidadeInner.surplus_rl_large_properties
              ),
              value: ConvertToNumber(
                legalidadeInner.surplus_rl_large_properties
              ),
              totalVal: ConvertToNumber(legalidadeInner.surplus_total),
              color: "#6D945C",
              isPattern: false,
            },
          ]);
        }
        if (sizeFilter?.value === "G") {
          setDataRL([
            {
              size: "G",
              [i18n.t("surplusRL")]: ConvertToNumber(
                legalidadeInner.surplus_rl_large_properties
              ),
              value: ConvertToNumber(
                legalidadeInner.surplus_rl_large_properties
              ),
              totalVal: ConvertToNumber(legalidadeInner.surplus_total),
              color: "#6D945C",
              isPattern: false,
            },
          ]);
        }
        if (sizeFilter?.value === "M") {
          setDataRL([
            {
              size: "M",
              [i18n.t("surplusRL")]: ConvertToNumber(
                legalidadeInner.surplus_rl_average_properties
              ),
              value: ConvertToNumber(
                legalidadeInner.surplus_rl_average_properties
              ),
              totalVal: ConvertToNumber(legalidadeInner.surplus_total),
              color: "#6D945C",
              isPattern: false,
            },
          ]);
        }
        if (sizeFilter?.value === "S") {
          setDataRL([
            {
              size: "P",
              [i18n.t("surplusRL")]: ConvertToNumber(
                legalidadeInner.surplus_rl_small_properties
              ),
              value: ConvertToNumber(
                legalidadeInner.surplus_rl_small_properties
              ),
              totalVal: ConvertToNumber(legalidadeInner.surplus_total),
              color: "#6D945C",
              isPattern: false,
            },
          ]);
        }
      } else if (type === "settlements") {
        setDataRL([
          {
            size: "",
            [i18n.t("surplusRL")]: ConvertToNumber(
              legalidadeInner.surplus_total
            ),
            name: i18n.t("surplusRL"),
            value: ConvertToNumber(legalidadeInner.surplus_total),
            totalVal: ConvertToNumber(legalidadeInner.surplus_total),
            color: "#6D945C",
            isPattern: false,
          },
        ]);
      } else {
        setDataRL("");
      }
    }
  }, [legalidadeInner, sizeFilter, type]);

  const conditionalArray = [[i18n.t("surplusRL")]];
  const customColors = ["#6D945C"];

  return (
    <div className="tcf-map-report--body-territorialcut--ischildren">
      <div className="tcf-map-report--body-territorialcut--ischildren-item">
        <div className="tcf-map-report--body-territorialcut--ischildren-item--title">
          {i18n.t("surplusRL")}
        </div>
        {dataRL ? (
          <>
            <div className="tcf-map-report--body-territorialcut--ischildren-item--body">
              <ResponsiveBar
                data={dataRL}
                keys={conditionalArray}
                indexBy="size"
                margin={{ top: 10, right: 50, bottom: 30, left: 90 }}
                padding={0.6}
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                colors={customColors}
                /* colors={(bar) =>
                  type === "settlements"
                    ? "#6D945C"
                    : bar.index === 0
                      ? sizeFilter?.value === "G"
                        ? "#AC6C29"
                        : sizeFilter?.value === "M"
                          ? "#78C753"
                          : "#F3E6C9"
                      : bar.index === 1
                        ? "#78C753"
                        : "#AC6C29"
                } */
                axisTop={null}
                axisRight={null}
                axisLeft={{
                  tickSize: 0,
                  tickPadding: 6,
                  tickRotation: 0,
                  legend: i18n.t("areaHa"),
                  legendPosition: "middle",
                  legendOffset: -80,
                  truncateTickAt: 0,
                }}
                // eslint-disable-next-line react/no-unstable-nested-components
                tooltip={({ id, value }) => {
                  const valueFormated = formatNumber(value, 0, ".", ",");
                  return <NivoCustomTooltip id={id} value={valueFormated} />;
                }}
                enableLabel={false}
              />
            </div>
            <CustomLegend data={dataRL} useIcone={type === "settlements"} />
            {/* {type !== "settlements" && <CustomLegend data={dataRL} />} */}
          </>
        ) : (
          <ReportFeedback />
        )}
      </div>
    </div>
  );
};

export default Excedente;
