/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-globals */
export const isObj = (obj) => obj != null && obj instanceof Object;

export const isEmptyObj = (obj) => Object.keys(obj).length === 0;

export const isArray = (array) => Array.isArray(array);

// classifica array de objetos em asc ou desc
export const sortArrayOfObject = (key, order = "asc") =>
  function innerSort(a, b) {
    if (
      !Object.prototype.hasOwnProperty.call(a, key) ||
      !Object.prototype.hasOwnProperty.call(b, key)
    ) {
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };

export const sumValueArrayObject = (array, key) =>
  array.reduce(
    (accumulator, currentValue) =>
      !isNaN(currentValue[key]) ? accumulator + currentValue[key] : accumulator,
    0
  );

// movimenta itens de um array entre indexs
export const arrayMove = (arr, fromIndex, toIndex) => {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);

  return arr;
};

/*
 * PARAMETERS:
 * number: number formatting
 * s1: total of decimal places
 * s2 and s3: point or comma
 */
export function formatNumber(number, s1, s2, s3) {
  if (!number) return 0;
  const text = `${parseFloat(number).toFixed(s1)}`;
  const x = text.split(".");
  let x1 = x[0];
  const x2 = x.length > 1 ? s3 + x[1] : "";
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, `$1${s2}$2`);
  }
  return x1 + x2;
}

export const ConvertToNumber = (stringValue) => Number(stringValue);

export const renderLegendNivo = ({ type, value, total, color }) => {
  const percent = formatNumber((value / total) * 100, 2, ".", ",");
  return {
    label: (
      <>
        {color ? (
          <>
            <tspan
              style={{
                fill: "#7F746C",
                fontSize: "12px",
                fontWeight: 600,
                marginBottom: "4px",
              }}
            >
              {type}
            </tspan>
            <tspan x={25} dy={18} style={{ fill: "#7F746C", fontSize: "12px" }}>
              {formatNumber((value / total) * 100, 2, ".", ",")}% -{" "}
              {formatNumber(value, 0, ".", ",")} ha
            </tspan>
          </>
        ) : (
          <>
            <tspan style={{ fontWeight: 600, fill: "#7F746C" }}>{type}</tspan>
            <tspan style={{ fill: "#7F746C", fontSize: 12 }}>
              : {percent}% - {formatNumber(value, 0, ".", ",")} ha
            </tspan>
          </>
        )}
      </>
    ),
    color: color,
  };
};

export function filtersWithoutAccent(str) {
  return str
    .normalize("NFD")
    .replace(/[^a-zA-Zs]/g, "")
    .toLowerCase();
}

export function formateSelect(array, type) {
  let list = [];
  switch (type) {
    case "biome":
      list = array.map((item) => ({
        label: item.bioma,
        value: item.cd_bioma,
      }));
      break;
    case "state":
    case "traditional_territories":
    case "rural_properties":
      list = array.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      break;
    case "settlements":
      list = array.map((item) => ({
        label: item.incra_nm,
        value: item.id,
      }));
      break;
  }

  return list;
}
