/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/button-has-type */
/* eslint-disable prettier/prettier */
import React from "react";
import { NavLink } from "react-router-dom";

function Button({
  onClick = () => { },
  style = null,
  disabled = false,
  children,
  size = "",
  variant = "",
  type = "#",
  href = "",
  multipleState = false,
  loading = false,
}) {
  const classNames = (variant, size, isActive) => {
    let className = `tcf-btn tcf-btn--primary tcf-btn--medium`;
    if (variant === "primary" && size === "md") {
      className = `tcf-btn tcf-btn--primary tcf-btn--medium`;
    } else if (variant === "primary" && size === "sm") {
      className = `tcf-btn tcf-btn--primary tcf-btn--small`;
    } else if (variant === "outline-primary" && size === "md") {
      className = `tcf-btn tcf-btn--outline tcf-btn--medium`;
    } else if (variant === "outline-primary" && size === "sm") {
      className = `tcf-btn tcf-btn--outline tcf-btn--small`;
    } else if (variant === "icon-primary" && size === "md") {
      className = `tcf-btn tcf-btn--primary tcf-btn--icon tcf-btn--medium`;
    } else if (variant === "icon-primary" && size === "sm") {
      className = `tcf-btn tcf-btn--primary tcf-btn--icon tcf-btn--small`;
    } else if (variant === "icon-outline" && size === "md") {
      className = `tcf-btn tcf-btn--outline tcf-btn--icon tcf-btn--icon--medium`;
    } else if (variant === "icon-outline" && size === "sm") {
      className = `tcf-btn tcf-btn--outline tcf-btn--icon tcf-btn--icon--small`;
    } else if (variant === "link-light" && size === "md") {
      if (isActive) {
        className = `tcf-btn-link tcf-btn-link--light`;
      } else {
        className = `tcf-btn-link tcf-btn-link--light`;
      }
    } else if (variant === "link-light" && size === "sm") {
      if (isActive) {
        className = `tcf-btn-link tcf-btn-link--light`;
      } else {
        className = `tcf-btn-link tcf-btn-link--light`;
      }
    } else if (variant === "link-dark" && size === "md") {
      if (isActive) {
        className = `tcf-btn-link tcf-btn-link--dark`;
      } else {
        className = `tcf-btn-link tcf-btn-link--dark`;
      }
    } else if (variant === "link-dark" && size === "sm") {
      if (isActive) {
        className = `tcf-btn-link tcf-btn-link--dark`;
      } else {
        className = `tcf-btn-link tcf-btn-link--dark`;
      }
    } else if (variant === "nav-link-light" && size === "md") {
      if (isActive) {
        className = `tcf-btn-link tcf-btn-link--light tcf-btn-link--light--active`;
      } else {
        className = `tcf-btn-link tcf-btn-link--light`;
      }
    } else if (variant === "nav-link-light" && size === "sm") {
      if (isActive) {
        className = `tcf-btn-link tcf-btn-link--light tcf-btn-link--light--active`;
      } else {
        className = `tcf-btn-link tcf-btn-link--light`;
      }
    } else if (variant === "nav-link-dark" && size === "md") {
      if (isActive) {
        className = `tcf-btn-link tcf-btn-link--dark tcf-btn-link--dark--active`;
      } else {
        className = `tcf-btn-link tcf-btn-link--dark`;
      }
    } else if (variant === "nav-link-dark" && size === "sm") {
      if (isActive) {
        className = `tcf-btn-link tcf-btn-link--dark tcf-btn-link--dark--active`;
      } else {
        className = `tcf-btn-link tcf-btn-link--dark`;
      }
    }

    return className;
  };
  return (
    <>
      {variant.includes("link") ? (
        <NavLink
          to={href}
          style={style}
          onClick={() => onClick()}
          className={({ isActive }) => `${classNames(variant, size, isActive)}`}
        >
          {children}
        </NavLink>
      ) : variant === "icon" ? (
        <button
          type={type}
          style={style}
          disabled={disabled}
          className={`${multipleState ? "btn-multiple-state " : ""}${classNames(
            variant,
            size
          )}`}
        >
          {children}
        </button>
      ) : (
        <button
          onClick={() => onClick()}
          type={type}
          style={style}
          disabled={disabled}
          className={`${multipleState ? "tcf-btn-multiple-state " : ""
            }${classNames(variant, size)}${loading ? " show-spinner" : ""}`}
        >
          {children}
        </button>
      )}
    </>
  );
}

export default Button;
