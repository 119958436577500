import React from "react";

function ButtonGroup({
  children,
  style = {},
  vertical = false,
  center,
  space,
}) {
  return (
    <div
      style={style}
      className={`tcf-btn-group ${vertical ? "vertical" : ""} 
      ${space ? "space" : ""} ${vertical ? "vertical" : ""} 
      ${center ? "center" : ""} `}
    >
      {" "}
      {children}
    </div>
  );
}

export default ButtonGroup;
