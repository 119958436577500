import api from "service/axios.service";
import { sendMailUser } from "./types";
import i18n from "i18next";

export const sendMail = (action) => async (dispatch) => {
  dispatch({ type: sendMailUser.SEND_MAIL_REQUEST });
  try {
    const res = await api.post("/v1/contact", action);
    const { data } = res || {};
    dispatch({
      type: sendMailUser.SEND_MAIL_SUCCESS,
      payload: { message: i18n.t("successSendMessage"), type: "success" },
    });
    return data;
  } catch (error) {
    dispatch({
      type: sendMailUser.SEND_MAIL_FAILURE,
      payload: { message: i18n.t("errorSendMessage"), type: "error" },
    });
  }
};

export const closeMessage = () => async (dispatch) => {
  dispatch({
    type: sendMailUser.CLOSE_MESSAGE,
  });
};
