import {
  MUNICIPALITIES_REQUEST,
  MUNICIPALITIES_FAIL,
  MUNICIPALITIES_SUCCESS,
} from "../constants/municipalitiesConstants";

const municipalitiesReducer = (state = { municipalitiesInner: [] }, action) => {
  switch (action.type) {
    case MUNICIPALITIES_REQUEST:
      return { loading: true, municipalitiesInner: [] };
    case MUNICIPALITIES_SUCCESS:
      return { loading: false, municipalitiesInner: action.payload };
    case MUNICIPALITIES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export default municipalitiesReducer;
