/* eslint-disable prettier/prettier */
import i18n from "i18next";
import { Tile } from "ol/layer";
import { OSM, XYZ } from "ol/source";
import React, { useEffect, useState } from "react";
import "./style.css";
import { useMapStore } from "service/map.context";
import Popover from "components/Popover";
import ControlButton from "../Controls/ControlButton";


const maxHeightBodyLegend = window.innerHeight - 120;

/*
 * Street map
 */
const streetMap = new Tile({
  source: new XYZ({
    attribution:
      'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
      'rest/services/World_Light_Gray_Base/MapServer">ArcGIS</a>',
    url: "http://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}",
  }),
  base: true,
  visible: true,
  name: "streetMap",
  label: "Mapa da Rua",
});

/*
 * Satellite Map
 */
const satelliteMap = new Tile({
  source: new XYZ({
    attributions:
      'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
      'rest/services/ESRI_Imagery_World_2D/MapServer">ArcGIS</a>',
    url:
      "https://server.arcgisonline.com/ArcGIS/rest/services/" +
      "World_Imagery/MapServer/tile/{z}/{y}/{x}",
  }),
  base: true,
  visible: false,
  name: "satellite",
  label: "Mapa de Satélite",
});

/*
 * Label Satellite Map
 */
const satelliteLabel = new Tile({
  source: new XYZ({
    attributions:
      'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
      'rest/services/ESRI_Imagery_World_2D/MapServer">ArcGIS</a>',
    url:
      "https://server.arcgisonline.com/ArcGIS/rest/services/" +
      "Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}",
  }),
  base: true,
  visible: false,
  name: "satellite",
  label: "Mapa de Satélite",
});

/*
 * Função BaseLayer
 */
function BaseLayer() {
  const { map } = useMapStore();
  // para definir qual radio button fica checked

  const [currentLayer, setCurrentLayer] = useState("streetMap");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    map?.addLayer(streetMap);
    map?.addLayer(satelliteMap);
    map?.addLayer(satelliteLabel);
  }, [map]);

  /*
   * Para alternar os mapas do background
   */
  const toggleBaseLayer = (ev) => {
    setCurrentLayer(ev);

    map?.getLayers().forEach((layer) => {
      if (layer.get("base")) {
        if (layer.get("name") === ev) {
          layer.setVisible(true);
        } else {
          layer.setVisible(false);
        }
      }
    });
  };

  const openModal = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <div className="tcf-map--control__btn--container">
      <ControlButton
        Action={openModal}
        type="BaseLayer"
        title={i18n.t("baseMap")}
        active={showModal}
      />
      <Popover
        showModal={showModal}
        setShowModal={setShowModal}
        size="sm"
        content={
          <div className="tcf-map-basemap">
            <div
              id="streetMap"
              onClick={() => toggleBaseLayer("streetMap")}
              className={
                currentLayer === "streetMap"
                  ? "tcf-map-basemap-item tcf-map-basemap-item--active"
                  : "tcf-map-basemap-item"
              }
            >
              Open Street
            </div>
            <div
              id="satellite"
              onClick={() => toggleBaseLayer("satellite")}
              className={
                currentLayer === "satellite"
                  ? "tcf-map-basemap-item tcf-map-basemap-item--active"
                  : "tcf-map-basemap-item"
              }
            >
              Satélite
            </div>
          </div>
        }
      /*  title={i18n.t("baseMap")} */
      />
    </div>
  );
}

export default BaseLayer;
