import React from "react";
import styled from "styled-components";
import { direction, alignment, color } from "styles";

const CheckBox = ({ name, selected, setSelected, id }) => (
  <StyledCheckBox onClick={() => setSelected(selected === id ? null : name)}>
    {selected === name && <p style={{ color: "white" }}>X</p>}
  </StyledCheckBox>
);

const StyledCheckBox = styled.div`
  width: 20px;
  height: 20px;
  background: ${color.gray};
  border-radius: 5px;
  ${direction};
  ${alignment};
  cursor: pointer;
`;

export default CheckBox;
