/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { legalidadeData } from "store/actions/legalidadeActions";
import NivoBar from "../../components/NivoBar";
import { isEmptyObj, isObj } from "helpers/utils";
import { useReportStore } from "service/report.service";

function Legalidade() {
  const dispatch = useDispatch();
  const choice = useSelector((state) => state.choice);
  const { type } = choice || {};
  const { legalidadeInner } = useSelector((state) => state.legalidade);
  const { sizeFilter } = useReportStore();

  const [data, setdata] = useState();

  useEffect(() => {
    dispatch(legalidadeData(choice));
  }, [dispatch, choice]);

  const calculateTotal = (size) => {
    const total =
      parseFloat(legalidadeInner[`passive_app_${size}_properties`]) +
      parseFloat(legalidadeInner[`passive_rl_${size}_properties`]) +
      parseFloat(legalidadeInner[`remaining_app_${size}_properties`]) +
      parseFloat(legalidadeInner[`remaining_rl_${size}_properties`]) +
      parseFloat(legalidadeInner[`surplus_rl_${size}_properties`]);

    return total.toFixed(2); // Round to 2 decimal places
  };

  const ConvertToNumber = (stringValue) => Number(stringValue);
  const ConvertToNegaNumber = (stringValue) => {
    const numberValue = Number(stringValue);
    const negativeNumber = -numberValue;

    return negativeNumber;
  };

  useEffect(() => {
    if (isObj(legalidadeInner) && !isEmptyObj(legalidadeInner)) {
      if (type === "rural_properties") {
        if (sizeFilter?.value === "All") {
          setdata([
            {
              size: "P",
              total: calculateTotal("small"),
              "Passivo RL": ConvertToNegaNumber(
                legalidadeInner.passive_rl_small_properties
              ),
              "Passivo APP": ConvertToNegaNumber(
                legalidadeInner.passive_app_small_properties
              ),
              "Remanescente RL": ConvertToNumber(
                legalidadeInner.remaining_rl_small_properties
              ),
              "Remanescente APP": ConvertToNumber(
                legalidadeInner.remaining_app_small_properties
              ),
              Excedente: ConvertToNumber(
                legalidadeInner.surplus_rl_small_properties
              ),
            },
            {
              size: "M",
              total: calculateTotal("average"),
              "Passivo RL": ConvertToNegaNumber(
                legalidadeInner.passive_rl_average_properties
              ),
              "Passivo APP": ConvertToNegaNumber(
                legalidadeInner.passive_app_average_properties
              ),
              "Remanescente RL": ConvertToNumber(
                legalidadeInner.remaining_rl_average_properties
              ),
              "Remanescente APP": ConvertToNumber(
                legalidadeInner.remaining_app_average_properties
              ),
              Excedente: ConvertToNumber(
                legalidadeInner.surplus_rl_average_properties
              ),
            },
            {
              size: "G",
              total: calculateTotal("large"),
              "Passivo RL": ConvertToNegaNumber(
                legalidadeInner.passive_rl_large_properties
              ),
              "Passivo APP": ConvertToNegaNumber(
                legalidadeInner.passive_app_large_properties
              ),
              "Remanescente RL": ConvertToNumber(
                legalidadeInner.remaining_rl_large_properties
              ),
              "Remanescente APP": ConvertToNumber(
                legalidadeInner.remaining_app_large_properties
              ),
              Excedente: ConvertToNumber(
                legalidadeInner.surplus_rl_large_properties
              ),
            },
          ]);
        }
        if (sizeFilter?.value === "G") {
          setdata([
            {
              size: "G",
              total: calculateTotal("large"),
              "Passivo RL": ConvertToNegaNumber(
                legalidadeInner.passive_rl_large_properties
              ),
              "Passivo APP": ConvertToNegaNumber(
                legalidadeInner.passive_app_large_properties
              ),
              "Remanescente RL": ConvertToNumber(
                legalidadeInner.remaining_rl_large_properties
              ),
              "Remanescente APP": ConvertToNumber(
                legalidadeInner.remaining_app_large_properties
              ),
              Excedente: ConvertToNumber(
                legalidadeInner.surplus_rl_large_properties
              ),
            },
          ]);
        }
        if (sizeFilter?.value === "M") {
          setdata([
            {
              size: "M",
              total: calculateTotal("average"),
              "Passivo RL": ConvertToNegaNumber(
                legalidadeInner.passive_rl_average_properties
              ),
              "Passivo APP": ConvertToNegaNumber(
                legalidadeInner.passive_app_average_properties
              ),
              "Remanescente RL": ConvertToNumber(
                legalidadeInner.remaining_rl_average_properties
              ),
              "Remanescente APP": ConvertToNumber(
                legalidadeInner.remaining_app_average_properties
              ),
              Excedente: ConvertToNumber(
                legalidadeInner.surplus_rl_average_properties
              ),
            },
          ]);
        }
        if (sizeFilter?.value === "S") {
          setdata([
            {
              size: "P",
              total: calculateTotal("small"),
              "Passivo RL": ConvertToNegaNumber(
                legalidadeInner.passive_rl_small_properties
              ),
              "Passivo APP": ConvertToNegaNumber(
                legalidadeInner.passive_app_small_properties
              ),
              "Remanescente RL": ConvertToNumber(
                legalidadeInner.remaining_rl_small_properties
              ),
              "Remanescente APP": ConvertToNumber(
                legalidadeInner.remaining_app_small_properties
              ),
              Excedente: ConvertToNumber(
                legalidadeInner.surplus_rl_small_properties
              ),
            },
          ]);
        }
      } else if (type === "settlements") {
        setdata([
          {
            size: "",
            "Passivo RL": ConvertToNegaNumber(legalidadeInner.passive_rl_total),
            "Passivo APP": ConvertToNegaNumber(
              legalidadeInner.passive_app_total
            ),
            "Remanescente RL": ConvertToNumber(
              legalidadeInner.remaining_rl_total
            ),
            "Remanescente APP": ConvertToNumber(
              legalidadeInner.remaining_app_total
            ),
            Excedente: ConvertToNumber(legalidadeInner.surplus_total),
          },
        ]);
      } else {
        setdata("");
      }

      /* setData([
        {
          name: i18n.t("registeredArea"), // "Area cadastrada",
          y: Number(cadastramentoInner.registered_area),
        },
        {
          name: i18n.t("nonRegisteredArea"), // "Vazios do CAR",
          y: Number(cadastramentoInner.empty_of_car),
        },
      ]); */
    }
  }, [legalidadeInner, sizeFilter, type]);

  return (
    <>
      {data && (
        <div className="tcf-map-report--body-territorialcut-item--body--bar">
          <NivoBar data={data} />
        </div>
      )}

      {/* <Row>
        <Passivo obj={legalidadeInner} />
        <Remanescente obj={legalidadeInner} />
        {type === "settlements" ? (
          <ExcedenteTree obj={legalidadeInner} />
        ) : (
          <Excedente obj={legalidadeInner} />
        )}
      </Row> */}
    </>
  );
}

export default Legalidade;
