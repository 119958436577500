import api from "service/axios.service";
import {
  TRANSPARENCIA_FAIL,
  TRANSPARENCIA_REQUEST,
  TRANSPARENCIA_SUCCESS,
} from "../constants/transparenciaConstants";

export const transparenciaData = (id) => async (dispatch) => {
  dispatch({ type: TRANSPARENCIA_REQUEST });
  try {
    const res = await api.post("/v1/transparency/", {
      geocodes: id ? [id] : [],
    });
    const { data } = res || {};
    dispatch({
      type: TRANSPARENCIA_SUCCESS,
      payload: id && data ? data[0] : data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: TRANSPARENCIA_FAIL,
      payload: error.response?.data.message
        ? error.message.data.message
        : error.message,
    });
  }
};
