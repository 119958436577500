import React, { useState } from "react";
import i18n from "i18next";
import { ToggleButton } from "components";
import { useReportStore } from "service/report.service";

function ReportButton() {
  const { handleShowReport } = useReportStore();
  const [radioValue, setRadioValue] = useState("1");
  const radios = [
    { name: i18n.t("map"), value: "1" },
    { name: i18n.t("report"), value: "2" },
  ];

  return (
    <>
      {radios.map((radio, idx) => (
        <ToggleButton
          key={`radio-${idx}`}
          id={`radio-${idx}`}
          type="radio"
          variant={radioValue === radio.value ? "primary" : "outline-primary"}
          name={radio.name}
          value={radio.value}
          checked={radioValue === radio.value}
          onChange={(e) => {
            setRadioValue(e.currentTarget.value);
            handleShowReport();
          }}
        >
          {radio.name}
        </ToggleButton>
      ))}
    </>
  );
}

export default ReportButton;
