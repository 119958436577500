/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-danger */
import React, { useRef, useState, useEffect } from "react";
import i18n from "i18next";
import PropTypes from "prop-types";
import { isArray } from "helpers/utils";
import HTMLRenderer from "components/HTMLRenderer";
import { useSelector } from "react-redux";

function Info({ item, showModal, setShowModal }) {
  const [itemData, setItemData] = useState([]);
  const choice = useSelector((state) => state.choice);
  const { name } = choice || {};
  // const { source, info } = itemData || {};
  const modalRef = useRef();
  const closeModal = (e) => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (item) setItemData(item);

    /*  return () => {
      second
    } */
  }, [item]);

  return (
    <>
      {itemData && showModal ? (
        <div className="tcf-map-layer-popover" ref={modalRef}>
          <div className="tcf-map-layer-popover-header">
            <span className="tcf-map-layer-popover-header-title">
              <svg
                width="14"
                height="13"
                viewBox="0 0 14 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 12.5C10.3137 12.5 13 9.81371 13 6.5C13 3.18629 10.3137 0.5 7 0.5C3.68629 0.5 1 3.18629 1 6.5C1 9.81371 3.68629 12.5 7 12.5Z"
                  stroke="#78C753"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 8.9V6.5"
                  stroke="#78C753"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.80005 5.5H6.80605"
                  stroke="#78C753"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <h2>
                {/* {name} / */} {itemData.name}
              </h2>
            </span>

            <span
              className="tcf-map-layer-popover-header-title-close"
              onClick={
                closeModal
              } /* onClick={() => setOpenLegend(!openLegend)} */
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
              >
                <path
                  d="M13 1.75L1 13.75"
                  stroke="#AFA7A1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 1.25L13 13.25"
                  stroke="#AFA7A1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </div>
          <div className="tcf-map-layer-popover-body">
            {itemData?.source ? (
              <p className="new-line">{itemData?.source} </p>
            ) : isArray(itemData?.info) ? (
              itemData?.info.map((v, idx) => (
                <div key={`key_${idx}`}>
                  <h2>{v.item.id}</h2>
                  <HTMLRenderer content={v.text_item} />
                </div>
              ))
            ) : (
              <p
                className="new-line tagP"
                dangerouslySetInnerHTML={{
                  __html:
                    (itemData?.info &&
                      itemData?.info.replaceAll("\\n", "\n")) ||
                    `${i18n.t("no_information")}!`,
                }}
              />
            )}
          </div>
        </div>
      ) : null}
    </>
  );
}

Info.defaultProps = {
  item: {},
  style: {},
};

Info.propTypes = {
  item: PropTypes.object,
  style: PropTypes.object,
};

export default Info;
