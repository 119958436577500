const { useState, createContext, useContext, useMemo } = require("react");

const MapStore = createContext();

function MapStoreProvider({ children }) {
  const [map, setMap] = useState(null);
  const [openLegend, setOpenLegend] = useState(true);
  const [openLIndicator, setOpenLIndicator] = useState(false);

  const storeValue = useMemo(
    () => ({
      map,
      setMap,
      openLegend,
      setOpenLegend,
      openLIndicator,
      setOpenLIndicator,
    }),
    [map, setMap, openLegend, setOpenLegend, openLIndicator, setOpenLIndicator]
  );

  return <MapStore.Provider value={storeValue}>{children}</MapStore.Provider>;
}

const useMapStore = () => useContext(MapStore);

export { useMapStore, MapStoreProvider };
