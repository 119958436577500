// Lista de Biomas | Estados | Assentamentos | Imóveis Privados | Quilombos
import React, { useState, useEffect } from "react";
import i18n from "i18next";
import { useSelector, useDispatch } from "react-redux";
import WKT from "ol/format/WKT";
import { getDataTerritoryCategory } from "store/actions/biomesBrasilAction";
import { formateSelect } from "helpers/utils";
import { zoomToExtentLayer } from "../../../Map/controlMap";
import { listStates } from "store/actions/statesAction";
import { getSettlements } from "store/actions/settlementsAction";
import setChoice from "store/actions/choiceAction";
import * as regionActions from "store/actions/regionActions";
import { listMunicipalities } from "store/actions/municipalitiesAction";
import { Form } from "components";

const formatWkt = new WKT();

const Territorio = () => {
  const dispatch = useDispatch();
  const choice = useSelector((state) => state.choice);
  const { territorialCut, territory } = choice || {};

  const [listTerritory, setListTerritory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [randomValue, setRandomValue] = useState(0);

  // estados no redux que controlam se os dados já foram baixados pelo menos 1 vez, caso haja dados, o switch case
  // vai pegar os dados que estão no redux ao inves de fazer uma nova requisição
  const { statesInner: states } = useSelector((state) => state.states);
  const { biomesBrasil: biomes } = useSelector((state) => state.biomes);
  const { municipalitiesInner: municipalities } = useSelector(
    (state) => state.municipalities
  );
  const { settlements } = useSelector((state) => state.settlements);

  const renderData = (res, value) => {
    setListTerritory(formateSelect(res, value));
    setIsLoading(false);
  };

  // de acordo com a opção selecionada em recorte territorial será feito a requisição da lista do território
  // caso haja dados no redux, não será feito uma nova requisição, os dados viram do redux
  useEffect(() => {
    const { value } = territorialCut || {};
    if (value) {
      setIsLoading(true);
      setListTerritory([]);
      setRandomValue(Math.random());

      switch (value) {
        case "brazil":
          setIsLoading(false);
          break;

        case "biome":
          if (biomes.length === 0) {
            (async () => {
              dispatch(await getDataTerritoryCategory())
                .then((res) => renderData(res, value))
                .catch(() => setIsLoading(false));
            })();
          } else {
            renderData(biomes, value);
          }
          break;

        case "state":
          if (states.length === 0) {
            (async () => {
              dispatch(await listStates())
                .then((res) => renderData(res, value))
                .catch((error) => setIsLoading(false));
            })();
          } else {
            renderData(states, value);
          }
          break;

        case "rural_properties":
        case "traditional_territories":
          if (municipalities.length === 0) {
            (async () => {
              dispatch(await listMunicipalities())
                .then((res) => renderData(res, value))
                .catch(() => setIsLoading(false));
            })();
          } else {
            renderData(municipalities, value);
          }

          break;

        case "settlements":
          if (settlements.length === 0) {
            (async () => {
              dispatch(await getSettlements())
                .then((res) => renderData(res, value))
                .catch(() => setIsLoading(false));
            })();
          } else {
            renderData(settlements, value);
          }

          break;
      }
    }
  }, [territorialCut]);

  // para pegar infos da região seleciona (estado ou município)
  //----------------------------------------------------------------
  const onChangeRegion = (opt) => {
    const { value: geocode } = opt || {};
    dispatch(regionActions.getRegionInfo(geocode)).then((data) => {
      const { extent } = data;
      if (extent && geocode) {
        const boxextent = formatWkt.readFeature(extent);
        boxextent.getGeometry().transform("EPSG:4326", "EPSG:3857");
        zoomToExtentLayer(boxextent.getGeometry(), [150, 300, 150, 600]);
      }
    });

    const newChoice = { ...choice, territory: opt };
    dispatch(setChoice(newChoice));
  };
  //-----------------------------------------------------------------

  return (
    <div>
      {/* <h2 className="tcf-sidebar__territory-selector-title">
        {i18n.t("select_territory")}:
      </h2> */}
      {listTerritory && (
        <Form.Select
          placeholder={i18n.t("select_territory")}
          onChange={(opt) => onChangeRegion(opt)}
          options={listTerritory}
          defaultValue={territory}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default Territorio;
