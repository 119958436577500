import React from "react";

function Label({ htmlFor = "", children }) {
  return (
    <label htmlFor={htmlFor} className="tcf-form-label">
      {children}
    </label>
  );
}

export default Label;
