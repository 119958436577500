import { linksApp } from "../actions/types";

const initialState = {
  loading: false,
  linksAppInner: {},
  errorRead: "",
};

const linksAppReducer = (state = initialState, action) => {
  switch (action.type) {
    case linksApp.LINKS_APP_REQUEST:
      return { ...state, loading: true, linksAppInner: {} };
    case linksApp.LINKS_APP_SUCCESS:
      return { ...state, loading: false, linksAppInner: action.payload };
    case linksApp.LINKS_APP_FAIL:
      return { ...state, loading: false, errorRead: action.payload };
    default:
      return state;
  }
};

export default linksAppReducer;
