import api, { getLanguage } from "service/axios.service";
import {
  ANALISE_FAIL,
  ANALISE_REQUEST,
  ANALISE_SUCCESS,
} from "../constants/analiseEValidacaoConstants";

export const analiseData = (id) => async (dispatch) => {
  dispatch({ type: ANALISE_REQUEST });
  try {
    const res = await api.post(`/v1/analyAndValStates/${getLanguage()}`, {
      geocodes: id ? [id] : [],
    });
    const { data } = res || {};
    dispatch({
      type: ANALISE_SUCCESS,
      payload: id && data ? data[0] : data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: ANALISE_FAIL,
      payload: error.response?.data.message
        ? error.message.data.message
        : error.message,
    });
  }
};
