import React from "react";
import i18n from "i18next";
import { color } from "styles";
import { Pie } from "../../../components";
import BoxItems from "./BoxItems";
import {
  Box,
  Container,
  Item,
  Legenda,
  Marker,
  Wrapper,
  Title,
} from "./styles";

const colors = {
  // atendeu até 3 critérios
  3: {
    c3: color.green2,
    c2: color.yellow2,
    c1: color.orange1,
    c0: color.gray2,
  },
  // atendeu até 4 critérios
  4: {
    c4: color.green2,
    c3: color.green1,
    c2: color.yellow2,
    c1: color.orange1,
    c0: color.gray2,
  },
  // atendeu até 5 critérios
  5: {
    c5: color.green2,
    c4: color.green1,
    c3: color.yellow2,
    c2: color.orange3,
    c1: color.orange1,
    c0: color.gray2,
  },
};

const Panorama = ({ title, data, lawImplementation, geocode }) => {
  const { key, criteria } = lawImplementation;

  const totalCriteria = criteria.length;

  // prepara os dados para o gráfico
  const values = [];
  data.forEach((d) => {
    const finded = d.law_implementation.find((l) => l.key === key);

    const { totalPontuation } = finded;

    values.push({
      name: d.name,
      y: totalCriteria,
      z: totalPontuation,
      color: colors[totalCriteria]["c" + totalPontuation],
      borderColor: d.geocode === geocode ? color.primary : null,
      borderWidth: d.geocode === geocode ? 1.3 : 0,
      sliced: d.geocode === geocode,
      selected: d.geocode === geocode,
    });
  });

  // prepara os dados da legenda
  const legend = [];
  Object.keys(colors[totalCriteria]).forEach((c) => {
    // Nenhum critério atendido,
    let name = i18n.t("nca");

    // extrai apenas o número
    const cn = c.replace(/[^0-9]/g, "");

    if (cn > 0 && cn < 2) {
      // Atendeu x cretérios
      name = `${cn} ${i18n.t("criterion")} ${i18n.t("attended")}`;
    }

    if (cn >= 2) {
      // Atendeu x cretérios
      name = `${cn} ${i18n.t("criteria")} ${i18n.t("attended")}`;
    }

    // para 1 critério remove o "s" para deixar no singular
    if (parseInt(cn, 10) === 1) {
      name = name.slice(0, -1);
    }

    if (c === "c" + totalCriteria) {
      // Atendeu todos os critérios,
      name = i18n.t("atc");
    }

    legend.push({ name, color: colors[totalCriteria][c] });
  });

  return (
    <Wrapper>
      <Container>
        <Title>{title}</Title>
        <Pie {...{ data: values }} style={{ marginBottom: "21px" }} />
        <Box>
          <Legenda>
            {legend.map((item, idx) => (
              <Item key={idx}>
                <Marker active={1} color={item.color} />
                {item.name}
              </Item>
            ))}
          </Legenda>
          <BoxItems data={lawImplementation} />
        </Box>
      </Container>
    </Wrapper>
  );
};

export default Panorama;
