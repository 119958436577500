/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable prettier/prettier */
import Loading from "components/Loading";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Select from "react-select";

function CustomSelect({
  placeholder,
  isMulti = false,
  onChange,
  options,
  value = null,
  defaultValue = "",
  disabled = false,
  isLoading = false,
}) {
  const container = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    const optionValue = option.value ? option.value : option;
    if (isMulti) {
      if (selectedOptions.some((selected) => selected.value === optionValue)) {
        // Deselect the option if it's already selected
        const updatedOptions = selectedOption.filter((selected) =>
          selected.value ? selected.value : selected !== optionValue
        );
        onChange(updatedOptions);
        setSelectedOption(updatedOptions);
      } else {
        // Select the option if it's not already selected
        onChange([...selectedOption, option]);
        setSelectedOption([...selectedOption, option]);
      }
    } else {
      onChange(option);
      setSelectedOption(option);
      // setSelectedOption(option.value);
      toggleDropdown();
    }
  };

  const handleDeleteSelect = (option) => {
    const optionValue = option.value ? option.value : option;
    const updatedOptions = selectedOptions.filter(
      (selected) => selected.value !== optionValue
    );
    onChange(updatedOptions);
    setSelectedOption(updatedOptions);
  };

  useEffect(() => {
    if (container && container.current)
      document.addEventListener("click", (event) => {
        if (isOpen && !container.current.contains(event.target)) {
          setIsOpen(false);
        }
      });
  }, [container]);

  return (
    <>
      {isMulti ? (
        <div className="custom-multiselect">
          <div
            className={`${isMulti ? "tcf-select-multivalue__views" : "select-header "
              } ${isOpen ? "open" : ""}`}
            onClick={toggleDropdown}
          >
            {/* {selectedOption.length === 0
              ? placeholder
              : selectedOption.map((option) => (
                <div className="tcf-select-multivalue__view">
                  <div className="tcf-select-multivalue">{option.value}</div>
                  <div
                    className="tcf-select-multivalue__remove"
                    onClick={() => handleDeleteSelect(option)}
                    role="button"
                  >
                    <svg
                      height="14"
                      width="14"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      focusable="false"
                      className="css-tcf-select-multivalue__remove-Svg"
                    >
                      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
                    </svg>
                  </div>
                </div>
              ))} */}
            {selectedOption /* || placeholder */}
          </div>
          {isOpen && (
            <ul className="options">
              {options &&
                options.length > 0 &&
                options.map((option) => (
                  <>
                    {/* {selectedOption.some(
                      (selected) => selected.value === option.value
                    ) ? (
                      <></>
                    ) : (
                      <li
                        key={option.value}
                        onClick={() => handleSelect(option)}
                        className={
                          selectedOption.some(
                            (selected) => selected.value === option.value
                          )
                            ? "selected"
                            : ""
                        }
                      >
                        {option.label}
                      </li>
                    )} */}

                    <li
                      key={option.value}
                      className={`${selectedOption === option.label ? 'selected' : ''
                        } ${isOpen ? 'tcf-form-animate' : ''}`}
                      onClick={() => handleSelect(option)}
                    >
                      {option.label}
                    </li>
                  </>
                ))}
            </ul>
          )}
        </div>
      ) : (
        <div className="tcf-form-select" ref={container}>
          <select
            name=""
            value={defaultValue && defaultValue.value ? defaultValue.value : defaultValue}
            onChange={(e) => setSelectedOption(e.target.value)}
            onClick={() => false}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <div
            className={`tcf-form-select-header ${isOpen ? "open" : ""} ${selectedOption
              ? selectedOption || ""
              : "placeholder"
              } ${disabled ? "disabled" : isLoading ? "disabled" : ""}`}
            onClick={toggleDropdown}
          >
            {/* {selectedOption
              &&selectedOption === option.value?""
              : placeholder} */}
            {defaultValue && defaultValue.label ? defaultValue.label : defaultValue || placeholder}

            {isLoading ? <div
              className="tcf-form-select-loading"

            /> : <div
              className="css-tcf-select-indicatorContainer"
              aria-hidden="true"
            >
              <svg
                height="20"
                width="20"
                viewBox="0 0 20 20"
                aria-hidden="true"
                focusable="false"
                className="css-cf-select-Svg"
              >
                <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
              </svg>
            </div>}
          </div>
          {isOpen && (

            <ul className="tcf-form-select-options">
              {options.map((option) => (
                <li
                  key={option.value}
                  /* className={
                    selectedOption === option.label
                      ? "tcf-form-select-option-selected"
                      : ""
                  } */
                  className={`${selectedOption === option.value ? 'tcf-form-select-option-selected' : ''
                    } ${isOpen ? 'tcf-form-select-animate' : ''}`}
                  onClick={() => handleSelect(option)}
                >
                  {option.label}
                </li>
              ))}

            </ul>
          )}
        </div>
      )}
    </>
  );
}

export default CustomSelect;
