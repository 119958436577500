/* eslint-disable react/no-unstable-nested-components */
import i18n from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isEmptyObj,
  isObj,
  formatNumber,
  sumValueArrayObject,
} from "helpers/utils";
import { sobreposicoesData } from "store/actions/sobreposicoesActions";
import { ResponsiveSankey } from "@nivo/sankey";
import NivoCustomTooltip from "components/NivoCustomTooltip";

const names = {
  settlements: {
    t_area_uc: i18n.t("ASTUC"),
    t_area_ti: i18n.t("ASTTI"),
    t_area_ql: i18n.t("ASTQUI"),
    t_area_ar: i18n.t("ASTAST"),
    t_area_fb: i18n.t("ASTFPND"),
    t_area_iru: i18n.t("ASTIRU"),
  },
  rural_properties: {
    t_area_uc: i18n.t("IRUUC"),
    t_area_ti: i18n.t("IRUTI"),
    t_area_ql: i18n.t("IRUQUI"),
    t_area_ar: i18n.t("IRUAST"),
    t_area_fb: i18n.t("IRUFPND"),
  },
  traditional_territories: {
    t_area_uc: i18n.t("PCTsUC"),
    t_area_ti: i18n.t("PCTsTI"),
    t_area_ql: i18n.t("PCTsQUI"),
    t_area_ar: i18n.t("PCTsAST"),
    t_area_fb: i18n.t("PCTsFPND"),
  },
};

const Total = () => {
  const observedDivRef = useRef(null);

  const dispatch = useDispatch();
  const choice = useSelector((state) => state.choice);
  const { type } = choice || {};
  const { sobreposicoesInner } = useSelector((state) => state.sobreposicoes);

  const [data, setData] = useState();

  useEffect(() => {
    dispatch(sobreposicoesData(choice));
  }, [dispatch, choice]);

  useEffect(() => {
    if (isObj(sobreposicoesInner) && !isEmptyObj(sobreposicoesInner)) {
      const {
        t_area_uc, // AST X UC / IRU X UC / PCTs x UC
        t_area_ti, // AST X TI / IRU X TI / PCTs x TI
        t_area_ql, // AST X QUI / IRU X QUI / PCTs x QUI
        t_area_ar, // AST X AST / IRU X AST / PCTs x AST
        t_area_fb, // AST X FPND / IRU X FPND / PCTs x FPND
        t_area_iru, // AST X IRU
      } = sobreposicoesInner;

      const total = "100%";

      const data = {
        nodes: [
          { id: total, nodeColor: "#e8c1a0" },
          { id: "UC", label: names[type]["t_area_uc"], nodeColor: "#AFA4CE" },
          { id: "TI", label: names[type]["t_area_ti"], nodeColor: "#7F746C" },
          { id: "QUI", label: names[type]["t_area_ql"], nodeColor: "#78C753" },
          { id: "AST", label: names[type]["t_area_ar"], nodeColor: "#F7B718" },
          { id: "FPND", label: names[type]["t_area_fb"], nodeColor: "#AC6C29" },
        ],
        links: [
          {
            source: total,
            target: "UC",
            value: Number(t_area_uc) || 0,
          },
          {
            source: total,
            target: "TI",
            value: Number(t_area_ti) || 0,
          },
          {
            source: total,
            target: "QUI",
            value: Number(t_area_ql) || 0,
          },
          {
            source: total,
            target: "AST",
            value: Number(t_area_ar) || 0,
          },
          {
            source: total,
            target: "FPND",
            value: Number(t_area_fb) || 0,
          },
        ],
      };

      if (type !== "rural_properties") {
        data.nodes.push({
          id: "IRU",
          label: names[type]["t_area_iru"],
          nodeColor: "#e0746c",
        });
        data.links.push({
          source: total,
          target: "IRU",
          value: Number(t_area_iru) || 0,
        });
      }

      // soma os valores de todas as categorias
      data.totalVal = sumValueArrayObject(data.links, "value");

      setData(data);
    }
  }, [sobreposicoesInner]);

  return (
    <div
      className="tcf-map-report--body-territorialcut-item--body--bar"
      ref={observedDivRef}
    >
      {data && data.links && (
        <ResponsiveSankey
          data={data}
          margin={{ top: 10, right: 160, bottom: 20, left: 90 }}
          align="center"
          nodeOpacity={1}
          nodeHoverOthersOpacity={0.35}
          nodeThickness={18}
          nodeSpacing={24}
          nodeBorderWidth={0}
          colors={(node) => node.nodeColor}
          nodeBorderColor={(node) => node.nodeColor}
          label={(node) => {
            const { index, id, value } = node || {};
            const percent = formatNumber(
              (value / data.totalVal) * 100,
              2,
              ".",
              ","
            );
            const label =
              index === 0
                ? `${formatNumber(value, 0, ".", ",")} ha`
                : `${percent}% - ${formatNumber(value, 0, ".", ",")} ha`;

            return (
              <>
                <tspan style={{ fontSize: "13px" }}>{id}</tspan>
                <tspan x={0} dy={12} style={{ fontSize: "12px" }}>
                  {label}
                </tspan>
              </>
            );
          }}
          linkOpacity={0.5}
          linkHoverOthersOpacity={0.1}
          linkContract={0}
          enableLinkGradient={true}
          labelPosition="outside"
          nodeTooltip={({ node }) => {
            const { id, value } = node || {};
            const valueFormated = formatNumber(value, 0, ".", ",");

            return <NivoCustomTooltip id={id} value={valueFormated} />;
          }}
          linkTooltip={({ link }) => {
            const { target, value } = link || {};
            const { id } = target || {};
            const valueFormated = formatNumber(value, 0, ".", ",");

            return <NivoCustomTooltip id={id} value={valueFormated} />;
          }}
          labelOrientation="horizontal"
          labelPadding={16}
        />
      )}
    </div>
  );
};

export default Total;
