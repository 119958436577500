import React, { useState, useEffect } from "react";
import styled from "styled-components";

const CircularProgress = ({
  size,
  progress,
  strokeWidth,
  circleOneStroke,
  circleTwoStroke,
}) => {
  const [offset, setOffset] = useState(0);

  const center = `${size / 2}`;
  const radius = `${size / 2 - strokeWidth / 2}`;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference;
    setOffset(progressOffset);
  }, [setOffset, circumference, progress, offset]);

  return (
    <Svg width={size} height={size}>
      <circle
        stroke={circleOneStroke}
        cx={center}
        cy={center}
        r={radius}
        strokeWidth={strokeWidth}
        fill="none"
      />
      <circle
        stroke={circleTwoStroke}
        cx={center}
        cy={center}
        r={radius}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        {...(offset && { strokeDashoffset: offset })}
        fill="none"
      />
    </Svg>
  );
};

const Svg = styled.svg`
  transform: rotate(-90deg);
`;

export default CircularProgress;
