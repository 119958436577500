import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { transparenciaData } from "store/actions/transparenciaActions";
import Embargos from "../Embargos";
import Supressoes from "../Supressoes";
import { formatNumber } from "helpers/utils";
import NivoCustomTooltip from "components/NivoCustomTooltip";
import AnimationCount from "./AnimationCount";

const Transparencia = () => {
  const dispatch = useDispatch();
  const choice = useSelector((state) => state.choice);
  const { type, territorialCut, territory } = choice || {};
  const { transparenciaInner } = useSelector((state) => state.transparencia);

  const { active, passive } = transparenciaInner || {};

  useEffect(() => {
    if (territory?.value) dispatch(transparenciaData(territory.value));
  }, [dispatch, territory]);

  if (type !== "rural_properties" && !territory?.value)
    return <div style={{ width: "100%" }}>{i18n.t("select_state")}</div>;

  return (
    <div className="tcf-map-report--body-territorialcut--ischildren">
      {type === "rural_properties" && (
        <>
          <Embargos />
          <Supressoes />
        </>
      )}

      {territorialCut?.value === "state" && territory?.value && (
        <>
          {" "}
          <div className="tcf-map-report--body-territorialcut--ischildren-item">
            <div className="tcf-map-report--body-territorialcut--ischildren-item--title">
              {i18n.t("transparencyActive")}
            </div>
            <div className="tcf-map-report--body-territorialcut--ischildren-item--body box-counter">
              {active && (
                <AnimationCount value={Number(active)} color="#78C753" />
              )}
            </div>
          </div>
          <div className="tcf-map-report--body-territorialcut--ischildren-item">
            <div className="tcf-map-report--body-territorialcut--ischildren-item--title">
              {i18n.t("transparencyPassive")}
            </div>
            <div className="tcf-map-report--body-territorialcut--ischildren-item--body box-counter">
              {passive && (
                <AnimationCount value={Number(passive)} color="#AC6C29" />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Transparencia;
