/* eslint-disable camelcase */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as layerActions from "store/actions/layerActions";
import i18n from "i18next";
import { Accordion } from "components";
import Layers from "./Layers";
import ResetFilter from "../ResetFilter";

function MapLayers() {
  // reducer
  const dispatch = useDispatch();
  const layers = useSelector((state) => state.layers);
  const { layers_groups } = layers.data || {};

  useEffect(() => {
    dispatch(layerActions.read());
  }, [dispatch]);

  return (
    <Accordion
      size="md"
      title={i18n.t("layers")}
      space
      isLayer
      className="tcf-sidebar__territory--selector-layers"
      style={{
        marginBottom: "0px",
      }}
      content={
        <>
          <ResetFilter />

          {layers_groups &&
            layers_groups.map(
              (item, idx) =>
                item.layers_names.length > 0 &&
                item.main_menu && (
                  <Layers
                    key={idx}
                    indexGroup={idx}
                    group={item}
                    label={item.name}
                    list={item.layers_names}
                  />
                )
            )}
        </>
      }
    />
  );
}

export default MapLayers;
