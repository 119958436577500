import React from "react";
import { Totem } from "../SobreOTermometro/components";
import Brasil from "assets/ICONS/brasil-green.svg";
import BarChart from "assets/ICONS/bar-chart-green.svg";
import Database from "assets/ICONS/database-green.svg";
import Subtitle from "components/SubTitle";
import i18n from "i18next";
import Button from "components/Button";
import { Link } from "react-router-dom";

const Ferramentas = () => (
  <div className="tcf-tools_container" id="tools">
    <div className="tcf-container">
      <div className="tcf-row">
        <div className="tcf-col-12">
          <Subtitle>{i18n.t("tools")}</Subtitle>
        </div>
      </div>
      <div className="tcf-box-tools__container">
        <Totem src={Brasil} title={i18n.t("maps")}>
          {i18n.t("mapsText")}
        </Totem>
        <Totem src={BarChart} title={i18n.t("reports")}>
          {i18n.t("reportText")}
        </Totem>
        <Totem src={Database} title={i18n.t("data")}>
          {i18n.t("dataText")}
        </Totem>{" "}
      </div>
    </div>
    <div className="tcf-row">
      <div className="tcf-col-12">
        <Link to="/plataforma">
          <Button variant="primary" size="md">
            {i18n.t("access_platform")}
          </Button>
        </Link>
      </div>
    </div>
  </div>
);

export default Ferramentas;
